import React from "react";
import Oops from "./Oops";
import { createGuid } from "../utilities";
import * as Sentry from "@sentry/browser";
// import { useErrorBoundary } from "react-error-boundary";

const styles = {
  details: {
    marginTop: "1em",
    whiteSpace: "pre-wrap",
  },
  error: {
    margin: 10,
  },
  errorContentItem: {
    marginTop: "1em",
  },
  tryAgainButton: {
    margin: "10px 0",
  },
  //   mainError: {
  //     margin: "10px 0",
  //   },
  //   message: {
  //     display: "inline-block",
  //     color: "red",
  //   },
  stack: {
    margin: "10px 0",
  },
};

const ErrorBoundaryHook = ({ error, resetErrorBoundary }) => {
  // const { resetBoundary } = useErrorBoundary();

  //   let message;

  //   if (error) {
  //     message = error.message;
  //   } else {
  //     message = "Unknown error";
  //   }

  React.useEffect(() => {
    const eventId = createGuid();

    console.log("--------------------------");
    console.log("ErrorBoundaryHook");
    console.log(`Event ID: ${eventId}`);
    console.log(error.message);
    console.log(error.stack);
    console.log("--------------------------");

    Sentry.withScope((scope) => {
      scope.setExtra("eventId", eventId);
      scope.setExtra("errorInfo", error.stack);

      Sentry.captureException(error.message);
    });
  }, []);

  return (
    <div style={styles.component}>
      <Oops />
      <details style={styles.details}>
        {/* <div style={styles.mainError}>
          <div style={styles.message}>{message}</div>
        </div> */}
        <div style={styles.stack}>{error.stack}</div>
        <div style={styles.tryAgainButton}>
          {resetErrorBoundary && <button onClick={resetErrorBoundary}>Refresh</button>}
        </div>
      </details>
    </div>
  );
};

export default ErrorBoundaryHook;
