import React from "react";
import * as PropTypes from "prop-types";
import { css } from "@emotion/css";
import { measurePropType } from "../../propTypes";
import { withTranslation } from "react-i18next";

const classes = {
  chartRow: css`
    display: flex;
    flex-wrap: wrap;
  `,
  heading: css`
    margin: 40px 0 20px;
    font-size: medium;
    font-weight: bold;
  `,
};

const ChartsChartList = ({ t, ...rest }) => (
  <div className={rest.className}>
    {rest.children.length === 10 ? (
      <>
        <div className={classes.chartRow}>{rest.children.slice(0, rest.measure === "percentage" ? 4 : 3)}</div>

        <h1 className={classes.heading}>{t("Unmet need and demand satisfied for modern methods")}</h1>

        <div className={classes.chartRow}>{[5, 7, 9].map((index) => rest.children[index])}</div>

        <h1 className={classes.heading}>{t("Unmet need and demand satisfied for any method")}</h1>

        <div className={classes.chartRow}>{[4, 6, 8].map((index) => rest.children[index])}</div>
      </>
    ) : (
      <div className={classes.chartRow}>{rest.children}</div>
    )}
  </div>
);

ChartsChartList.propTypes = {
  children: PropTypes.arrayOf(PropTypes.element),
  measure: measurePropType.isRequired,
  className: PropTypes.string,
};

export default withTranslation()(ChartsChartList);
