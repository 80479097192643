import { createTheme } from "@mui/material/styles";
import blue from "@mui/material/colors/blue";

const theme = createTheme({
  palette: {
    background: {
      default: "#fff",
    },
    primary: {
      main: blue[900],
    },
    secondary: {
      main: "#f50057",
    },
  },
  typography: {
    useNextVariants: true,
  },
  components: {
    // MuiCheckbox: {
    //   defaultProps: {
    //     checked: "hotpink",
    //   },
    // },
    MuiRadio: {
      defaultProps: {
        color: "secondary",
      },
    },
    MuiFormControlLabel: {
      styleOverrides: {
        // Name of the slot
        label: {
          // Some CSS
          fontSize: "14px",
        },
      },
    },
  },
});

export default theme;
