import React from "react";
import * as PropTypes from "prop-types";
import ChartsMarker from "./ChartsMarker";

const getShape = (groupTypeRelativeToBaseline) => {
  switch (groupTypeRelativeToBaseline) {
    case "SA":
      return "triangle";

    case "EM":
    case "AL":
      return "inverted triangle";

    case "BS":
    case "HW":
      return "square";

    default:
      return "circle";
  }
};

const getColor = (dataSeriesType) => {
  switch (dataSeriesType) {
    case "DHS":
      return "#e41a1c";

    case "MICS":
      return "#377eb8";

    case "National survey":
    case "NS":
      return "#ff7f00";

    case "PMA":
      return "#984ea3";

    default:
      return "#4daf4a";
  }
};

const getLabel = (ageGroupBias, hasTraditionalMethodBias, modernMethodBias) => {
  let label;

  if (ageGroupBias === "+") {
    label = "+";
  } else if (ageGroupBias === "-") {
    label = "-";
  } else if (ageGroupBias === "?") {
    label = "A";
  } else if (hasTraditionalMethodBias) {
    label = "F";
  } else if (modernMethodBias === "-") {
    label = "S-";
  } else if (modernMethodBias === "+") {
    label = "S+";
  }

  return label;
};

const ChartsSurveyMarker = (props) => (
  <ChartsMarker
    shape={getShape(props.groupTypeRelativeToBaseline)}
    strokeColor={getColor(props.dataSeriesType)}
    label={getLabel(props.ageGroupBias, props.hasTraditionalMethodBias, props.modernMethodBias)}
    textColor="#999"
  />
);

export const ChartsSurveyMarker2 = (props) => {
  return ChartsMarker({
    shape: getShape(props.groupTypeRelativeToBaseline),
    strokeColor: getColor(props.dataSeriesType),
    label: getLabel(props.ageGroupBias, props.hasTraditionalMethodBias, props.modernMethodBias),
    textColor: "#999",
  });
};

ChartsSurveyMarker.propTypes = {
  survey: PropTypes.shape({
    dataSeriesType: PropTypes.string.isRequired,
    groupTypeRelativeToBaseline: PropTypes.oneOf(["AL", "BS", "EM", "FM", "HW", "MW", "SA", "UW"]).isRequired,
    ageGroupBias: PropTypes.oneOf(["+", "-", "?"]),
    hasTraditionalMethodBias: PropTypes.bool.isRequired,
    modernMethodBias: PropTypes.oneOf(["+", "-"]),
  }),
};

export default ChartsSurveyMarker;
