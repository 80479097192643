import React from "react";
import * as PropTypes from "prop-types";
import { css } from "@emotion/css";
import { emuDatumPropType, populationDatumPropType, surveyDatumPropType } from "../../propTypes";
import { Route, Switch, Redirect } from "react-router-dom";
import { withTranslation } from "react-i18next";
import SurveyDataTable from "../AGGrid/SurveyDataTable";
import PopulationDataTable from "../AGGrid/PopulationDataTable";
import EmuDataTable from "../AGGrid/EmuDataTable";
import NavigationTab from "../NavigationTab";
import Title from "../Title";

const classes = {
  tabPage: css`
    margin: 10px;
  `,
  title: css`
    margin-bottom: 20px;
  `,
};

const StartRunMainContent = ({ match, t, ...rest }) => (
  <div>
    <div className={classes.title}>
      <Title title={rest.runTitle} />
    </div>

    <NavigationTab to="/startrun/survey">{t("Survey data")}</NavigationTab>
    <NavigationTab to="/startrun/population">{t("Population data")}</NavigationTab>
    <NavigationTab to="/startrun/emu">{t("Service statistics")}</NavigationTab>

    <div className={classes.tabPage}>
      <Switch>
        <Route exact path={match.path} render={() => <Redirect replace to={`${match.path}/survey`} />} />
        <Route path={`${match.path}/survey`} render={() => <SurveyDataTable data={rest.surveyData} />} />
        <Route path={`${match.path}/population`} render={() => <PopulationDataTable data={rest.populationData} />} />
        <Route path={`${match.path}/emu`} render={() => <EmuDataTable data={rest.emuData} />} />
      </Switch>
    </div>
  </div>
);

StartRunMainContent.propTypes = {
  runTitle: PropTypes.string,
  surveyData: PropTypes.arrayOf(surveyDatumPropType),
  populationData: PropTypes.arrayOf(populationDatumPropType),
  emuData: PropTypes.arrayOf(emuDatumPropType),
};

StartRunMainContent.defaultProps = {
  surveyData: [],
  populationData: [],
  emuData: [],
};

export default withTranslation()(StartRunMainContent);
