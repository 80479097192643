import React from "react";
import authentication from "../authentication";
import * as api from "../api/api";
import i18next from "i18next";
import App from "../components/App";

const AppContainer = (props) => {
  const { history } = props;

  const signal = api.getSignal();

  React.useEffect(() => {
    return () => {
      signal.cancel();
    };
  }, []);

  const onChangeLanguage = (language) => {
    // noinspection JSIgnoredPromiseFromCall
    i18next.changeLanguage(language).then(() => {
      document.title = i18next.t("APP_TITLE");
    });
  };

  const onLogout = () => {
    authentication.logout((error) => {
      if (!error) {
        history.push("/login");
      }
    }, signal.token);
  };

  return <App onChangeLanguage={onChangeLanguage} onLogout={onLogout} />;
};

export default AppContainer;
