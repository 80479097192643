import React from "react";
import * as PropTypes from "prop-types";
import { css } from "@emotion/css";
import {
  emuDatumPropType,
  labeledValuePropType,
  maritalStatusPropType,
  measurePropType,
  periodPropType,
  resultPropType,
  surveyDatumPropType,
} from "../../propTypes";
import { indicators } from "../../constants";
import Checkbox from "@mui/material/Checkbox";
import FormControlLabel from "@mui/material/FormControlLabel";
import FormGroup from "@mui/material/FormGroup";
import ChartList from "./ChartsChartList";
import ChartsExporter from "./ChartsExporter";
import ComparisonLegend from "./ChartsComparisonLegend";
import ConfidenceIntervalChart from "./ChartsConfidenceIntervalChartHC";
import MaritalStatusSelector from "../MaritalStatusSelector";
import MeasureSelector from "../MeasureSelector";
import ChartsSurveyLegend from "./ChartsSurveyLegend";
import { withTranslation } from "react-i18next";

const classes = {
  charts: css`
    margin: 20px 0;
  `,
  checkbox: css`
    padding: 4px 12px;
  `,
  comparisonLegend: css`
    margin-top: 10px;
  `,
  container: css`
    margin-top: 20px;
  `,
  gridSelectors: css`
    display: flex;
  `,
  indicatorPaper: css`
    margin-bottom: 20px;
  `,
  indicators: css`
    margin-top: 20px;
  `,
  measureSelector: css`
    margin-right: 30px;
  `,
  noData: css`
    margin: 20px 0;
  `,
  surveyLegend: css`
    margin-bottom: 20px;
  `,
};

const recalculateSurveyMetrics = (surveys) =>
  surveys.map((survey) => ({
    ...survey,
    unmetNeedModern:
      survey.contraceptiveUseTraditional !== null && survey.unmetNeedAny !== null
        ? survey.contraceptiveUseTraditional + survey.unmetNeedAny
        : null,
    demandModern: survey.demand,
    demandSatisfiedModern:
      survey.contraceptiveUseModern !== null && survey.contraceptiveUseAny !== null && survey.unmetNeedAny !== null
        ? survey.contraceptiveUseModern / (survey.contraceptiveUseAny + survey.unmetNeedAny)
        : null,
  }));

const Charts = ({ t, ...rest }) => (
  <div className={classes.container}>
    <div className={classes.gridSelectors}>
      <MeasureSelector
        value={rest.selectedMeasure}
        className={classes.measureSelector}
        onChange={rest.onChangeMeasure}
      />
      <MaritalStatusSelector value={rest.selectedMaritalStatus} onChange={rest.onChangeMaritalStatus} />
    </div>

    <FormGroup row={true} className={classes.indicators}>
      {indicators
        .filter(
          (indicator) =>
            indicator.chart && (rest.selectedMeasure === "percentage" || indicator.value !== "ratioModernAny")
        )
        .map((indicator) => (
          <FormControlLabel
            key={indicator.value}
            control={
              <Checkbox
                checked={rest.selectedChartsIndicators.includes(indicator.value)}
                className={classes.checkbox}
                onChange={() => rest.onToggleChart(indicator.value)}
              />
            }
            label={t(indicator.label)}
          />
        ))}

      <FormControlLabel
        control={
          <Checkbox checked={rest.showSurveyLegend} className={classes.checkbox} onChange={rest.onToggleSurveyLegend} />
        }
        label={t("Show survey legend")}
      />
    </FormGroup>

    {rest.results ? (
      <>
        {rest.comparison && (
          <div className={classes.comparisonLegend}>
            <ComparisonLegend runName={rest.runName} comparisonRunName={rest.comparisonRunName} />
          </div>
        )}

        <ChartList measure={rest.selectedMeasure} className={classes.charts}>
          {indicators
            .filter((indicator) => rest.selectedChartsIndicators.includes(indicator.value))
            .map((indicator) => {
              const indicatorResults = (rest.results && rest.results[indicator.value]) || [];

              const surveyData = recalculateSurveyMetrics(rest.surveyData).filter(
                ({ startDate }) => startDate >= rest.period.firstYear && startDate <= rest.period.lastYear
              );

              return (
                <ConfidenceIntervalChart
                  key={indicator.value}
                  comparison={rest.comparison}
                  measure={rest.selectedMeasure}
                  indicator={indicator.value}
                  title={indicator.label}
                  description={indicator.description}
                  xDomain={[rest.period.firstYear, rest.period.lastYear + 1]}
                  data={indicatorResults.map((datum) => ({
                    ...datum,
                    year: datum.year + 0.5,
                  }))}
                  surveys={surveyData.filter((datum) => datum[indicator.value] != null)}
                  emuData={indicator.value === "contraceptiveUseModern" ? rest.emuData : []}
                  y={indicator.value}
                />
              );
            })}
        </ChartList>

        {rest.showSurveyLegend && (
          <div className={classes.surveyLegend}>
            <ChartsSurveyLegend />
          </div>
        )}
      </>
    ) : (
      <div className={classes.noData}>{t("No data to chart")}</div>
    )}

    <div className={classes.indicatorPaper}>
      Detailed descriptions of the indicators can be found in:{" "}
      <a href="https://www.un.org/en/development/desa/population/publications/dataset/contraception/wcu2019/UNPD_WCU2019_Methodology.pdf">
        https://www.un.org/en/development/desa/population/publications/dataset/contraception/wcu2019/UNPD_WCU2019_Methodology.pdf
      </a>
    </div>

    <ChartsExporter
      chartSets={rest.chartSets}
      selectedChartSet={rest.selectedExportedChartSet}
      selectedMeasure={rest.selectedExportedMeasure}
      selectedMaritalStatus={rest.selectedExportedMaritalStatus}
      disableChartSetDownload={rest.disableChartSetDownload}
      disableSelectedChartsDownload={rest.disableSelectedChartsDownload}
      onChangeChartSet={rest.onChangeExportedChartSet}
      onChangeMeasure={rest.onChangeExportedMeasure}
      onChangeMaritalStatus={rest.onChangeExportedMaritalStatus}
      onDownloadChartSet={rest.onDownloadChartSet}
      onDownloadSelectedCharts={rest.onDownloadSelectedCharts}
    />
  </div>
);

Charts.propTypes = {
  comparison: PropTypes.bool,
  runName: PropTypes.string,
  comparisonRunName: PropTypes.string,
  period: periodPropType,
  selectedMeasure: measurePropType.isRequired,
  selectedChartsMaritalStatus: PropTypes.string,
  selectedChartsIndicators: PropTypes.arrayOf(PropTypes.string),
  showSurveyLegend: PropTypes.bool,
  results: resultPropType,
  surveyData: PropTypes.arrayOf(surveyDatumPropType),
  emuData: PropTypes.arrayOf(emuDatumPropType),
  chartSets: PropTypes.arrayOf(labeledValuePropType),
  selectedExportedChartSet: PropTypes.string.isRequired,
  selectedExportedMeasure: measurePropType.isRequired,
  selectedExportedMaritalStatus: maritalStatusPropType,
  disableChartSetDownload: PropTypes.bool.isRequired,
  disableSelectedChartsDownload: PropTypes.bool.isRequired,
  onChangeMeasure: PropTypes.func.isRequired,
  onChangeMaritalStatus: PropTypes.func.isRequired,
  onToggleChart: PropTypes.func.isRequired,
  onToggleSurveyLegend: PropTypes.func.isRequired,
  onChangeExportedChartSet: PropTypes.func.isRequired,
  onChangeExportedMeasure: PropTypes.func.isRequired,
  onChangeExportedMaritalStatus: PropTypes.func.isRequired,
  onDownloadChartSet: PropTypes.func.isRequired,
  onDownloadSelectedCharts: PropTypes.func.isRequired,
};

Charts.defaultProps = {
  comparison: false,
  selectedChartsIndicators: [],
  showSurveyLegend: false,
  surveyData: [],
  emuData: [],
};

export default withTranslation()(Charts);
