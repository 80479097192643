import { maximumYear, minimumYear } from "./constants";

const parseNumber = (value) => {
  let normalizedValue = value;

  // NOTE: Editing a cell and changing nothing and clicking away gives a numeric value
  if (typeof value === "string") {
    if ((value.match(/,/g) || []).length === 1) {
      normalizedValue = normalizedValue.replace(",", ".");
    }
  }

  return parseFloat(normalizedValue);
};

export const createGuid = () =>
  ([1e7] + -1e3 + -4e3 + -8e3 + -1e11).replace(/[018]/g, (c) =>
    (c ^ (crypto.getRandomValues(new Uint8Array(1))[0] & (15 >> (c / 4)))).toString(16)
  );

export const createSlug = (name, suffix, separator = "_") => (name ? `${name}${separator}${suffix}` : suffix);

export const formatDateTime = (date) =>
  date.toLocaleDateString("en-US", {
    day: "numeric",
    month: "short",
    year: "numeric",
    hour: "2-digit",
    minute: "2-digit",
    seconds: "2-digit",
  });

export const formatLogicalCell = (value) => (value == null ? null : value ? "✔" : "✘");

export const formatNullableCell = (value) => (value != null ? value : "None");

export const formatNumericCell = (value, decimalPlaces) => {
  const number = parseFloat(value);

  return isNaN(number) ? undefined : number.toFixed(decimalPlaces);
};

export const formatPercentage = (value, decimalPlaces) => `${(value * 100).toFixed(decimalPlaces)}%`;

export const formatPercentageCell = (value, decimalPlaces, suffix) => {
  const number = parseFloat(value);

  return isNaN(number) ? undefined : `${(number * 100).toFixed(decimalPlaces)}${suffix ? "%" : ""}`;
};

export const getCurrentYear = () => new Date().getFullYear();

export const getDefaultLastYear = () => 2030;

export const groupBy = (items, key) =>
  items.reduce((result, item) => {
    const { indicator, ...rest } = item;

    result[indicator] = [...(result[indicator] || []), rest];

    return result;
  }, {});

export const hash = (text) =>
  [...text].reduce(
    (previousHash, currentCharacter) => ((previousHash << 5) - previousHash + currentCharacter.charCodeAt(0)) | 0,
    0
  );

export const parseIntegerCell = (params, minimumInclusiveValue, maximumInclusiveValue) => {
  const newValue = parseInt(params.newValue, 10);

  if (isNaN(newValue)) {
    return params.oldValue;
  }

  return trimToRange(newValue, minimumInclusiveValue, maximumInclusiveValue);
};

export const parseNullableAlphaCell = (params, minimumInclusiveValue = 0, maximumInclusiveValue = Number.MAX_VALUE) => {
  const newValue = params.newValue.trim();

  if (newValue.length === 0 || !isNaN(parseInt(newValue[0], 10))) {
    return;
  }

  return newValue;
};

export const parseNullableNumericCell = (
  params,
  minimumInclusiveValue = 0,
  maximumInclusiveValue = Number.MAX_VALUE
) => {
  const newValue = parseNumber(params.newValue);

  if (isNaN(newValue)) {
    return;
  }

  return trimToRange(newValue, minimumInclusiveValue, maximumInclusiveValue);
};

export const parseNumericCell = (params, minimumInclusiveValue = 0, maximumInclusiveValue = Number.MAX_VALUE) => {
  const newValue = parseNumber(params.newValue);

  if (isNaN(newValue)) {
    return params.oldValue;
  }

  return trimToRange(newValue, minimumInclusiveValue, maximumInclusiveValue);
};

export const toggleSetExistence = (set, value) => {
  if (set.has(value)) {
    set.delete(value);
  } else {
    set.add(value);
  }
};

export const trimYearToRange = (year, enforceRange) => trimToRange(year, enforceRange ? minimumYear : 0, maximumYear);

export const trimToRange = (value, inclusiveMinimum, inclusiveMaximum) =>
  Math.min(Math.max(value, inclusiveMinimum), inclusiveMaximum);
