import React from "react";
import * as PropTypes from "prop-types";
import { formatNumericCell } from "../../utilities";
import { AgGridReact } from "ag-grid-react";
import "ag-grid-enterprise";
import "ag-grid-community/styles/ag-grid.css";
import "ag-grid-community/styles/ag-theme-material.css";
import { withTranslation } from "react-i18next";
import { translateGridText } from "../../i18n";

const EmuData = ({ t, ...rest }) => {
  const colDefs = [
    { field: "regionCode", headerName: t("Region code") },
    { field: "referencePop", headerName: t("Reference pop") },
    { field: "serviceStatisticType", headerName: t("Service statistic type") },
    {
      field: "startYear",
      headerName: t("Year"),
      type: "numericColumn",
      filter: "agNumberColumnFilter",
    },
    {
      field: "emu",
      headerName: t("EMU"),
      type: "numericColumn",
      filter: "agNumberColumnFilter",
      valueFormatter: (params) => formatNumericCell(params.value, 3),
    },
    { field: "sD_EMU", headerName: t("SD_EMU") },
    { field: "emU_ROC", headerName: t("EMU_ROC") },
    { field: "sD_EMU_ROC", headerName: t("SD_EMU_ROC") },
    { field: "included", headerName: t("Include?") },
  ];

  const rowData = rest.data;

  return (
    <div className="ag-theme-material">
      <AgGridReact
        domLayout="autoHeight"
        defaultColDef={{ sortable: true, filter: true }}
        pagination={true}
        paginationPageSize={10}
        suppressColumnVirtualisation={true}
        suppressCellFocus={true}
        overlayNoRowsTemplate="<span />"
        getLocaleText={translateGridText}
        rowData={rowData}
        columnDefs={colDefs}
        // autoSizeStrategy={{ type: "fitCellContents" }}
        onGridReady={(params) => params.columnApi.autoSizeAllColumns()}
      />
    </div>
  );
};

EmuData.propTypes = {
  data: PropTypes.array,
};

EmuData.defaultProps = {
  data: [],
};

export default withTranslation()(EmuData);
