import React from "react";
import { css } from "@emotion/css";
import Drawer from "@mui/material/Drawer";
// import Box from "@mui/material/Box";

const DRAWER_WIDTH = 350;
const DENSE_TOOLBAR = true;

const styles2 = {
  drawerPaper: {
    width: DRAWER_WIDTH,
    height: "calc(100%)",
    padding: "10px",
    position: "relative",
  },
  toolbar: (theme) => ({
    ...theme.mixins.toolbar,
    marginTop: DENSE_TOOLBAR ? "-16px" : 0,
  }),
};

const classes = {
  pageContainer: css`
    height: 100%;
  `,
  drawer: css`
    width: 350px;
    height: 100%;
    float: left;
  `,
  mainContent: css`
    height: 100%;
    padding: 20px;
    overflow: auto;
  `,
};

const contentWithDrawer = (MainContent, DrawerContent) => (rest) => {
  return (
    <div className={classes.pageContainer}>
      <Drawer
        className={classes.drawer}
        variant="permanent"
        anchor="left"
        sx={{
          //   "& .MuiDrawer-docked": styles2.drawer,
          "& .MuiDrawer-paper": styles2.drawerPaper,
          //   ...styles2.drawer,
        }}
      >
        {/* <Box sx={styles2.toolbar} /> */}

        <DrawerContent {...rest} />
      </Drawer>

      <div className={classes.mainContent}>
        <MainContent {...rest} />
      </div>
    </div>
  );
};

export default contentWithDrawer;
