import React from "react";
import LogRocket from "logrocket";
import authentication from "../authentication";
import { Redirect } from "react-router-dom";
import * as api from "../api/api";
import i18next from "i18next";
import Login from "../components/Login";

const LoginContainer = (props) => {
  const { location } = props;

  const [state, setState] = React.useState({
    loggingIn: false,
    username: undefined,
    password: undefined,
    rememberMe: false,
    redirectToReferrer: undefined,
    error: undefined,
    systemOperational: undefined,
    systemStatusMessage: undefined,
  });

  const signal = api.getSignal();

  React.useEffect(() => {
    api.fetchSystemStatus().then((response) => {
      if (!response) {
        return;
      }

      setState((prevState) => ({
        ...prevState,
        systemOperational: response.data.systemOperational,
        systemStatusMessage: response.data.message,
      }));
    });

    return () => {
      signal.cancel();
    };
  }, []);

  const onChangeUsername = (username) => {
    setState((prevState) => ({
      ...prevState,
      username: username,
    }));
  };

  const onChangePassword = (password) => {
    setState((prevState) => ({
      ...prevState,
      password: password,
    }));
  };

  const onToggleRememberMe = () => {
    setState((prevState) => ({
      ...prevState,
      rememberMe: !prevState.rememberMe,
    }));
  };

  const onSubmit = () => {
    setState((prevState) => ({
      ...prevState,
      error: undefined,
      loggingIn: true,
    }));

    authentication.login(
      state.username,
      state.password,
      (error) => {
        if (!error) {
          LogRocket.identify(state.username);
        }

        setState((prevState) => ({
          ...prevState,
          loggingIn: false,
          redirectToReferrer: !error,
          error: error ? `${prevState.username} could not be authenticated` : undefined,
        }));
      },
      signal.token
    );
  };

  const onChangeLanguage = (language) => {
    // noinspection JSIgnoredPromiseFromCall
    i18next.changeLanguage(language);
  };

  if (state.redirectToReferrer) {
    const { from } = location.state || { from: { pathname: "/" } };
    return <Redirect to={from} />;
  }

  return (
    <Login
      systemOperational={state.systemOperational}
      systemStatusMessage={state.systemStatusMessage}
      username={state.username}
      password={state.password}
      rememberMe={state.rememberMe}
      error={state.error}
      loggingIn={state.loggingIn}
      onChangeUsername={onChangeUsername}
      onChangePassword={onChangePassword}
      onToggleRememberMe={onToggleRememberMe}
      onSubmit={onSubmit}
      onChangeLanguage={onChangeLanguage}
    />
  );
};

export default LoginContainer;
