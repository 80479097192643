import React from "react";
import * as PropTypes from "prop-types";
import { surveyDatumPropType } from "../../propTypes";
import { formatLogicalCell, formatNumericCell } from "../../utilities";
import { AgGridReact } from "ag-grid-react";
import "ag-grid-enterprise";
import "ag-grid-community/styles/ag-grid.css";
import "ag-grid-community/styles/ag-theme-material.css";
import { useTranslation } from "react-i18next";
import { translateGridText } from "../../i18n";

const SurveyData = (props) => {
  const { t } = useTranslation();

  const colDefs = [
    { field: "regionCode", headerName: t("Region code") },
    { field: "possible_outlier", headerName: t("possible_outlier") },
    { field: "possible_outlier_userinput", headerName: t("possible_outlier_userinput") },
    {
      field: "startDate",
      headerName: t("Start year"),
      type: "numericColumn",
      filter: "agNumberColumnFilter",
      valueFormatter: (params) => formatNumericCell(params.value, 2),
    },
    {
      field: "endDate",
      headerName: t("End year"),
      type: "numericColumn",
      filter: "agNumberColumnFilter",
      valueFormatter: (params) => formatNumericCell(params.value, 2),
    },
    { field: "isInUnion", headerName: t("In union?"), valueFormatter: (params) => formatLogicalCell(params.value) },
    { field: "ageRange", headerName: t("Age") },
    { field: "dataSeriesType", headerName: t("Data series/type") },
    {
      field: "groupTypeRelativeToBaseline",
      headerName: t("Population"),
      headerTooltip: t("Group type relative to baseline"),
    },
    {
      field: "contraceptiveUseModern",
      headerName: t("Modern contraceptive use"),
      type: "numericColumn",
      filter: "agNumberColumnFilter",
      valueFormatter: (params) => formatNumericCell(params.value, 3),
      headerTooltip: t("Contraceptive use (modern)"),
    },
    {
      field: "contraceptiveUseTraditional",
      headerName: t("Traditional contraceptive use"),
      type: "numericColumn",
      filter: "agNumberColumnFilter",
      valueFormatter: (params) => formatNumericCell(params.value, 3),
      headerTooltip: t("Contraceptive use (traditional)"),
    },
    {
      field: "contraceptiveUseAny",
      headerName: t("All contraceptive use"),
      type: "numericColumn",
      filter: "agNumberColumnFilter",
      valueFormatter: (params) => formatNumericCell(params.value, 3),
      headerTooltip: t("Contraceptive use (all)"),
    },
    {
      field: "unmetNeedModern",
      headerName: t("Modern methods unmet need"),
      type: "numericColumn",
      filter: "agNumberColumnFilter",
      valueFormatter: (params) => formatNumericCell(params.value, 3),
      headerTooltip: t("Unmet need for any method"),
    },
    {
      field: "unmetNeedAny",
      headerName: t("Unmet need for modern methods"),
      type: "numericColumn",
      filter: "agNumberColumnFilter",
      valueFormatter: (params) => formatNumericCell(params.value, 3),
      headerTooltip: t("Unmet need for any method"),
    },
    {
      field: "pertainingToMethodsUsedSinceLastPregnancyReason",
      headerName: t("Pertaining to methods used since last pregnancy"),
    },
    { field: "geographicalRegionBiasReason", headerName: t("Geographical bias") },
    { field: "nonPregnantAndOtherPositiveBiasesReason", headerName: t("Non-pregnant and other positive biases") },
    { field: "ageGroupBias", headerName: t("Age bias") },
    { field: "modernMethodBias", headerName: t("Modern method bias") },
    { field: "traditionalMethodBiasReason", headerName: t("Traditional method bias") },
    {
      field: "hasAbsenceOfProbingQuestionsBias",
      headerName: t("Absence of probing question bias?"),
      valueFormatter: (params) => formatLogicalCell(params.value),
    },
    {
      field: "seLogRModernNoUse",
      headerName: t("SE log-ratio modern/no use"),
      type: "numericColumn",
      filter: "agNumberColumnFilter",
      valueFormatter: (params) => formatNumericCell(params.value, 2),
      headerTooltip: t("Sampling error for the log-ratio of modern contraceptive method use to no use"),
    },
    {
      field: "seLogRTraditionalNoUse",
      headerName: t("SE log-ratio traditional/no use"),
      type: "numericColumn",
      filter: "agNumberColumnFilter",
      valueFormatter: (params) => formatNumericCell(params.value, 2),
      headerTooltip: t("Sampling error for the log-ratio of traditional contraceptive method use to no use"),
    },
    {
      field: "seLogRUnmetNoNeed",
      headerName: t("SE log-ratio unmet/no need"),
      type: "numericColumn",
      filter: "agNumberColumnFilter",
      valueFormatter: (params) => formatNumericCell(params.value, 2),
      headerTooltip: t("Sampling error for the log-ratio of unmet need (any method) to no need"),
    },
    { field: "sourceId", headerName: t("Source ID"), type: "numericColumn" },
  ];

  const rowData = props.data;

  return (
    <div className="ag-theme-material">
      <AgGridReact
        domLayout="autoHeight"
        defaultColDef={{ sortable: true, filter: true }}
        pagination={true}
        paginationPageSize={10}
        suppressColumnVirtualisation={true}
        suppressCellFocus={true}
        overlayNoRowsTemplate="<span />"
        getLocaleText={translateGridText}
        rowData={rowData}
        columnDefs={colDefs}
        // autoSizeStrategy={{ type: "fitGridWidth" }}
        onGridReady={(params) => params.columnApi.autoSizeAllColumns()}
      />
    </div>
  );
};

SurveyData.propTypes = {
  data: PropTypes.arrayOf(surveyDatumPropType),
};

SurveyData.defaultProps = {
  data: [],
};

export default SurveyData;

// const SurveyDataRegular = (props) => {
//   const { t } = useTranslation();

//   return (
//     <div className="ag-theme-material">
//       <AgGridReact
//       // domLayout="autoHeight"
//       // defaultColDef={{
//       //   sortable: true,
//       //   filter: true,
//       // }}
//       // pagination={true}
//       // paginationPageSize={10}
//       // suppressColumnVirtualisation={true}
//       // suppressCellFocus={true}
//       // overlayNoRowsTemplate="<span />"
//       // getLocaleText={translateGridText}
//       // rowData={props.data}
//       >
//         <AgGridColumn headerName={t("Region code")} field="regionCode" />
//         {/* <AgGridColumn headerName={t("possible_outlier")} field="possible_outlier" />
//         <AgGridColumn headerName={t("possible_outlier_userinput")} field="possible_outlier_userinput" />
//         <AgGridColumn
//           headerName={t("Start year")}
//           field="startDate"
//           type="numericColumn"
//           filter="agNumberColumnFilter"
//           valueFormatter={(params) => formatNumericCell(params.value, 2)}
//         />
//         <AgGridColumn
//           headerName={t("End year")}
//           field="endDate"
//           type="numericColumn"
//           filter="agNumberColumnFilter"
//           valueFormatter={(params) => formatNumericCell(params.value, 2)}
//         />
//         <AgGridColumn
//           headerName={t("In union?")}
//           field="isInUnion"
//           valueFormatter={(params) => formatLogicalCell(params.value)}
//         />
//         <AgGridColumn headerName={t("Age")} field="ageRange" />
//         <AgGridColumn headerName={t("Data series/type")} field="dataSeriesType" />
//         <AgGridColumn
//           headerName={t("Population")}
//           field="groupTypeRelativeToBaseline"
//           headerTooltip={t("Group type relative to baseline")}
//         />
//         <AgGridColumn
//           headerName={t("Modern contraceptive use")}
//           field="contraceptiveUseModern"
//           type="numericColumn"
//           filter="agNumberColumnFilter"
//           valueFormatter={(params) => formatNumericCell(params.value, 3)}
//           headerTooltip={t("Contraceptive use (modern)")}
//         />
//         <AgGridColumn
//           headerName={t("Traditional contraceptive use")}
//           field="contraceptiveUseTraditional"
//           type="numericColumn"
//           filter="agNumberColumnFilter"
//           valueFormatter={(params) => formatNumericCell(params.value, 3)}
//           headerTooltip={t("Contraceptive use (traditional)")}
//         />
//         <AgGridColumn
//           headerName={t("All contraceptive use")}
//           field="contraceptiveUseAny"
//           type="numericColumn"
//           filter="agNumberColumnFilter"
//           valueFormatter={(params) => formatNumericCell(params.value, 3)}
//           headerTooltip={t("Contraceptive use (all)")}
//         />
//         <AgGridColumn
//           headerName={t("Modern methods unmet need")}
//           field="unmetNeedModern"
//           type="numericColumn"
//           filter="agNumberColumnFilter"
//           valueFormatter={(params) => formatNumericCell(params.value, 3)}
//           headerTooltip={t("Unmet need for modern methods")}
//         />
//         <AgGridColumn
//           headerName={t("Any method unmet need")}
//           field="unmetNeedAny"
//           type="numericColumn"
//           filter="agNumberColumnFilter"
//           valueFormatter={(params) => formatNumericCell(params.value, 3)}
//           headerTooltip={t("Unmet need for any method")}
//         />
//         <AgGridColumn
//           headerName={t("Pertaining to methods used since last pregnancy")}
//           field="pertainingToMethodsUsedSinceLastPregnancyReason"
//         />
//         <AgGridColumn headerName={t("Geographical bias")} field="geographicalRegionBiasReason" />
//         <AgGridColumn
//           headerName={t("Non-pregnant and other positive biases")}
//           field="nonPregnantAndOtherPositiveBiasesReason"
//         />
//         <AgGridColumn headerName={t("Age bias")} field="ageGroupBias" />
//         <AgGridColumn headerName={t("Modern method bias")} field="modernMethodBias" />
//         <AgGridColumn headerName={t("Traditional method bias")} field="traditionalMethodBiasReason" />
//         <AgGridColumn
//           headerName={t("Absence of probing question bias?")}
//           field="hasAbsenceOfProbingQuestionsBias"
//           valueFormatter={(params) => formatLogicalCell(params.value)}
//         />
//         <AgGridColumn
//           headerName={t("SE log-ratio modern/no use")}
//           field="seLogRModernNoUse"
//           type="numericColumn"
//           filter="agNumberColumnFilter"
//           valueFormatter={(params) => formatNumericCell(params.value, 2)}
//           headerTooltip={t("Sampling error for the log-ratio of modern contraceptive method use to no use")}
//         />
//         <AgGridColumn
//           headerName={t("SE log-ratio traditional/no use")}
//           field="seLogRTraditionalNoUse"
//           type="numericColumn"
//           filter="agNumberColumnFilter"
//           valueFormatter={(params) => formatNumericCell(params.value, 2)}
//           headerTooltip={t("Sampling error for the log-ratio of traditional contraceptive method use to no use")}
//         />
//         <AgGridColumn
//           headerName={t("SE log-ratio unmet/no need")}
//           field="seLogRUnmetNoNeed"
//           type="numericColumn"
//           filter="agNumberColumnFilter"
//           valueFormatter={(params) => formatNumericCell(params.value, 2)}
//           headerTooltip={t("Sampling error for the log-ratio of unmet need (any method) to no need")}
//         />
//         <AgGridColumn headerName={t("Source ID")} field="sourceId" type="numericColumn" /> */}
//       </AgGridReact>
//     </div>
//   );
// };
