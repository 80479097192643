import React from "react";
import { defaultFirstYear, indicators } from "../constants";
import { createSlug, getDefaultLastYear, toggleSetExistence } from "../utilities";
import {
  convertMaritalStatusToIsInUnion,
  filterResults,
  getPeriod,
  normalizeChartSets,
  normalizeDatabases,
  normalizeEmuData,
  normalizeResults,
  normalizeRuns,
  normalizeSurveyData,
} from "../data";
import * as api from "../api/api";
import fileDownload from "js-file-download";
import { withErrorBoundary } from "../errors";
import CompareRuns from "../pages/CompareRuns";

const createInitialState = () => {
  return {
    period: {
      firstYear: defaultFirstYear,
      lastYear: getDefaultLastYear(),
    },
    selectedResultsMeasure: "percentage",
    selectedResultsIndicator: indicators[0].value,
    selectedResultsMaritalStatus: "married",
    selectedExportedResultsMeasures: new Set(),
    selectedExportedResultsIndicators: new Set(),
    selectedChartsMeasure: "percentage",
    selectedChartsMaritalStatus: "married",
    selectedChartsIndicators: new Set(
      indicators.filter((indicator) => indicator.chart).map((indicator) => indicator.value)
    ),
    showSurveyLegend: true,
    chartSets: [
      {
        value: "complete",
        label: "Complete",
      },
    ],
    selectedExportedChartSet: "complete",
    selectedExportedChartsMeasure: "percentage",
    selectedExportedChartsMaritalStatus: "married",
  };
};

const CompareRunsContainer = (props) => {
  const [state, setState] = React.useState(createInitialState());

  const signal = api.getSignal();

  const setStateSafely = (newState) => {
    setState((prevState) => ({
      ...prevState,
      ...newState,
    }));
  };

  React.useEffect(() => {
    setStateSafely({
      loadingData: true,
    });

    // noinspection JSCheckFunctionSignatures
    Promise.all([
      api.fetchDatabases(signal.token),
      api.fetchRuns(false, false, signal.token),
      api.fetchChartSets(signal.token),
    ])
      .then((response) => {
        if (!response || response.some((item) => !item)) {
          return;
        }

        const [databases, runs, chartSets] = response;

        setStateSafely({
          databases: normalizeDatabases(databases.data),
          runs: normalizeRuns(runs.data),
          chartSets: normalizeChartSets(chartSets.data),
        });
      })
      .finally(() => {
        setStateSafely({
          loadingData: false,
        });
      });

    return () => {
      signal.cancel();
    };
  }, []);

  const getCountries = (runId, comparisonRunId) => {
    if (!runId || !comparisonRunId) {
      return {};
    }

    const run = state.runs[runId];
    const comparisonRun = state.runs[comparisonRunId];

    const countryNumericCodes = run.countryNumericCodes.filter((countryNumericCode) =>
      comparisonRun.countryNumericCodes.includes(countryNumericCode)
    );

    const divisions = state.databases[run.surveyDatabaseId].divisions;

    return countryNumericCodes.reduce((result, countryNumericCode) => {
      if (divisions[countryNumericCode] && divisions[countryNumericCode].isCountry) {
        result[countryNumericCode] = {
          numericCode: countryNumericCode,
          ...divisions[countryNumericCode],
        };
      }

      return result;
    }, {});
  };

  const getRegions = (runId, comparisonRunId) => {
    if (!runId || !comparisonRunId) {
      return [];
    }

    const runRegions = state.runs[runId].regionCodes || [];
    const comparisonRunRegions = state.runs[comparisonRunId].regionCodes || [];

    return runRegions.filter((region) => comparisonRunRegions.includes(region));
  };

  const selectDefaultCountryOrRegion = (runId, comparisonRunId) => {
    const countryNumericCodes = Object.keys(getCountries(runId, comparisonRunId));
    const regions = getRegions(runId, comparisonRunId);

    if (countryNumericCodes.length + regions.length === 1) {
      if (countryNumericCodes.length) {
        setStateSafely({
          selectedCountryNumericCode: parseInt(countryNumericCodes[0], 10),
        });
      } else {
        setStateSafely({
          selectedRegion: regions[0],
        });
      }
    } else {
      setStateSafely({
        selectedCountryNumericCode: undefined,
        selectedRegion: undefined,
      });
    }
  };

  const getSelectedCountryNumericCodeOrRegion = () => state.selectedRegion || state.selectedCountryNumericCode;

  const createRunTitle = () => {
    let countryAlphaCodeOrRegion;

    if (state.selectedRegion) {
      countryAlphaCodeOrRegion = state.selectedRegion;
    } else if (state.selectedCountryNumericCode) {
      const selectedRun = state.runs[state.selectedRunId];

      countryAlphaCodeOrRegion =
        state.databases[selectedRun.surveyDatabaseId].divisions[state.selectedCountryNumericCode].alphaCode;
    }

    if (countryAlphaCodeOrRegion) {
      const selectedRun = state.runs[state.selectedRunId];
      const selectedComparisonRun = state.runs[state.selectedComparisonRunId];

      const { firstYear, lastYear } = state.period;

      return `Run ${selectedRun.name} vs ${
        selectedComparisonRun.name
      } for ${countryAlphaCodeOrRegion} during ${firstYear}-${lastYear}`;
    }
  };

  const updateRun = (runId, runIdProperty, comparisonRunId) => {
    setStateSafely({
      [runIdProperty]: runId,
    });

    selectDefaultCountryOrRegion(runId, comparisonRunId);
  };

  const getMeasureResultsForPeriod = (measure) => {
    if (!state.results) {
      return;
    }

    const measureKey = measure === "percentage" ? "proportions" : "populations";

    return filterResults(state.results[measureKey], state.period.firstYear, state.period.lastYear);
  };

  const getTableResults = () => {
    let measureResultsForPeriod = getMeasureResultsForPeriod(state.selectedResultsMeasure);

    const isInUnion = convertMaritalStatusToIsInUnion(state.selectedResultsMaritalStatus);

    return (
      measureResultsForPeriod &&
      measureResultsForPeriod[state.selectedResultsIndicator].filter((datum) => datum.isInUnion === isInUnion)
    );
  };

  const getChartSurveyData = () => {
    if (!state.surveyData) {
      return;
    }

    const { firstYear, lastYear } = state.period;

    const isInUnion = convertMaritalStatusToIsInUnion(state.selectedChartsMaritalStatus);

    return state.surveyData.filter(
      (datum) => datum.startDate >= firstYear && datum.startDate <= lastYear && datum.isInUnion === isInUnion
    );
  };

  const getChartResults = () => {
    const measureResultsForPeriod = getMeasureResultsForPeriod(state.selectedChartsMeasure);

    if (!measureResultsForPeriod) {
      return;
    }

    const isInUnion = convertMaritalStatusToIsInUnion(state.selectedChartsMaritalStatus);

    const results = Object.keys(measureResultsForPeriod).reduce((result, indicator) => {
      if (state.selectedChartsIndicators.has(indicator)) {
        result[indicator] = measureResultsForPeriod[indicator].filter((datum) => datum.isInUnion === isInUnion);
      }

      return result;
    }, {});

    if (!Object.keys(results).length || !results[Object.keys(results)[0]].length) {
      return;
    }

    return results;
  };

  const onSelectRun = (runId) => {
    updateRun(runId, "selectedRunId", state.selectedComparisonRunId);
  };

  const onSelectComparisonRun = (runId) => {
    updateRun(runId, "selectedComparisonRunId", state.selectedRunId);
  };

  const onSelectCountry = (countryNumericCode) => {
    setStateSafely({
      selectedCountryNumericCode: countryNumericCode,
      selectedRegion: undefined,
    });
  };

  const onSelectRegion = (region) => {
    setStateSafely({
      selectedRegion: region,
    });
  };

  const onChangePeriod = (period) => {
    setStateSafely({
      period,
    });
  };

  const onCompareRuns = () => {
    const run = state.runs[state.selectedRunId];
    const comparisonRun = state.runs[state.selectedComparisonRunId];

    const countryNumericCodeOrRegion = getSelectedCountryNumericCodeOrRegion();

    setStateSafely({
      loadingRuns: true,
    });

    // noinspection JSCheckFunctionSignatures
    Promise.all([
      api.fetchSurveyDatabase(run.surveyDatabaseId, countryNumericCodeOrRegion, state.selectedRunId, signal.token),
      api.fetchSurveyDatabase(
        comparisonRun.surveyDatabaseId,
        countryNumericCodeOrRegion,
        state.selectedComparisonRunId,
        signal.token
      ),
      run.emuDatabaseId
        ? api.fetchEmuDatabase(run.emuDatabaseId, countryNumericCodeOrRegion, signal.token)
        : new Promise((resolve) =>
            resolve({
              data: [],
            })
          ),
      comparisonRun.emuDatabaseId
        ? api.fetchEmuDatabase(comparisonRun.emuDatabaseId, countryNumericCodeOrRegion, signal.token)
        : new Promise((resolve) =>
            resolve({
              data: [],
            })
          ),
      api.fetchResults(
        state.selectedRunId,
        state.selectedComparisonRunId,
        getSelectedCountryNumericCodeOrRegion(),
        signal.token
      ),
    ])
      .then((response) => {
        if (!response || response.some((item) => !item)) {
          return;
        }

        const [runSurveyData, comparisonRunSurveyData, runEmuData, comparisonRunEmuData, results] = response;

        const period = getPeriod(results.data.proportions);

        setStateSafely({
          period,
          surveyData: normalizeSurveyData([...runSurveyData.data, ...comparisonRunSurveyData.data]),
          emuData: normalizeEmuData([...runEmuData.data, ...comparisonRunEmuData.data]),
          results: {
            proportions: normalizeResults(results.data.proportions),
            populations: normalizeResults(results.data.populations),
          },
        });
      })
      .finally(() => {
        setStateSafely({
          loadingRuns: false,
        });
      });
  };

  const onChangeResultsMeasure = (measure) => {
    setStateSafely({
      selectedResultsMeasure: measure,
      selectedResultsIndicator:
        measure === "percentage" || state.selectedResultsIndicator !== "ratioModernAny"
          ? state.selectedResultsIndicator
          : indicators[0].value,
    });
  };

  const onChangeResultsIndicator = (indicator) => {
    setStateSafely({
      selectedResultsIndicator: indicator,
    });
  };

  const onChangeResultsMaritalStatus = (martialStatus) => {
    setStateSafely({
      selectedResultsMaritalStatus: martialStatus,
    });
  };

  const onToggleExportedResultsMeasure = (measure) => {
    const selectedExportedResultsMeasures = new Set(state.selectedExportedResultsMeasures);

    toggleSetExistence(selectedExportedResultsMeasures, measure);

    setStateSafely({
      selectedExportedResultsMeasures,
    });
  };

  const onToggleExportedResultsIndicator = (indicator) => {
    const selectedExportedResultsIndicators = new Set(state.selectedExportedResultsIndicators);

    toggleSetExistence(selectedExportedResultsIndicators, indicator);

    setStateSafely({
      selectedExportedResultsIndicators,
    });
  };

  const onDownloadSelectedResults = () => {
    api
      .downloadResults(
        state.selectedRunId,
        state.selectedComparisonRunId,
        getSelectedCountryNumericCodeOrRegion(),
        Array.from(state.selectedExportedResultsMeasures),
        Array.from(state.selectedExportedResultsIndicators),
        signal.token
      )
      .then((response) => {
        if (!response) {
          return;
        }

        const run = state.runs[state.selectedRunId];

        const comparisonRun = state.runs[state.selectedComparisonRunId];

        if (run && comparisonRun) {
          const prefix = `${run.name}_vs_${comparisonRun.name}`;

          fileDownload(response.data, createSlug(prefix, "results.csv"));
        }
      });
  };

  const onDownloadAllResults = () => {
    api
      .downloadResults(
        state.selectedRunId,
        state.selectedComparisonRunId,
        getSelectedCountryNumericCodeOrRegion(),
        ["percentage", "count"],
        indicators.map((indicator) => indicator.value),
        signal.token
      )
      .then((response) => {
        if (!response) {
          return;
        }

        const run = state.runs[state.selectedRunId];
        const comparisonRun = state.runs[state.selectedComparisonRunId];

        if (run && comparisonRun) {
          const prefix = `${run.name}_vs_${comparisonRun.name}`;

          fileDownload(response.data, createSlug(prefix, "all_results.csv"));
        }
      });
  };

  const onChangeChartsMeasure = (measure) => {
    setStateSafely({
      selectedChartsMeasure: measure,
    });
  };

  const onChangeChartsMaritalStatus = (martialStatus) => {
    setStateSafely({
      selectedChartsMaritalStatus: martialStatus,
    });
  };

  const onToggleChart = (chart) => {
    const selectedChartsIndicators = new Set(state.selectedChartsIndicators);

    toggleSetExistence(selectedChartsIndicators, chart);

    setStateSafely({
      selectedChartsIndicators,
    });
  };

  const onToggleSurveyLegend = () => {
    setStateSafely({
      showSurveyLegend: !state.showSurveyLegend,
    });
  };

  const onChangeExportedChartSet = (chartSet) => {
    setStateSafely({
      selectedExportedChartSet: chartSet,
    });
  };

  const onChangeExportedChartsMeasure = (measure) => {
    setStateSafely({
      selectedExportedChartsMeasure: measure,
    });
  };

  const onChangeExportedChartsMaritalStatus = (maritalStatus) => {
    setStateSafely({
      selectedExportedChartsMaritalStatus: maritalStatus,
    });
  };

  const onDownloadChartSet = () => {
    api
      .downloadChartSet(
        state.selectedRunId,
        state.selectedComparisonRunId,
        getSelectedCountryNumericCodeOrRegion(),
        state.selectedExportedChartSet,
        state.selectedExportedChartsMaritalStatus,
        state.selectedExportedChartsMeasure,
        signal.token
      )
      .then((response) => {
        if (!response) {
          return;
        }

        const run = state.runs[state.selectedRunId];

        const comparisonRun = state.runs[state.selectedComparisonRunId];

        if (run && comparisonRun) {
          const prefix = `${run.name}_vs_${comparisonRun.name}_${state.selectedExportedChartSet}`;

          fileDownload(response.data, createSlug(prefix, "charts.pdf"));
        }
      });
  };

  const onDownloadSelectedCharts = () => {
    api
      .downloadCharts(
        state.selectedRunId,
        state.selectedComparisonRunId,
        getSelectedCountryNumericCodeOrRegion(),
        state.selectedExportedChartsMaritalStatus,
        state.selectedExportedChartsMeasure,
        Array.from(state.selectedChartsIndicators),
        signal.token
      )
      .then((response) => {
        if (!response) {
          return;
        }

        const run = state.runs[state.selectedRunId];

        const comparisonRun = state.runs[state.selectedComparisonRunId];

        if (run && comparisonRun) {
          const prefix = `${run.name}_vs_${comparisonRun.name}`;

          fileDownload(response.data, createSlug(prefix, "charts.pdf"));
        }
      });
  };

  return (
    <CompareRuns
      match={props.match}
      loadingData={state.loadingData}
      runs={state.runs}
      selectedRunId={state.selectedRunId}
      selectedRunName={state.runs && state.selectedRunId && state.runs[state.selectedRunId].name}
      selectedComparisonRunId={state.selectedComparisonRunId}
      selectedComparisonRunName={
        state.runs && state.selectedComparisonRunId && state.runs[state.selectedComparisonRunId].name
      }
      countries={getCountries(state.selectedRunId, state.selectedComparisonRunId)}
      selectedCountryNumericCode={state.selectedCountryNumericCode}
      regions={getRegions(state.selectedRunId, state.selectedComparisonRunId)}
      selectedRegion={state.selectedRegion}
      period={state.period}
      loadingRuns={state.loadingRuns}
      runsTitle={createRunTitle()}
      selectedResultsMeasure={state.selectedResultsMeasure}
      selectedResultsIndicator={state.selectedResultsIndicator}
      selectedResultsMaritalStatus={state.selectedResultsMaritalStatus}
      tableResults={getTableResults()}
      selectedExportedResultsMeasures={Array.from(state.selectedExportedResultsMeasures)}
      selectedExportedResultsIndicators={Array.from(state.selectedExportedResultsIndicators)}
      disableSelectedResultsDownload={
        !api.createResultsDownloadUrl(
          state.selectedRunId,
          state.selectedComparisonRunId,
          getSelectedCountryNumericCodeOrRegion(),
          Array.from(state.selectedExportedResultsMeasures),
          Array.from(state.selectedExportedResultsIndicators)
        )
      }
      disableAllResultsDownload={
        !api.createResultsDownloadUrl(
          state.selectedRunId,
          state.selectedComparisonRunId,
          getSelectedCountryNumericCodeOrRegion(),
          ["percentage", "count"],
          indicators.map((indicator) => indicator.value)
        )
      }
      selectedChartsMeasure={state.selectedChartsMeasure}
      selectedChartsMaritalStatus={state.selectedChartsMaritalStatus}
      selectedChartsIndicators={Array.from(state.selectedChartsIndicators)}
      showSurveyLegend={state.showSurveyLegend}
      chartResults={getChartResults()}
      chartSurveyData={getChartSurveyData()}
      chartEmuData={state.selectedChartsMaritalStatus === "married" ? state.emuData : undefined}
      chartSets={state.chartSets}
      selectedExportedChartSet={state.selectedExportedChartSet}
      selectedExportedChartsMeasure={state.selectedExportedChartsMeasure}
      selectedExportedChartsMaritalStatus={state.selectedExportedChartsMaritalStatus}
      disableChartSetDownload={
        !api.createChartSetDownloadUrl(
          state.selectedRunId,
          undefined,
          getSelectedCountryNumericCodeOrRegion(),
          state.selectedExportedChartSet,
          state.selectedExportedChartsMaritalStatus,
          state.selectedExportedChartsMeasure
        )
      }
      disableSelectedChartsDownload={
        !api.createChartsDownloadUrl(
          state.selectedRunId,
          undefined,
          getSelectedCountryNumericCodeOrRegion(),
          state.selectedExportedChartsMaritalStatus,
          state.selectedExportedChartsMeasure,
          Array.from(state.selectedChartsIndicators)
        )
      }
      onSelectRun={onSelectRun}
      onSelectComparisonRun={onSelectComparisonRun}
      onSelectCountry={onSelectCountry}
      onSelectRegion={onSelectRegion}
      onChangePeriod={onChangePeriod}
      onCompareRuns={onCompareRuns}
      onChangeResultsMeasure={onChangeResultsMeasure}
      onChangeResultsIndicator={onChangeResultsIndicator}
      onChangeResultsMaritalStatus={onChangeResultsMaritalStatus}
      onToggleExportedResultsMeasure={onToggleExportedResultsMeasure}
      onToggleExportedResultsIndicator={onToggleExportedResultsIndicator}
      onDownloadSelectedResults={onDownloadSelectedResults}
      onDownloadAllResults={onDownloadAllResults}
      onChangeChartsMeasure={onChangeChartsMeasure}
      onChangeChartsMaritalStatus={onChangeChartsMaritalStatus}
      onToggleChart={onToggleChart}
      onToggleSurveyLegend={onToggleSurveyLegend}
      onChangeExportedChartSet={onChangeExportedChartSet}
      onChangeExportedChartsMeasure={onChangeExportedChartsMeasure}
      onChangeExportedChartsMaritalStatus={onChangeExportedChartsMaritalStatus}
      onDownloadChartSet={onDownloadChartSet}
      onDownloadSelectedCharts={onDownloadSelectedCharts}
    />
  );
};

export default withErrorBoundary(CompareRunsContainer);
