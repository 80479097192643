import React from "react";
import * as PropTypes from "prop-types";
import { css } from "@emotion/css";
import { measurePropType } from "../../propTypes";
import Button from "@mui/material/Button";
import Checkbox from "@mui/material/Checkbox";
import FormControlLabel from "@mui/material/FormControlLabel";
import FormGroup from "@mui/material/FormGroup";
import Tooltip from "@mui/material/Tooltip";
import Typography from "@mui/material/Typography";
import { withTranslation } from "react-i18next";

const classes = {
  button: css`
    margin-right: 20px;
  `,
  buttonGroup: css`
    margin-top: 20px;
  `,
  checkbox: css`
    padding: 4px 12px;
  `,
  subtitle: css`
    margin-top: 10px;
  `,
};

const ResultsExporter = ({ t, ...rest }) => (
  <div>
    <Typography variant="h5">{t("Export results")}</Typography>

    <Typography variant="h6" className={classes.subtitle}>
      {t("Measures to export")}
    </Typography>

    <FormGroup row={true}>
      <FormControlLabel
        control={
          <Checkbox
            checked={rest.selectedMeasures.includes("percentage")}
            className={classes.checkbox}
            onChange={() => {
              rest.onToggleMeasure("percentage");
            }}
          />
        }
        label={t("Percentage")}
      />
      <FormControlLabel
        control={
          <Checkbox
            checked={rest.selectedMeasures.includes("count")}
            className={classes.checkbox}
            onChange={() => {
              rest.onToggleMeasure("count");
            }}
          />
        }
        label={t("Count")}
      />
    </FormGroup>

    <Typography variant="h6" className={classes.subtitle}>
      {t("Indicators to export")}
    </Typography>

    <FormGroup row={true}>
      {rest.indicators.map((indicator) => (
        <FormControlLabel
          key={indicator.label}
          control={
            <Checkbox
              checked={rest.selectedIndicators.includes(indicator.value)}
              className={classes.checkbox}
              onChange={() => {
                rest.onToggleIndicator(indicator.value);
              }}
            />
          }
          label={t(indicator.label)}
        />
      ))}
    </FormGroup>

    <div className={classes.buttonGroup}>
      <Tooltip title={t("CSV file containing selected results for selected indicators")}>
        <span>
          <Button
            variant="contained"
            color="primary"
            disabled={rest.disableSelectedResultsDownload}
            className={classes.button}
            onClick={rest.onDownloadSelectedResults}
          >
            {t("Download selected results")}
          </Button>
        </span>
      </Tooltip>

      {rest.comparison && (
        <Tooltip title={t("CSV file containing all results for all indicators")}>
          <span>
            <Button
              variant="contained"
              color="primary"
              disabled={rest.disableAllResultsDownload}
              className={classes.button}
              onClick={rest.onDownloadAllResults}
            >
              {t("Download all results")}
            </Button>
          </span>
        </Tooltip>
      )}

      {!rest.comparison && (
        <Tooltip title={t("ZIP archive containing all data files")}>
          <span>
            <Button
              variant="contained"
              color="primary"
              disabled={rest.disableAllDataDownload}
              className={classes.button}
              onClick={rest.onDownloadAllData}
            >
              {t("Download all data")}
            </Button>
          </span>
        </Tooltip>
      )}
    </div>
  </div>
);

ResultsExporter.propTypes = {
  comparision: PropTypes.bool,
  selectedMeasures: PropTypes.arrayOf(measurePropType),
  indicators: PropTypes.arrayOf(
    PropTypes.shape({
      value: PropTypes.string.isRequired,
      label: PropTypes.string.isRequired,
    })
  ).isRequired,
  selectedIndicators: PropTypes.arrayOf(PropTypes.string),
  disableSelectedResultsDownload: PropTypes.bool.isRequired,
  disableAllResultsDownload: PropTypes.bool.isRequired,
  disableAllDataDownload: PropTypes.bool.isRequired,
  onToggleMeasure: PropTypes.func.isRequired,
  onToggleIndicator: PropTypes.func.isRequired,
  onDownloadSelectedResults: PropTypes.func.isRequired,
  onDownloadAllResults: PropTypes.func.isRequired,
  onDownloadAllData: PropTypes.func,
};

ResultsExporter.defaultProps = {
  comparison: false,
  selectedMeasures: [],
  selectedIndicators: [],
};

export default withTranslation()(ResultsExporter);
