import React from "react";
import * as PropTypes from "prop-types";
import { parseNullableAlphaCell, parseIntegerCell } from "../../utilities";
import { AgGridReact } from "ag-grid-react";
import "ag-grid-enterprise";
import "ag-grid-community/styles/ag-grid.css";
import "ag-grid-community/styles/ag-theme-material.css";
import { withTranslation } from "react-i18next";
import { translateGridText } from "../../i18n";
import BooleanCellRenderer from "../BooleanCellRenderer";
import { maximumYear, minimumYear } from "../../constants";

const PrepareDataPopulationDataTable = ({ t, ...rest }) => {
  const onGridReady = (params) => {
    rest.onGridReady(params);
    params.columnApi.autoSizeAllColumns();
  };

  const onSelectionChanged = () => {
    rest.onSelectedChanged();
  };

  const components = React.useMemo(
    () => ({
      booleanCellRenderer: BooleanCellRenderer,
    }),
    []
  );

  const colDefs = [
    {
      headerName: t("Region code"),
      field: "regionCode",
      valueParser: (params) => parseNullableAlphaCell(params),
    },
    {
      headerName: t("In union?"),
      field: "isInUnion",
      cellRenderer: "booleanCellRenderer",
      cellEditor: "agRichSelectCellEditor",
      cellEditorParams: {
        cellRenderer: "booleanCellRenderer",
        values: [true, false],
      },
      cellEditorPopup: true,
    },
    {
      headerName: t("Age"),
      field: "ageRange",
      cellEditor: "agRichSelectCellEditor",
      cellEditorParams: {
        values: ["10-14", "15-19", "20-24", "25-29", "30-34", "35-39", "40-44", "45-49", "15-49"],
      },
      cellEditorPopup: true,
    },
    {
      headerName: t("Year"),
      field: "midYear",
      type: "numericColumn",
      filter: "agNumberColumnFilter",
      valueParser: (params) => parseIntegerCell(params, minimumYear, maximumYear),
    },
    {
      headerName: t("Population"),
      field: "population",
      type: "numericColumn",
      filter: "agNumberColumnFilter",
    },
  ];

  const rowData = rest.data;

  return (
    <div className="ag-theme-material">
      <AgGridReact
        domLayout="autoHeight"
        defaultColDef={{ sortable: true, editable: true }}
        pagination={true}
        paginationPageSize={10}
        suppressColumnVirtualisation={true}
        suppressCellFocus={true}
        components={components}
        overlayNoRowsTemplate="<span />"
        getLocaleText={translateGridText}
        rowData={rowData}
        columnDefs={colDefs}
        onGridReady={onGridReady}
        rowSelection="single"
        onSelectionChanged={onSelectionChanged}
        stopEditingWhenCellsLoseFocus={true}
      />
    </div>
  );
};

PrepareDataPopulationDataTable.propTypes = {
  data: PropTypes.array,
};

PrepareDataPopulationDataTable.defaultProps = {
  data: [],
};

export default withTranslation()(PrepareDataPopulationDataTable);

/* <div className={classNames("ag-theme-material", classes.table)}>
        <AgGridReact
          defaultColDef={{
            sortable: true,
            editable: true,
          }}
          rowSelection="single"
          suppressColumnVirtualisation={true}
          suppressScrollOnNewData={true}
          frameworkComponents={{ booleanCellRenderer: BooleanCellRenderer }}
          overlayNoRowsTemplate="<span />"
          getLocaleText={translateGridText}
          rowData={props.data}
          onGridReady={onGridReady}
          onSelectionChanged={onSelectionChanged}
          onCellValueChanged={(params) => {
            props.onEditItem(params.data);
          }}
        >
          <AgGridColumn
            headerName={props.t("Region code")}
            field="regionCode"
            valueParser={(params) => parseNullableAlphaCell(params)}
          />
          <AgGridColumn
            headerName={props.t("In union?")}
            field="isInUnion"
            cellRenderer="booleanCellRenderer"
            cellEditor="agRichSelectCellEditor"
            cellEditorParams={{
              cellRenderer: "booleanCellRenderer",
              values: [true, false],
            }}
          />
          <AgGridColumn
            headerName={props.t("Age")}
            field="ageRange"
            cellEditor="agRichSelectCellEditor"
            cellEditorParams={{
              values: ["10-14", "15-19", "20-24", "25-29", "30-34", "35-39", "40-44", "45-49", "15-49"],
            }}
          />
          <AgGridColumn
            headerName={props.t("Year")}
            field="midYear"
            type="numericColumn"
            filter="agNumberColumnFilter"
            valueParser={(params) => parseIntegerCell(params, minimumYear, maximumYear)}
          />
          <AgGridColumn
            headerName={props.t("Population")}
            field="population"
            type="numericColumn"
            filter="agNumberColumnFilter"
          />
        </AgGridReact>
      </div> */
