// import React from "react";
// import ErrorBoundary from "./components/ErrorBoundary";
import ErrorBoundaryHook from "./components/ErrorBoundaryHook";
import { withErrorBoundary as withReactErrorBoundary } from "react-error-boundary";

// export const withErrorBoundary = Component => props => (
//   <ErrorBoundary>
//     <Component {...props} />
//   </ErrorBoundary>
// );

export const withErrorBoundary = (Component) => {
  return withReactErrorBoundary(Component, {
    FallbackComponent: ErrorBoundaryHook,
  });
};
