import React from "react";
import * as PropTypes from "prop-types";
import IconButton from "@mui/material/IconButton";
import EnIcon from "./EnIcon";
import FrIcon from "./FrIcon";

const styles = {
  button: {
    padding: "4px",
  },
  container: {
    whiteSpace: "nowrap",
  },
  icon: {
    width: 30,
  },
};

const LanguageChooser = (props) => (
  <div style={styles.container}>
    <IconButton
      sx={styles.button}
      onClick={() => {
        props.onChangeLanguage("en");
      }}
      size="large"
    >
      <EnIcon sx={styles.icon} />
    </IconButton>
    <IconButton
      sx={styles.button}
      onClick={() => {
        props.onChangeLanguage("fr");
      }}
      size="large"
    >
      <FrIcon sx={styles.icon} />
    </IconButton>
  </div>
);

LanguageChooser.propTypes = {
  onChangeLanguage: PropTypes.func.isRequired,
};

export default LanguageChooser;
