import React from "react";
import * as PropTypes from "prop-types";
import {
  formatNumericCell,
  parseNullableAlphaCell,
  parseNullableNumericCell,
  parseNumericCell,
  parseIntegerCell,
} from "../../utilities";
import { AgGridReact } from "ag-grid-react";
import "ag-grid-enterprise";
import "ag-grid-community/styles/ag-grid.css";
import "ag-grid-community/styles/ag-theme-material.css";
import { withTranslation } from "react-i18next";
import { translateGridText } from "../../i18n";
import BooleanCellRenderer from "../BooleanCellRenderer";
import { maximumYear, minimumYear } from "../../constants";

const PrepareDataEmuDataTable = ({ t, ...rest }) => {
  const onGridReady = (params) => {
    rest.onGridReady(params);
    params.columnApi.autoSizeAllColumns();
  };

  const onSelectionChanged = () => {
    rest.onSelectedChanged();
  };

  const components = React.useMemo(
    () => ({
      booleanCellRenderer: BooleanCellRenderer,
    }),
    []
  );

  const colDefs = [
    {
      headerName: t("Region code"),
      field: "regionCode",
      valueParser: (params) => parseNullableAlphaCell(params),
    },
    {
      headerName: t("Reference pop"),
      field: "referencePop",
      cellEditor: "agRichSelectCellEditor",
      cellEditorParams: {
        values: ["All women", "Married women"],
      },
      cellEditorPopup: true,
    },
    {
      headerName: t("Service statistic type"),
      field: "serviceStatisticType",
      cellEditor: "agRichSelectCellEditor",
      cellEditorParams: {
        values: ["clients", "facilities", "users", "visits"],
      },
      cellEditorPopup: true,
    },
    {
      headerName: t("Year"),
      field: "startYear",
      type: "numericColumn",
      filter: "agNumberColumnFilter",
      valueParser: (params) => parseIntegerCell(params, minimumYear, maximumYear),
    },
    {
      headerName: t("EMU"),
      field: "emu",
      type: "numericColumn",
      filter: "agNumberColumnFilter",
      valueFormatter: (params) => formatNumericCell(params.value, 3),
      valueParser: (params) => parseNumericCell(params, 0, 1),
    },
    {
      headerName: t("SD_EMU"),
      field: "sD_EMU",
      type: "numericColumn",
      filter: "agNumberColumnFilter",
      valueParser: (params) => parseNullableNumericCell(params, 0, Number.MAX_VALUE),
    },
    {
      headerName: t("EMU_ROC"),
      field: "emU_ROC",
      type: "numericColumn",
      filter: "agNumberColumnFilter",
      valueParser: (params) => parseNullableNumericCell(params, -Number.MAX_VALUE, Number.MAX_VALUE),
    },
    {
      headerName: t("SD_EMU_ROC"),
      field: "sD_EMU_ROC",
      type: "numericColumn",
      filter: "agNumberColumnFilter",
      valueParser: (params) => parseNullableNumericCell(params, 0, Number.MAX_VALUE),
    },
    {
      headerName: t("Include?"),
      field: "included",
      cellRenderer: "booleanCellRenderer",
      cellEditor: "agRichSelectCellEditor",
      cellEditorParams: {
        cellRenderer: "booleanCellRenderer",
        values: [true, false],
      },
      cellEditorPopup: true,
    },
  ];

  const rowData = rest.data;

  return (
    <div className="ag-theme-material">
      <AgGridReact
        domLayout="autoHeight"
        defaultColDef={{ sortable: true, editable: true }}
        pagination={true}
        paginationPageSize={10}
        suppressColumnVirtualisation={true}
        suppressCellFocus={true}
        components={components}
        overlayNoRowsTemplate="<span />"
        getLocaleText={translateGridText}
        rowData={rowData}
        columnDefs={colDefs}
        onGridReady={onGridReady}
        rowSelection="single"
        onSelectionChanged={onSelectionChanged}
        stopEditingWhenCellsLoseFocus={true}
      />
    </div>
  );
};

PrepareDataEmuDataTable.propTypes = {
  data: PropTypes.array,
  onDeleteItem: PropTypes.func,
  onAddItem: PropTypes.func,
};

PrepareDataEmuDataTable.defaultProps = {
  data: [],
};

export default withTranslation()(PrepareDataEmuDataTable);

/* <div className={classNames("ag-theme-material", classes.table)}>
        <AgGridReact
          defaultColDef={{
            sortable: true,
            editable: true,
          }}
          rowSelection="single"
          suppressColumnVirtualisation={true}
          suppressScrollOnNewData={true}
          frameworkComponents={{ booleanCellRenderer: BooleanCellRenderer }}
          overlayNoRowsTemplate="<span />"
          getLocaleText={translateGridText}
          rowData={props.data}
          onGridReady={onGridReady}
          onSelectionChanged={onSelectionChanged}
          onCellValueChanged={(params) => {
            props.onEditItem(params.data);
          }}
        >
          <AgGridColumn
            headerName={props.t("Region code")}
            field="regionCode"
            valueParser={(params) => parseNullableAlphaCell(params)}
          />
          <AgGridColumn
            headerName={props.t("Reference pop")}
            field="referencePop"
            cellEditor="agRichSelectCellEditor"
            cellEditorParams={{
              values: ["All women", "Married women"],
            }}
          />
          <AgGridColumn
            headerName={props.t("Service statistic type")}
            field="serviceStatisticType"
            cellEditor="agRichSelectCellEditor"
            cellEditorParams={{
              values: ["clients", "facilities", "users", "visits"],
            }}
          />
          <AgGridColumn
            headerName={props.t("Year")}
            field="startYear"
            type="numericColumn"
            filter="agNumberColumnFilter"
            valueParser={(params) => parseIntegerCell(params, minimumYear, maximumYear)}
          />
          <AgGridColumn
            headerName={props.t("EMU")}
            field="emu"
            type="numericColumn"
            filter="agNumberColumnFilter"
            valueFormatter={(params) => formatNumericCell(params.value, 3)}
            valueParser={(params) => parseNumericCell(params, 0, 1)}
          />
          <AgGridColumn
            headerName={props.t("SD_EMU")}
            field="sD_EMU"
            type="numericColumn"
            filter="agNumberColumnFilter"
            valueParser={(params) => parseNullableNumericCell(params, 0, Number.MAX_VALUE)}
          />
          <AgGridColumn
            headerName={props.t("EMU_ROC")}
            field="emU_ROC"
            type="numericColumn"
            filter="agNumberColumnFilter"
            valueParser={(params) => parseNullableNumericCell(params, -Number.MAX_VALUE, Number.MAX_VALUE)}
          />
          <AgGridColumn
            headerName={props.t("SD_EMU_ROC")}
            field="sD_EMU_ROC"
            type="numericColumn"
            filter="agNumberColumnFilter"
            valueParser={(params) => parseNullableNumericCell(params, 0, Number.MAX_VALUE)}
          />
          <AgGridColumn
            headerName={props.t("Include?")}
            field="included"
            cellRenderer="booleanCellRenderer"
            cellEditor="agRichSelectCellEditor"
            cellEditorParams={{
              cellRenderer: "booleanCellRenderer",
              values: [true, false],
            }}
          />
        </AgGridReact>
      </div> */
