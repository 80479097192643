import React from "react";
import * as PropTypes from "prop-types";
import { css } from "@emotion/css";
import { periodPropType } from "../../propTypes";
import Divider from "@mui/material/Divider";
import LinearProgress from "@mui/material/LinearProgress";
import Select from "react-select";
import { withTranslation } from "react-i18next";
import PeriodSelector from "../PeriodSelector/PeriodSelector";
import ProgressButton from "../ProgressButton";

const classes = {
  actionButton: css`
    display: inline-flex;
    margin-top: 10px;
  `,
  countrySelector: css`
    display: none;
  `,
  divider: css`
    margin: 10px 0;
  `,
  instruction: css`
    margin: 10px 0;
    font-weight: bold;
  `,
  periodSelector: css`
    margin: 30px 20px 40px;
    z-index: -1;
  `,
  progress: css`
    margin: 15px 0 5px;
  `,
  select: css`
    margin-bottom: 10px;
  `,
  stepNumber: css`
    display: none;
  `,
};

const CompareRunsDrawerContent = ({ t, ...rest }) => {
  const runs = Object.entries(rest.runs).map(([id, run]) => ({
    value: id,
    label: run.name,
  }));

  const countries = Object.entries(rest.countries)
    .map(([numericCode, country]) => ({
      value: parseInt(numericCode, 10),
      label: country.name,
    }))
    .sort((a, b) => a.label.localeCompare(b.label));

  const regions = rest.regions.map((region, index) => ({
    value: -index - 1,
    label: region,
  }));

  const selectedRegion = regions.find((region) => region.label === rest.selectedRegion);

  const selectedRegionValue = selectedRegion && selectedRegion.value;

  const countriesAndRegions = countries.concat(regions);

  return (
    <>
      {rest.loadingData && (
        <div className={classes.progress}>
          <LinearProgress />
        </div>
      )}

      <div className={classes.instruction}>
        <span className={classes.stepNumber}>1.</span> {t("Select runs to compare")}
      </div>

      <Select
        placeholder={t("Select run 1...")}
        options={runs}
        value={runs.filter(({ value }) => value === rest.selectedRunId)}
        className={classes.select}
        onChange={({ value }) => rest.onSelectRun(value)}
      />

      <Select
        placeholder={t("Select run 2...")}
        options={runs}
        value={runs.filter(({ value }) => value === rest.selectedComparisonRunId)}
        className={classes.select}
        onChange={({ value }) => rest.onSelectComparisonRun(value)}
      />

      <div className={classes.countrySelector}>
        <Divider className={classes.divider} />
        <div className={classes.instruction}>2. {t("Select country/population to view")}</div>

        <Select
          placeholder={t("Select country/population...")}
          options={countriesAndRegions}
          value={countriesAndRegions.filter(
            ({ value }) => value === (selectedRegionValue || rest.selectedCountryNumericCode)
          )}
          className={classes.select}
          onChange={({ value }) => {
            if (value >= 0) {
              rest.onSelectCountry(value);
            } else {
              rest.onSelectRegion(regions.find((region) => region.value === value).label);
            }
          }}
        />

        <Divider className={classes.divider} />
      </div>

      <ProgressButton
        variant="contained"
        color="primary"
        disabled={!(rest.selectedCountryNumericCode || rest.selectedRegion)}
        loading={rest.loadingRuns}
        className={classes.actionButton}
        onClick={rest.onCompareRuns}
      >
        {t("Compare runs")}
      </ProgressButton>

      <Divider className={classes.divider} />

      <div className={classes.instruction}>{t("Select years for display/export of results")}</div>

      <div className={classes.periodSelector}>
        <PeriodSelector period={rest.period} onChange={rest.onChangePeriod} />
      </div>
    </>
  );
};

CompareRunsDrawerContent.propTypes = {
  loadingData: PropTypes.bool,
  runs: PropTypes.objectOf(
    PropTypes.shape({
      name: PropTypes.string.isRequired,
    })
  ),
  selectedRunId: PropTypes.string,
  selectedComparisonRunId: PropTypes.string,
  countries: PropTypes.objectOf(
    PropTypes.shape({
      name: PropTypes.string.isRequired,
    })
  ),
  selectedCountryNumericCode: PropTypes.number,
  regions: PropTypes.arrayOf(PropTypes.string),
  selectedRegion: PropTypes.string,
  period: periodPropType,
  loadingRuns: PropTypes.bool,
  onSelectRun: PropTypes.func.isRequired,
  onSelectComparisonRun: PropTypes.func.isRequired,
  onSelectCountry: PropTypes.func.isRequired,
  onSelectRegion: PropTypes.func.isRequired,
  onChangePeriod: PropTypes.func.isRequired,
  onCompareRuns: PropTypes.func.isRequired,
};

CompareRunsDrawerContent.defaultProps = {
  loadingData: false,
  runs: {},
  countries: {},
  regions: [],
  loadingRuns: false,
};

export default withTranslation()(CompareRunsDrawerContent);
