import React from "react";
import * as PropTypes from "prop-types";
import { css } from "@emotion/css";
import { measurePropType, indicatorResultPropType } from "../../propTypes";
import { indicators } from "../../constants";
import IndicatorSelector from "./ResultsIndicatorSelector";
import MaritalStatusSelector from "../MaritalStatusSelector";
import MeasureSelector from "../MeasureSelector";
import ResultsExporter from "./ResultsExporter";
import ResultsDataTable from "../AGGrid/ResultsDataTable";

const classes = {
  container: css`
    margin-top: 20px;
  `,
  gridSelectors: css`
    display: flex;
  `,
  leftSelector: css`
    margin-right: 30px;
  `,
  table: css`
    margin-top: 10px;
  `,
};

const Results = (props) => (
  <div className={classes.container}>
    <div className={classes.gridSelectors}>
      <MeasureSelector
        value={props.selectedMeasure}
        className={classes.leftSelector}
        onChange={props.onChangeMeasure}
      />
      <IndicatorSelector
        indicators={indicators.filter(
          (indicator) => props.selectedMeasure === "percentage" || indicator.value !== "ratioModernAny"
        )}
        value={props.selectedIndicator}
        className={classes.leftSelector}
        onChange={props.onChangeIndicator}
      />
      <MaritalStatusSelector value={props.selectedMaritalStatus} onChange={props.onChangeMaritalStatus} />
    </div>

    <ResultsDataTable
      measure={props.selectedMeasure}
      results={props.results}
      comparison={props.comparison}
      className={classes.table}
    />

    <div>
      <ResultsExporter
        comparison={props.comparison}
        selectedMeasures={props.selectedExportedMeasures}
        indicators={indicators}
        selectedIndicators={props.selectedExportedIndicators}
        disableSelectedResultsDownload={props.disableSelectedResultsDownload}
        disableAllResultsDownload={props.disableAllResultsDownload}
        disableAllDataDownload={props.disableAllDataDownload}
        onToggleMeasure={props.onToggleExportedMeasure}
        onToggleIndicator={props.onToggleExportedIndicator}
        onDownloadSelectedResults={props.onDownloadSelectedResults}
        onDownloadAllResults={props.onDownloadAllResults}
        onDownloadAllData={props.onDownloadAllData}
      />
    </div>
  </div>
);

Results.propTypes = {
  comparison: PropTypes.bool,
  selectedMeasure: measurePropType.isRequired,
  selectedIndicator: PropTypes.string.isRequired,
  selectedMaritalStatus: PropTypes.string.isRequired,
  results: PropTypes.arrayOf(indicatorResultPropType),
  selectedExportedMeasures: PropTypes.arrayOf(measurePropType),
  selectedExportedIndicators: PropTypes.arrayOf(PropTypes.string),
  disableSelectedResultsDownload: PropTypes.bool.isRequired,
  disableAllResultsDownload: PropTypes.bool.isRequired,
  disableAllDataDownload: PropTypes.bool,
  onChangeMeasure: PropTypes.func.isRequired,
  onChangeIndicator: PropTypes.func.isRequired,
  onChangeMaritalStatus: PropTypes.func.isRequired,
  onToggleExportedMeasure: PropTypes.func.isRequired,
  onToggleExportedIndicator: PropTypes.func.isRequired,
  onDownloadSelectedResults: PropTypes.func.isRequired,
  onDownloadAllResults: PropTypes.func.isRequired,
  onDownloadAllData: PropTypes.func,
};

Results.defaultProps = {
  comparison: false,
  results: [],
  selectedExportedMeasures: [],
  selectedExportedIndicators: [],
  disableAllDataDownload: true,
};

export default Results;
