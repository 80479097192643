import React from "react";
import * as PropTypes from "prop-types";
import { populationDatumPropType } from "../../propTypes";
import { formatLogicalCell } from "../../utilities";
import { AgGridReact } from "ag-grid-react";
import "ag-grid-enterprise";
import "ag-grid-community/styles/ag-grid.css";
import "ag-grid-community/styles/ag-theme-material.css";
import { withTranslation } from "react-i18next";
import { translateGridText } from "../../i18n";

const PopulationData = ({ t, ...rest }) => {
  const colDefs = [
    { field: "regionCode", headerName: t("Region code") },
    { field: "isInUnion", headerName: t("In union?"), valueFormatter: (params) => formatLogicalCell(params.value) },

    { field: "ageRange", headerName: "Age" },
    {
      field: "midYear",
      headerName: t("Year"),
      type: "numericColumn",
      filter: "agNumberColumnFilter",
    },
    {
      field: "population",
      headerName: t("Population"),
      type: "numericColumn",
      filter: "agNumberColumnFilter",
    },
  ];

  const rowData = rest.data;

  return (
    <div className="ag-theme-material">
      <AgGridReact
        domLayout="autoHeight"
        defaultColDef={{ sortable: true, filter: true }}
        pagination={true}
        paginationPageSize={10}
        suppressColumnVirtualisation={true}
        suppressCellFocus={true}
        overlayNoRowsTemplate="<span />"
        getLocaleText={translateGridText}
        rowData={rowData}
        columnDefs={colDefs}
        // autoSizeStrategy={{ type: "fitGridWidth" }}
        onGridReady={(params) => params.columnApi.autoSizeAllColumns()}
      />
    </div>
  );
};

PopulationData.propTypes = {
  data: PropTypes.arrayOf(populationDatumPropType),
};

PopulationData.defaultProps = {
  data: [],
};

export default withTranslation()(PopulationData);
