import React from "react";
import * as PropTypes from "prop-types";
import FormControl from "@mui/material/FormControl";
import Input from "@mui/material/Input";
import InputLabel from "@mui/material/InputLabel";
import NativeSelect from "@mui/material/NativeSelect";
import { withTranslation } from "react-i18next";

const ChartSetSelector = ({ t, ...rest }) => (
  <div className={rest.className}>
    <FormControl>
      <InputLabel htmlFor="input" shrink={true}>
        {t("Chart set")}
      </InputLabel>

      <NativeSelect
        input={<Input id="input" />}
        value={rest.value}
        onChange={e => {
          rest.onChange(e.target.value);
        }}
      >
        {rest.chartSets.map(chartSet => (
          <option key={chartSet.value} value={chartSet.value}>
            {t(chartSet.label)}
          </option>
        ))}
      </NativeSelect>
    </FormControl>
  </div>
);

ChartSetSelector.propTypes = {
  chartSets: PropTypes.arrayOf(
    PropTypes.shape({
      value: PropTypes.string.isRequired,
      label: PropTypes.string.isRequired
    })
  ).isRequired,
  value: PropTypes.string,
  className: PropTypes.string,
  onChange: PropTypes.func.isRequired
};

export default withTranslation()(ChartSetSelector);
