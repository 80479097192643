import React from "react";
import * as PropTypes from "prop-types";
import { css } from "@emotion/css";
import { directionPropType, labeledValuePropType, maritalStatusPropType } from "../../propTypes";
import { maximumYear, minimumYear } from "../../constants";
import classNames from "classnames";
import Input from "@mui/material/Input";
import InputAdornment from "@mui/material/InputAdornment";
import NativeSelect from "@mui/material/NativeSelect";
import MaritalStatusSelector from "../SentenceMaritalStatusSelector";

const classes = {
  container: css`
    display: inline-flex;
    flex-wrap: wrap;
    align-items: baseline;
  `,
  input: css`
    margin: 0 0.5em;
  `,
  percentage: css`
    width: 5rem;
  `,
  year: css`
    width: 85px;
  `,
};

const TargetsIndicatorProbabilitySpecification = (props) => (
  <div className={classes.container}>
    <span>What is the probability that</span>

    <NativeSelect
      value={props.indicator}
      className={classes.input}
      onChange={(e) => {
        props.onSelectIndicator(e.target.value);
      }}
    >
      {props.indicators.map((indicator) => (
        <option key={indicator.value} value={indicator.value}>
          {indicator.label}
        </option>
      ))}
    </NativeSelect>

    <span>among</span>

    <MaritalStatusSelector
      value={props.maritalStatus}
      className={classes.input}
      onChange={props.onSelectMaritalStatus}
    />

    <span>women in the year</span>

    <Input
      type="number"
      margin="dense"
      inputProps={{
        min: minimumYear,
        max: maximumYear,
      }}
      value={props.year || ""}
      className={classNames(classes.input, classes.year)}
      onChange={(e) => {
        props.onChangeYear(parseInt(e.target.value, 10) || undefined);
      }}
      onBlur={(e) => {
        props.onChangeYear(parseInt(e.target.value, 10) || undefined, true);
      }}
    />

    <span>is</span>

    <NativeSelect
      value={props.direction}
      className={classes.input}
      onChange={(e) => {
        props.onSelectDirection(e.target.value);
      }}
    >
      <option value="greaterThan">above</option>
      <option value="lessThan">below</option>
    </NativeSelect>

    <Input
      type="number"
      margin="dense"
      inputProps={{
        min: 0,
        max: 100,
      }}
      endAdornment={<InputAdornment position="start">%</InputAdornment>}
      value={props.level || ""}
      className={classNames(classes.input, classes.percentage)}
      onChange={(e) => {
        props.onChangeLevel(parseFloat(e.target.value) || undefined);
      }}
    />

    <span>?</span>
  </div>
);

TargetsIndicatorProbabilitySpecification.propTypes = {
  indicators: PropTypes.arrayOf(labeledValuePropType).isRequired,
  indicator: PropTypes.string.isRequired,
  maritalStatus: maritalStatusPropType,
  year: PropTypes.number,
  direction: directionPropType,
  level: PropTypes.number,
  probability: PropTypes.number,
  onSelectIndicator: PropTypes.func.isRequired,
  onSelectMaritalStatus: PropTypes.func.isRequired,
  onChangeYear: PropTypes.func.isRequired,
  onSelectDirection: PropTypes.func.isRequired,
  onChangeLevel: PropTypes.func.isRequired,
};

export default TargetsIndicatorProbabilitySpecification;
