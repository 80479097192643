import React from "react";
import * as PropTypes from "prop-types";
import { css } from "@emotion/css";
import { databaseTypePropType, periodPropType } from "../../propTypes";
import Button from "@mui/material/Button";
import Divider from "@mui/material/Divider";
import InputLabel from "@mui/material/InputLabel";
import LinearProgress from "@mui/material/LinearProgress";
import TextField from "@mui/material/TextField";
import Select from "react-select";
import { useTranslation } from "react-i18next";
import DatabasePicker from "./StartRunDatabasePicker";
import PeriodSelector from "../PeriodSelector/PeriodSelector";
import ProgressButton from "../ProgressButton";

const classes = {
  actionButton: css`
    display: inline-flex;
    min-height: 36px;
    margin-top: 10px;
  `,
  divider: css`
    margin: 10px 0;
  `,
  drawerItem: css`
    flex-shrink: 0;
  `,
  instruction: css`
    margin: 10px 0;
    font-weight: bold;
  `,
  periodSelector: css`
    margin: 40px 10px;
  `,
  periodSelectorGroup: css`
    margin-top: 10px;
    z-index: -1;
  `,
  periodSelectorLabel: css`
    font-size: 75%;
  `,
  progress: css`
    margin: 15px 0 5px;
  `,
  select: css`
    margin-bottom: 10px;
  `,
};

const groupDatabases = (databases) => {
  const denormalizedDatabases = Object.entries(databases).map(([id, database]) => ({
    id,
    ...database,
  }));

  return {
    surveyDatabases: denormalizedDatabases.filter((database) => database.type === "survey" && database.isGlobal),
    customSurveyDatabases: denormalizedDatabases.filter((database) => database.type === "survey" && !database.isGlobal),
    populationDatabases: denormalizedDatabases.filter(
      (database) => database.type === "population" && database.isGlobal
    ),
    customPopulationDatabases: denormalizedDatabases.filter(
      (database) => database.type === "population" && !database.isGlobal
    ),
    emuDatabases: denormalizedDatabases.filter((database) => database.type === "emu" && database.isGlobal),
    customEmuDatabases: denormalizedDatabases.filter((database) => database.type === "emu" && !database.isGlobal),
  };
};

const StartRunDrawerContent = (props) => {
  const {
    surveyDatabases,
    customSurveyDatabases,
    populationDatabases,
    customPopulationDatabases,
    customEmuDatabases,
  } = groupDatabases(props.databases);

  const countries = Object.entries(props.countries || {})
    .map(([numericCode, country]) => ({
      value: parseInt(numericCode, 10),
      label: country.name,
    }))
    .sort((a, b) => a.label.localeCompare(b.label));

  const regions = props.regions.map((region, index) => ({
    value: -index - 1,
    label: region,
  }));

  const selectedRegion = regions.find((region) => region.label === props.selectedRegion);

  const selectedRegionValue = selectedRegion && selectedRegion.value;

  const countriesAndRegions = countries.concat(regions);

  //  const getNations = (countryCode) => {
  //    if (!countryCode) {
  //        return [];
  //    }

  //    return [
  //        { value: "National", label: "National" },
  //        { value: "Subnational", label: "Subnational" },
  //    ]
  //  }

  //   const selectedNationValue = props.selectedNation;
  //   const nations = getNations(props.selectedCountryNumericCode);

  const { t } = useTranslation();

  return (
    <>
      {props.loadingData && (
        <div className={classes.progress}>
          <LinearProgress />
        </div>
      )}

      <div className={classes.instruction}>1a. {t("Select survey database")}</div>

      <DatabasePicker
        databaseType={props.surveyDatabaseType}
        defaultDatabases={surveyDatabases}
        customDatabases={customSurveyDatabases}
        selectedDatabaseId={props.selectedSurveyDatabaseId}
        uploadedDatabaseName={props.uploadedSurveyDatabaseName}
        databaseUploadProgress={props.surveyDatabaseUploadProgress}
        uploadingDatabase={props.uploadingSurveyDatabase}
        onChangeDatabaseType={props.onChangeSurveyDatabaseType}
        onSelectDatabase={props.onSelectSurveyDatabase}
        onSelectDatabaseFile={props.onSelectSurveyDatabaseFile}
        onUploadDatabase={props.onUploadSurveyDatabase}
        onCancelDatabaseUpload={props.onCancelSurveyDatabaseUpload}
      />

      <Divider className={classes.divider} />

      <div className={classes.instruction}>1b. {t("Select population database")}</div>

      <DatabasePicker
        databaseType={props.populationDatabaseType}
        defaultDatabases={populationDatabases}
        customDatabases={customPopulationDatabases}
        selectedDatabaseId={props.selectedPopulationDatabaseId}
        uploadedDatabaseName={props.uploadedPopulationDatabaseName}
        databaseUploadProgress={props.populationDatabaseUploadProgress}
        uploadingDatabase={props.uploadingPopulationDatabase}
        onChangeDatabaseType={props.onChangePopulationDatabaseType}
        onSelectDatabase={props.onSelectPopulationDatabase}
        onSelectDatabaseFile={props.onSelectPopulationDatabaseFile}
        onUploadDatabase={props.onUploadPopulationDatabase}
        onCancelDatabaseUpload={props.onCancelPopulationDatabaseUpload}
      />

      <Divider className={classes.divider} />

      <div className={classes.instruction}>2. {t("Select service statistics")}</div>

      <DatabasePicker
        variant="emu"
        databaseType={props.emuDatabaseType}
        customDatabases={[{ id: "", name: t("None") }, ...customEmuDatabases]}
        selectedDatabaseId={props.selectedEmuDatabaseId}
        uploadedDatabaseName={props.uploadedEmuDatabaseName}
        databaseUploadProgress={props.emuDatabaseUploadProgress}
        uploadingDatabase={props.uploadingEmuDatabase}
        onChangeDatabaseType={props.onChangeEmuDatabaseType}
        onSelectDatabase={props.onSelectEmuDatabase}
        onSelectDatabaseFile={props.onSelectEmuDatabaseFile}
        onUploadDatabase={props.onUploadEmuDatabase}
        onCancelDatabaseUpload={props.onCancelEmuDatabaseUpload}
      />

      <Divider className={classes.divider} />

      <div className={classes.instruction}>3. {t("Select country/population")}</div>

      <Select
        placeholder={t("Select country/population")}
        options={countriesAndRegions}
        value={countriesAndRegions.filter(
          ({ value }) => value === (selectedRegionValue || props.selectedCountryNumericCode)
        )}
        noOptionsMessage={() => "No countries available"}
        className={classes.select}
        onChange={({ value }) => {
          if (value >= 0) {
            props.onSelectCountry(value);
          } else {
            props.onSelectRegion(regions.find((region) => region.value === value).label);
          }
        }}
      />

      <Divider className={classes.divider} />

      {/* <>
        <div className={classes.instruction}>
            3b. {t("Select national/subnational")}
        </div>

        <Select
            placeholder={t("Select national/subnational")}
            options={nations}
            value={nations.filter(
            ({ value }) =>
                value === (selectedNationValue)
            )}
            noOptionsMessage={() => "No options available"}
            className={classes.select}
            onChange={({ value }) => {
                props.onSelectNation(value);
            }}
        />

        <Divider className={classes.divider} />
      </> */}

      <div className={classes.instruction}>4. {t("Input run settings")}</div>

      <TextField
        label={t("Run name")}
        placeholder={t("Enter run name...")}
        InputLabelProps={{
          shrink: true,
          required: true,
        }}
        value={props.runName || ""}
        className={classes.drawerItem}
        onChange={(e) => {
          props.onRunNameChanged(e.target.value);
        }}
      />

      <div className={classes.periodSelectorGroup}>
        <div className="hidden">
          <InputLabel className={classes.periodSelectorLabel}>{t("Estimate years")}</InputLabel>

          <div className={classes.periodSelector}>
            <PeriodSelector period={props.period} onChange={props.onChangePeriod} />
          </div>
        </div>
      </div>

      <Divider className={classes.divider} />

      <ProgressButton
        variant="contained"
        disabled={!(props.selectedCountryNumericCode || props.selectedRegion)}
        loading={props.loadingTables}
        className={classes.actionButton}
        onClick={props.onReviewData}
      >
        {t("Review data")}
      </ProgressButton>

      <Button
        variant="contained"
        color="primary"
        disabled={!(props.selectedCountryNumericCode || props.selectedRegion) || !props.runName}
        className={classes.actionButton}
        onClick={props.onStartRun}
      >
        {t("Start run")}
      </Button>
    </>
  );
};

StartRunDrawerContent.propTypes = {
  loadingData: PropTypes.bool,
  databases: PropTypes.objectOf(
    PropTypes.shape({
      name: PropTypes.string.isRequired,
    })
  ),
  surveyDatabaseType: databaseTypePropType,
  selectedSurveyDatabaseId: PropTypes.string,
  uploadedSurveyDatabaseName: PropTypes.string,
  uploadingSurveyDatabase: PropTypes.bool,
  surveyDatabaseUploadProgress: PropTypes.number,
  populationDatabaseType: databaseTypePropType,
  selectedPopulationDatabaseId: PropTypes.string,
  uploadedPopulationDatabaseName: PropTypes.string,
  uploadingPopulationDatabase: PropTypes.bool,
  populationDatabaseUploadProgress: PropTypes.number,
  emuDatabaseType: databaseTypePropType,
  selectedEmuDatabaseId: PropTypes.string,
  uploadedEmuDatabaseName: PropTypes.string,
  uploadingEmuDatabase: PropTypes.bool,
  emuDatabaseUploadProgress: PropTypes.number,
  countries: PropTypes.objectOf(
    PropTypes.shape({
      name: PropTypes.string.isRequired,
    })
  ),
  selectedCountryNumericCode: PropTypes.number,
  regions: PropTypes.arrayOf(PropTypes.string),
  selectedRegion: PropTypes.string,
  // selectedNation: PropTypes.string,
  runName: PropTypes.string,
  period: periodPropType,
  loadingTables: PropTypes.bool,
  onChangeSurveyDatabaseType: PropTypes.func.isRequired,
  onSelectSurveyDatabase: PropTypes.func.isRequired,
  onSelectSurveyDatabaseFile: PropTypes.func.isRequired,
  onUploadSurveyDatabase: PropTypes.func.isRequired,
  onCancelSurveyDatabaseUpload: PropTypes.func.isRequired,
  onChangePopulationDatabaseType: PropTypes.func.isRequired,
  onSelectPopulationDatabase: PropTypes.func.isRequired,
  onSelectPopulationDatabaseFile: PropTypes.func.isRequired,
  onUploadPopulationDatabase: PropTypes.func.isRequired,
  onCancelPopulationDatabaseUpload: PropTypes.func.isRequired,
  onChangeEmuDatabaseType: PropTypes.func.isRequired,
  onSelectEmuDatabase: PropTypes.func.isRequired,
  onSelectEmuDatabaseFile: PropTypes.func.isRequired,
  onUploadEmuDatabase: PropTypes.func.isRequired,
  onCancelEmuDatabaseUpload: PropTypes.func.isRequired,
  onSelectCountry: PropTypes.func.isRequired,
  onSelectRegion: PropTypes.func.isRequired,
  onRunNameChanged: PropTypes.func.isRequired,
  onChangePeriod: PropTypes.func.isRequired,
  onReviewData: PropTypes.func.isRequired,
  onStartRun: PropTypes.func.isRequired,
  // onSelectNation : PropTypes.func.isRequired,
};

StartRunDrawerContent.defaultProps = {
  loadingData: false,
  databases: {},
  surveyDatabaseType: "default",
  uploadingSurveyDatabase: false,
  populationDatabaseType: "default",
  uploadingPopulationDatabase: false,
  emuDatabaseType: "default",
  uploadingEmuDatabase: false,
  regions: [],
  loadingTables: false,
};

export default StartRunDrawerContent;
