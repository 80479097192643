import React from "react";
import * as PropTypes from "prop-types";
import { css } from "@emotion/css";
import { Link, withRouter } from "react-router-dom";
import AppBar from "@mui/material/AppBar";
import Button from "@mui/material/Button";
import Tab from "@mui/material/Tab";
import Tabs from "@mui/material/Tabs";
import Toolbar from "@mui/material/Toolbar";
import Typography from "@mui/material/Typography";
import LanguageChooser from "./LanguageChooser/LanguageChooser";
import { withTranslation } from "react-i18next";

const styles = {
  appBar: {
    zIndex: (theme) => theme.zIndex.drawer + 1,
  },
  Tabs: {
    display: "flex",
    flex: 1,
    "& .MuiTabs-flexContainer": {
      justifyContent: "space-evenly",
    },
    "& a": {
      color: "#FFFFFF",
      opacity: 0.7,
      fontSize: "13px",
    },
    "& a.Mui-selected": {
      color: "#FFFFFF",
      opacity: 1.0,
    },
  },
  brand: {
    marginRight: "30px",
    whiteSpace: "nowrap",
  },
  //   signOut: {
  //     display: "flex",
  //     flexGrow: 1,
  //     justifyContent: "flex-end",
  //   },
};

const classes = {
  languages: css`
    margin-right: 20px;
  `,
  signOutLabel: css`
    white-space: nowrap;
  `,
};

const getMainSectionPath = (path) => `/${path.split("/")[1]}`;

const AppBarCustom = (props) => {
  const { location, onChangeLanguage, onLogout, t } = props;

  const mainSectionPath = getMainSectionPath(location.pathname);

  return (
    <AppBar position="sticky" sx={styles.appBar}>
      <Toolbar variant="dense">
        <Typography variant="h6" color="inherit" sx={styles.brand}>
          {t("APP_TITLE")}
        </Typography>

        <Tabs
          variant="scrollable"
          scrollButtons="auto"
          value={mainSectionPath}
          TabIndicatorProps={{ style: { background: "#f50057" } }}
          sx={styles.Tabs}
        >
          <Tab label={t("About")} value="/" component={Link} to="/" />
          <Tab
            label={t("Start run|APP", {
              defaultValue: "Start run",
            })}
            value="/startrun"
            component={Link}
            to="/startrun"
          />
          <Tab label={t("View run")} value="/viewrun" component={Link} to="/viewrun" />
          <Tab label={t("Compare runs")} value="/compareruns" component={Link} to="/compareruns" />
          <Tab label={t("Run history")} value="/history" component={Link} to="/history" />
          <Tab label={t("Prepare data")} value="/preparedata" component={Link} to="/preparedata" />
          <Tab label={t("Manage data")} value="/managedata" component={Link} to="/managedata" />
        </Tabs>

        {/* <div style={{ flex: 1 }} /> */}

        <div className={classes.languages}>
          <LanguageChooser onChangeLanguage={onChangeLanguage} />
        </div>

        <div className={classes.signOutLabel}>
          <Button color="inherit" onClick={onLogout}>
            {t("Sign out")}
          </Button>
        </div>
      </Toolbar>
    </AppBar>
  );
};

AppBarCustom.propTypes = {
  onChangeLanguage: PropTypes.func.isRequired,
  onLogout: PropTypes.func.isRequired,
};

export default withRouter(withTranslation()(AppBarCustom));
