import React from "react";
import * as PropTypes from "prop-types";
import Avatar from "@mui/material/Avatar";
import Button from "@mui/material/Button";
import Checkbox from "@mui/material/Checkbox";
import FormControl from "@mui/material/FormControl";
import FormControlLabel from "@mui/material/FormControlLabel";
import Input from "@mui/material/Input";
import InputLabel from "@mui/material/InputLabel";
import Paper from "@mui/material/Paper";
import Typography from "@mui/material/Typography";
import Box from "@mui/material/Box";
import LockOutlinedIcon from "@mui/icons-material/LockOutlined";
import { withTranslation } from "react-i18next";
import LanguageChooser from "./LanguageChooser/LanguageChooser";
import LastUpdated from "./LastUpdated";
import CircularProgress from "@mui/material/CircularProgress";

const styles = {
  avatar: {
    margin: 1,
    backgroundColor: (theme) => theme.palette.secondary.main,
  },
  container: {
    width: "400px",
    display: "block", // Fix IE 11 issue
    marginLeft: "auto",
    marginRight: "auto",
    md: {
      width: "400px",
      marginLeft: "auto",
      marginRight: "auto",
    },
  },
  error: {
    marginTop: "20px",
    fontWeight: "bold",
    color: (theme) => theme.palette.error.dark,
  },
  form: {
    width: "100%", // Fix IE 11 issue
    marginTop: 1,
  },
  languages: {
    margin: "20px 0 10px",
    textAlign: "center",
  },
  lastUpdated: {
    textAlign: "center",
  },
  paper: {
    marginTop: 8,
    display: "flex",
    flexDirection: "column",
    alignItems: "center",
    paddingLeft: 3,
    paddingRight: 3,
    paddingTop: 1,
    paddingBottom: 3,
  },
  register: {
    marginTop: "20px",
  },
  submit: {
    marginTop: 3,
    height: "40px",
  },
};

const Login = ({ t, ...rest }) => {
  return (
    <Box sx={styles.container}>
      <Paper sx={styles.paper}>
        <Avatar sx={styles.avatar}>
          <LockOutlinedIcon />
        </Avatar>

        <Typography component="h1" variant="h5">
          {t("Sign in")}
        </Typography>

        {rest.systemStatusMessage ? <Box sx={styles.error}>{rest.systemStatusMessage}</Box> : undefined}

        {rest.error ? <Box sx={styles.error}>{rest.error}</Box> : undefined}

        <form style={styles.form}>
          <FormControl margin="normal" required fullWidth>
            <InputLabel htmlFor="username">{t("Username")}</InputLabel>
            <Input
              id="username"
              name="username"
              autoComplete="username"
              autoFocus
              value={rest.username || ""}
              onChange={(e) => {
                rest.onChangeUsername(e.target.value);
              }}
            />
          </FormControl>

          <FormControl margin="normal" required fullWidth>
            <InputLabel htmlFor="password">{t("Password")}</InputLabel>
            <Input
              name="password"
              type="password"
              id="password"
              value={rest.password || ""}
              autoComplete="current-password"
              onChange={(e) => {
                rest.onChangePassword(e.target.value);
              }}
            />
          </FormControl>

          <FormControlLabel
            control={<Checkbox value="remember" color="primary" />}
            label={t("Remember me")}
            checked={rest.rememberMe}
            onChange={rest.onToggleRememberMe}
          />

          {!rest.loggingIn && (
            <Button
              type="submit"
              fullWidth
              variant="contained"
              color="primary"
              sx={styles.submit}
              disabled={!rest.systemOperational}
              onClick={(e) => {
                e.preventDefault();

                rest.onSubmit(e);
              }}
            >
              {t("Sign in")}
            </Button>
          )}

          {rest.loggingIn && (
            <Button type="submit" fullWidth variant="contained" color="primary" sx={styles.submit} disabled={true}>
              <CircularProgress color="secondary" size="30px" />
            </Button>
          )}

          <Box sx={styles.register}>
            {t("Don't have an account?")}{" "}
            <a
              href={`https://authuser.avenirhealth.org/?sv=${process.env.REACT_APP_AUTHENTICATION_SERVICE_ID}&md=new`}
              target="_blank"
              rel="noopener noreferrer"
            >
              {t("Register")}
            </a>{" "}
            {t("now.")}
          </Box>

          <Box sx={styles.register}>
            {t("Forgotten your password?")}{" "}
            <a
              href={`https://authuser.avenirhealth.org/?sv=${process.env.REACT_APP_AUTHENTICATION_SERVICE_ID}&md=fg`}
              target="_blank"
              rel="noopener noreferrer"
            >
              {t("Reset")}
            </a>{" "}
            {t("it.")}
          </Box>
        </form>
      </Paper>

      <Box sx={styles.languages}>
        <LanguageChooser onChangeLanguage={rest.onChangeLanguage} />
      </Box>

      <Box sx={styles.lastUpdated}>
        <LastUpdated />
      </Box>
    </Box>
  );
};

Login.propTypes = {
  systemOperational: PropTypes.bool,
  systemStatusMessage: PropTypes.string,
  username: PropTypes.string,
  password: PropTypes.string,
  rememberMe: PropTypes.bool.isRequired,
  error: PropTypes.string,
  loggingIn: PropTypes.bool,
  onChangeUsername: PropTypes.func.isRequired,
  onChangePassword: PropTypes.func.isRequired,
  onToggleRememberMe: PropTypes.func.isRequired,
  onSubmit: PropTypes.func.isRequired,
  onChangeLanguage: PropTypes.func.isRequired,
};

Login.defaultProps = {
  systemOperational: true,
};

export default withTranslation()(Login);
