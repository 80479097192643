import React from "react";
import * as PropTypes from "prop-types";
import { css } from "@emotion/css";
import {
  emuDatumPropType,
  indicatorResultPropType,
  labeledValuePropType,
  measurePropType,
  periodPropType,
  resultPropType,
  surveyDatumPropType,
} from "../../propTypes";
import { Route, Switch, Redirect } from "react-router-dom";
import { withTranslation } from "react-i18next";
import Charts from "../Charts/Charts";
import NavigationTab from "../NavigationTab";
import Results from "../Results/Results";
import Title from "../Title";

const classes = {
  tabPage: css`
    margin: 10px;
  `,
  title: css`
    margin-bottom: 20px;
  `,
};

const CompareRunsMainContent = ({ match, t, ...rest }) => (
  <div>
    <div className={classes.title}>
      <Title title={rest.runsTitle} />
    </div>

    <NavigationTab to="/compareruns/results">{t("Results")}</NavigationTab>
    <NavigationTab to="/compareruns/charts">{t("Charts")}</NavigationTab>

    <div className={classes.tabPage}>
      <Switch>
        <Route exact path={match.path} render={() => <Redirect replace to={`${match.path}/results`} />} />
        <Route
          path={`${match.path}/results`}
          render={() => (
            <Results
              comparison={true}
              selectedMeasure={rest.selectedResultsMeasure}
              selectedIndicator={rest.selectedResultsIndicator}
              selectedMaritalStatus={rest.selectedResultsMaritalStatus}
              results={rest.tableResults}
              selectedExportedMeasures={rest.selectedExportedResultsMeasures}
              selectedExportedIndicators={rest.selectedExportedResultsIndicators}
              disableSelectedResultsDownload={rest.disableSelectedResultsDownload}
              disableAllResultsDownload={rest.disableAllResultsDownload}
              onChangeMeasure={rest.onChangeResultsMeasure}
              onChangeIndicator={rest.onChangeResultsIndicator}
              onChangeMaritalStatus={rest.onChangeResultsMaritalStatus}
              onToggleExportedMeasure={rest.onToggleExportedResultsMeasure}
              onToggleExportedIndicator={rest.onToggleExportedResultsIndicator}
              onDownloadSelectedResults={rest.onDownloadSelectedResults}
              onDownloadAllResults={rest.onDownloadAllResults}
            />
          )}
        />
        <Route
          path={`${match.path}/charts`}
          render={() => (
            <Charts
              comparison={true}
              runName={rest.selectedRunName}
              comparisonRunName={rest.selectedComparisonRunName}
              period={rest.period}
              selectedMeasure={rest.selectedChartsMeasure}
              selectedMaritalStatus={rest.selectedChartsMaritalStatus}
              selectedChartsIndicators={rest.selectedChartsIndicators}
              showSurveyLegend={rest.showSurveyLegend}
              results={rest.chartResults}
              surveyData={rest.chartSurveyData}
              emuData={rest.chartEmuData}
              chartSets={rest.chartSets}
              selectedExportedChartSet={rest.selectedExportedChartSet}
              selectedExportedMeasure={rest.selectedExportedChartsMeasure}
              selectedExportedMaritalStatus={rest.selectedExportedChartsMaritalStatus}
              disableChartSetDownload={rest.disableChartSetDownload}
              disableSelectedChartsDownload={rest.disableSelectedChartsDownload}
              onChangeMeasure={rest.onChangeChartsMeasure}
              onChangeMaritalStatus={rest.onChangeChartsMaritalStatus}
              onToggleChart={rest.onToggleChart}
              onToggleSurveyLegend={rest.onToggleSurveyLegend}
              onChangeExportedChartSet={rest.onChangeExportedChartSet}
              onChangeExportedMeasure={rest.onChangeExportedChartsMeasure}
              onChangeExportedMaritalStatus={rest.onChangeExportedChartsMaritalStatus}
              onDownloadChartSet={rest.onDownloadChartSet}
              onDownloadSelectedCharts={rest.onDownloadSelectedCharts}
            />
          )}
        />
      </Switch>
    </div>
  </div>
);

CompareRunsMainContent.propTypes = {
  period: periodPropType,
  runTitle: PropTypes.string,
  selectedRunName: PropTypes.string,
  selectedComparisonRunName: PropTypes.string,
  selectedResultsMeasure: PropTypes.oneOf(["percentage", "count"]).isRequired,
  selectedResultsIndicator: PropTypes.string.isRequired,
  selectedResultsMaritalStatus: PropTypes.string.isRequired,
  tableResults: PropTypes.arrayOf(indicatorResultPropType),
  selectedExportedResultsMeasures: PropTypes.arrayOf(measurePropType),
  selectedExportedResultsIndicators: PropTypes.arrayOf(PropTypes.string),
  disableSelectedResultsDownload: PropTypes.bool.isRequired,
  disableAllResultsDownload: PropTypes.bool.isRequired,
  selectedChartsIndicators: PropTypes.arrayOf(PropTypes.string),
  showSurveyLegend: PropTypes.bool,
  chartResults: resultPropType,
  chartSurveyData: PropTypes.arrayOf(surveyDatumPropType),
  chartEmuData: PropTypes.arrayOf(emuDatumPropType),
  chartSets: PropTypes.arrayOf(labeledValuePropType),
  selectedExportedChartSet: PropTypes.string,
  selectedExportedChartsMeasure: PropTypes.string,
  selectedExportedChartsMaritalStatus: PropTypes.string,
  disableChartSetDownload: PropTypes.bool.isRequired,
  disableSelectedChartsDownload: PropTypes.bool.isRequired,
  onChangeResultsMeasure: PropTypes.func.isRequired,
  onChangeResultsIndicator: PropTypes.func.isRequired,
  onChangeResultsMaritalStatus: PropTypes.func.isRequired,
  onToggleExportedResultsMeasure: PropTypes.func.isRequired,
  onToggleExportedResultsIndicator: PropTypes.func.isRequired,
  onDownloadSelectedResults: PropTypes.func.isRequired,
  onDownloadAllResults: PropTypes.func.isRequired,
  onChangeChartsMeasure: PropTypes.func.isRequired,
  onChangeChartsMaritalStatus: PropTypes.func.isRequired,
  onToggleChart: PropTypes.func.isRequired,
  onToggleSurveyLegend: PropTypes.func.isRequired,
  onChangeExportedChartSet: PropTypes.func.isRequired,
  onChangeExportedChartsMeasure: PropTypes.func.isRequired,
  onChangeExportedChartsMaritalStatus: PropTypes.func.isRequired,
  onDownloadChartSet: PropTypes.func.isRequired,
  onDownloadSelectedCharts: PropTypes.func.isRequired,
};

CompareRunsMainContent.defaultProps = {
  tableResults: [],
  selectedChartsIndicators: [],
  showSurveyLegend: false,
  chartSurveyData: [],
  chartEmuData: [],
  chartSets: [],
  selectedExportedResultsMeasures: [],
  selectedExportedResultsIndicators: [],
};

export default withTranslation()(CompareRunsMainContent);
