import React from "react";
import * as PropTypes from "prop-types";
import nouislider from "nouislider";
import "nouislider/distribute/nouislider.css";

const NoUiSliderHook = (props) => {
  const inputEl = React.useRef(null);
  const slider = React.useRef(undefined);

  React.useEffect(() => {
    const _slider = nouislider.create(inputEl.current, {
      range: props.range,
      start: props.value,
      margin: props.margin,
      connect: props.connect,
      pips: props.pips,
      step: props.step,
      tooltips: props.tooltips,
      format: props.format,
      behaviour: props.behaviour,
    });

    _slider.on("change", () => onChange());

    slider.current = _slider;

    return () => {
      slider.current.destroy();
    };
  }, []);

  React.useEffect(() => {
    if (slider && slider.current && props.value) {
      slider.current.set(props.value);
    }
  }, [props.value]);

  const onChange = React.useCallback(() => {
    if (props.onChange) {
      const [lower, upper] = slider.current.get();

      props.onChange({
        lower,
        upper,
      });
    }
  }, [props.onChange, slider]);

  return <div ref={inputEl} />;
};

NoUiSliderHook.propTypes = {
  range: PropTypes.shape({
    min: PropTypes.oneOfType([PropTypes.number, PropTypes.arrayOf(PropTypes.number)]).isRequired,
    max: PropTypes.oneOfType([PropTypes.number, PropTypes.arrayOf(PropTypes.number)]).isRequired,
  }).isRequired,
  value: PropTypes.oneOfType([PropTypes.number, PropTypes.arrayOf(PropTypes.number)]).isRequired,
  step: PropTypes.number,
  margin: PropTypes.number,
  connect: PropTypes.oneOfType([PropTypes.bool, PropTypes.arrayOf(PropTypes.bool)]),
  pips: PropTypes.oneOfType([
    PropTypes.shape({
      mode: PropTypes.oneOf(["count"]),
      values: PropTypes.number.isRequired,
      density: PropTypes.number.isRequired,
    }),
  ]),
  format: PropTypes.object,
  tooltips: PropTypes.oneOfType([
    PropTypes.bool,
    PropTypes.object,
    PropTypes.arrayOf(PropTypes.oneOfType([PropTypes.bool, PropTypes.object])),
  ]),
  behaviour: PropTypes.string,
  onChange: PropTypes.func,
};

export default NoUiSliderHook;
