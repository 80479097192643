import React from "react";
import * as PropTypes from "prop-types";
import { css } from "@emotion/css";
import { labeledValuePropType } from "../../propTypes";
import Button from "@mui/material/Button";
import Select from "react-select";
import { withTranslation } from "react-i18next";

const classes = {
  actionButton: css`
    margin-top: 20px;
  `,
  country: css`
    margin-left: 20px;
  `,
  select: css`
    margin-top: 10px;
    width: 400px;
  `,
  selectorGroup: css`
    display: flex;
  `,
};

const PrepareDataCountrySelector = ({ t, ...rest }) => (
  <div>
    <div className={classes.selectorGroup}>
      <div>
        <label htmlFor="database">{t("Database:")}</label>
        <Select
          id="database"
          placeholder={t("Select database...")}
          options={rest.databases}
          value={rest.databases.filter(({ value }) => value === rest.selectedDatabaseId)}
          className={classes.select}
          onChange={({ value }) => rest.onSelectDatabase(value)}
        />
      </div>

      <div className={classes.country}>
        <label htmlFor="country">{t("Country:")}</label>
        <Select
          id="country"
          placeholder={t("Select country/population...")}
          options={rest.countries}
          value={rest.countries.filter(({ value }) => parseInt(value, 10) === rest.selectedCountryNumericCode)}
          className={classes.select}
          onChange={({ value }) => rest.onSelectCountry(parseInt(value, 10))}
        />
      </div>
    </div>

    <Button
      variant="contained"
      color="primary"
      disabled={!rest.selectedDatabaseId || !rest.selectedCountryNumericCode}
      className={classes.actionButton}
      onClick={rest.onLoadData}
    >
      {t("Load existing data")}
    </Button>
  </div>
);

PrepareDataCountrySelector.propTypes = {
  databases: PropTypes.arrayOf(labeledValuePropType),
  countries: PropTypes.arrayOf(labeledValuePropType),
  selectedDatabaseId: PropTypes.string,
  selectedCountryNumericCode: PropTypes.number,
  onSelectDatabase: PropTypes.func.isRequired,
  onSelectCountry: PropTypes.func.isRequired,
  onLoadData: PropTypes.func.isRequired,
};

PrepareDataCountrySelector.defaultProps = {
  databases: [],
  countries: [],
};

export default withTranslation()(PrepareDataCountrySelector);
