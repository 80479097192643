import React from "react";
import * as PropTypes from "prop-types";
import { css } from "@emotion/css";
import { withTranslation } from "react-i18next";

const classes = {
  comparisonRunName: css`
    background-color: plum;
    border-bottom: 3px dashed black;
    margin-left: 10px;
  `,
  container: css`
    display: flex;
  `,
  runName: css`
    background-color: lavender;
    border-bottom: 3px solid black;
    margin-left: 10px;
  `,
};

const ChartsComparisonLegend = ({ t, ...rest }) => (
  <div className={classes.container}>
    <div>{t("Legend:")}</div>
    <div className={classes.runName}>{rest.runName}</div>
    <div className={classes.comparisonRunName}>{rest.comparisonRunName}</div>
  </div>
);

ChartsComparisonLegend.propTypes = {
  runName: PropTypes.string,
  comparisonRunName: PropTypes.string,
};

export default withTranslation()(ChartsComparisonLegend);
