import React from "react";
import * as PropTypes from "prop-types";
import { css } from "@emotion/css";
import { periodPropType } from "../../propTypes";
import { maximumYear, minimumYear } from "../../constants";
import { getCurrentYear } from "../../utilities";
import NoUiSliderHook from "../NoUiSliderHook";
import wNumb from "wnumb";

const classes = {
  PeriodSelector: css`
    font-size: 80%;
    
    .noUi-marker-horizontal.noUi-marker-large {
      height: 10px;
    }
    
    .noUi-connect {
      background-color: #0d47a1;
    }
    
    .noUi-tooltip {
      padding: 2px;
    }
  }`,
};

const PeriodSelector = (props) => (
  <div className={classes.PeriodSelector}>
    <NoUiSliderHook
      range={{
        min: props.minimumYear,
        max: props.maximumYear,
      }}
      value={[props.period.firstYear, props.period.lastYear]}
      step={1}
      margin={1}
      connect={true}
      pips={{
        mode: "count",
        values: 10,
        density: 4,
      }}
      format={wNumb({ decimals: 0 })}
      tooltips={true}
      behaviour="drag"
      onChange={(value) =>
        props.onChange({
          firstYear: parseInt(value.lower, 10),
          lastYear: parseInt(value.upper, 10),
        })
      }
    />
  </div>
);

PeriodSelector.propTypes = {
  minimumYear: PropTypes.number,
  maximumYear: PropTypes.number,
  period: periodPropType,
  onChange: PropTypes.func.isRequired,
};

PeriodSelector.defaultProps = {
  minimumYear,
  maximumYear,
  period: {
    firstYear: maximumYear + 20,
    lastYear: getCurrentYear() + 1,
  },
};

export default PeriodSelector;
