import "core-js/fn/promise";
import React from "react";
import ReactDOM from "react-dom";
import * as Sentry from "@sentry/browser";
import LogRocket from "logrocket";
import "./index.css";
import { LicenseManager } from "ag-grid-enterprise";
import "./i18n";
import Root from "./Root";
import { ThemeProvider, StyledEngineProvider } from "@mui/material/styles";
import theme from "./theme";

const integrateLogRocketWithSentry = (sessionUrl) => {
  Sentry.configureScope((scope) => {
    scope.addEventProcessor(async (event) => {
      event.extra.sessionURL = sessionUrl;
      return event;
    });
  });
};

Sentry.init({
  dsn: process.env.REACT_APP_SENTRY_DSN,
});

LogRocket.init(process.env.REACT_APP_LOG_ROCKET_APP_ID);
LogRocket.getSessionURL(integrateLogRocketWithSentry);

// LicenseManager.setLicenseKey(
//   "Avenir_Health__MultiApp_1Devs8_November_2019__MTU3MzE3MTIwMDAwMA==5fe24a203ae4d849fe9768ee0a3219f5"
// );

LicenseManager.setLicenseKey(
  "CompanyName=Avenir Health, Inc.,LicensedGroup=Avenir Health,LicenseType=MultipleApplications,LicensedConcurrentDeveloperCount=4,LicensedProductionInstancesCount=4,AssetReference=AG-034875,SupportServicesEnd=10_February_2024_[v2]_MTcwNzUyMzIwMDAwMA==ae89b790138d1bfed9f913c2d60bce65"
);

ReactDOM.render(
  <StyledEngineProvider injectFirst>
    <ThemeProvider theme={theme}>
      <Root />
    </ThemeProvider>
  </StyledEngineProvider>,
  document.getElementById("root")
);
