import React from "react";
import Markdown from "react-markdown";
import { css } from "@emotion/css";
import { withErrorBoundary } from "../errors";
import { useTranslation } from "react-i18next";
import LastUpdated from "../components/LastUpdated";

const classes = {
  mainContent: css`
    height: 100%;
    padding: 0 20px 20px 20px;
    overflow: auto;
  `,
};

const About = () => {
  const { t } = useTranslation();

  return (
    <div className={classes.mainContent}>
      <Markdown>{t("ABOUT_INTRO")}</Markdown>
      <Markdown>{t("ABOUT_INSTRUCTIONS")}</Markdown>
      <Markdown>{t("ABOUT_ALKEMA")}</Markdown>
      <Markdown>{t("ABOUT_CAHILL")}</Markdown>
      <Markdown>{t("ABOUT_WHELDON")}</Markdown>
      <Markdown>{t("ABOUT_DATA")}</Markdown>
      <Markdown>{t("ABOUT_CREDITS")}</Markdown>
      <Markdown>{t("ABOUT_CITATION")}</Markdown>

      <p>
        <LastUpdated />
      </p>
    </div>
  );
};

export default withErrorBoundary(About);
