import React from "react";
import { css } from "@emotion/css";
import classNames from "classnames";
import Paper from "@mui/material/Paper";
import { withTranslation } from "react-i18next";

const classes = {
  container: css`
    display: inline-grid;
    grid-template-columns: 1fr;
    grid-auto-rows: max-content;
    grid-row-gap: 8px;
    padding: 10px;
  `,
  section: css`
    display: grid;
    grid-template-columns: 24px 1fr;
    grid-auto-rows: 22px;
    align-items: center;
  `,
  legend: css`
    font-weight: bold;
  `,
  subpopulation: css`
    grid-auto-rows: max-content;
  `,
};

const createCircle = (strokeColor, fillColor = "none", radius = 6) => (
  <svg height="16" width="16" style={{ display: "block" }}>
    <circle cx={8} cy={8} r={radius} stroke={strokeColor} strokeWidth={2} fill={fillColor} />
  </svg>
);

const createTriangle = (strokeColor, inverted = false) => (
  <svg height="16" width="16" style={{ display: "block" }}>
    <g transform={`translate(0, ${!inverted ? 0 : 16}), scale(1, ${!inverted ? 1 : -1})`}>
      <polygon points="8,2 14,14 2,14" stroke={strokeColor} strokeWidth={2} fill="none" />
    </g>
  </svg>
);

const createSquare = (strokeColor) => (
  <svg height="16" width="16" style={{ display: "block" }}>
    <rect x={2} y={2} width={12} height={12} stroke={strokeColor} strokeWidth={2} fill="none" />
  </svg>
);

const ChartsSurveyLegend = ({ t }) => (
  <Paper className={classes.container}>
    <div className={classNames(classes.section, classes.legend)}>{t("Legend")}</div>

    <div className={classes.section}>
      <div>{createCircle("#e41a1c")}</div>
      <div>{t("DHS")}</div>
      <div>{createCircle("#377eb8")}</div>
      <div>{t("MICS")}</div>
      <div>{createCircle("#ff7f00")}</div>
      <div>{t("National survey")}</div>
      <div>{createCircle("#984ea3")}</div>
      <div>{t("PMA")}</div>
      <div>{createCircle("#4daf4a")}</div>
      <div>{t("Other international survey")}</div>
      <div>{createCircle("none", "black", 4)}</div>
      <div>{t("Service statistics")}</div>
    </div>

    <div className={classNames(classes.section, classes.subpopulation)}>
      <div>{createCircle("#f781bf", "#f781bf")}</div>
      <div>{t("Subpopulation")}</div>
      <div />
      <div className={classes.section}>
        <div>+</div>
        <div>{t("Higher contraceptive use")}</div>
        <div>-</div>
        <div>{t("Lower contraceptive use")}</div>
        <div>A</div>
        <div>{t("Other age group")}</div>
        <div>F</div>
        <div>{t("Folk methods included")}</div>
        <div>S+</div>
        <div>{t("Sterilization included")}</div>
        <div>S-</div>
        <div>{t("Sterilization excluded")}</div>
      </div>
    </div>

    <div className={classes.section}>
      <div>{createCircle("#999")}</div>
      <div>{t("Married women")}</div>
      <div>{createTriangle("#999")}</div>
      <div>{t("Sexually active women")}</div>
      <div>{createTriangle("#999", true)}</div>
      <div>{t("Ever married/All women")}</div>
      <div>{createSquare("#999")}</div>
      <div>{t("Both sexes and husbands/wives")}</div>
    </div>
  </Paper>
);

export default withTranslation()(ChartsSurveyLegend);
