import * as api from "./api/api";
import { developmentMode } from "./constants";

const authentication = {
  getUsername() {
    return developmentMode ? "guest" : api.getUsername();
  },
  login(username, password, callback, cancellationToken) {
    api
      .login(username, password)
      .then(() => {
        this.username = username;

        callback && callback();
      })
      .catch((error) => {
        this.username = undefined;

        callback && callback(error);
      });
  },
  logout(callback, cancellationToken) {
    api
      .logout(this.username)
      .then(() => {
        this.username = undefined;

        callback && callback();
      })
      .catch((error) => {
        callback && callback(error);
      });
  },
};

export default authentication;
