import React from "react";
import * as PropTypes from "prop-types";
import { css } from "@emotion/css";
import { databasePropType, databaseTypePropType } from "../../propTypes";
import FormControl from "@mui/material/FormControl";
import FormControlLabel from "@mui/material/FormControlLabel";
import InputLabel from "@mui/material/InputLabel";
import Radio from "@mui/material/Radio";
import RadioGroup from "@mui/material/RadioGroup";
import Select from "react-select";
import { withTranslation } from "react-i18next";
import DatabaseUploader from "./StartRunDatabaseUploader";

const classes = {
  dataTypeSpecificComponents: css`
    margin: 10px 0;
  `,
  inputLabel: css`
    font-size: 75%;
  `,
  radio: css`
    padding: 2px 12px;
  `,
  select: css`
    margin-top: 10px;
  `,
};

const StartRunDatabasePicker = (props) => {
  const { t } = props;

  const getDatabaseTypeSpecificComponents = () => {
    switch (props.databaseType) {
      case "default":
        const defaultDatabases = props.defaultDatabases.map(({ id, name }) => ({
          value: id,
          label: name,
        }));

        return (
          <div>
            <InputLabel className={classes.inputLabel}>{t("Choose default database")}</InputLabel>

            <Select
              placeholder={t("Select database...")}
              options={defaultDatabases}
              value={defaultDatabases.filter(({ value }) => value === props.selectedDatabaseId)}
              noOptionsMessage={() => t("No default databases available")}
              className={classes.select}
              onChange={({ value }) => props.onSelectDatabase(value)}
            />
          </div>
        );

      case "custom":
        const customDatabases = props.customDatabases.map(({ id, name }) => ({
          value: id,
          label: name,
        }));

        return (
          <div>
            <InputLabel className={classes.inputLabel}>Choose custom database</InputLabel>

            <Select
              placeholder={t("Select database...")}
              options={customDatabases}
              value={customDatabases.filter(({ value }) => value === props.selectedDatabaseId)}
              noOptionsMessage={() => t("No custom databases available")}
              className={classes.select}
              onChange={({ value }) => props.onSelectDatabase(value)}
            />
          </div>
        );

      case "external":
        return (
          <DatabaseUploader
            databaseName={props.uploadedDatabaseName}
            uploadingDatabase={props.uploadingDatabase}
            databaseUploadProgress={props.databaseUploadProgress}
            onSelectDatabase={props.onSelectDatabaseFile}
            onUpload={props.onUploadDatabase}
            onCancelUpload={props.onCancelDatabaseUpload}
          />
        );

      default:
        console.error(`${props.databaseType} is an unknown database type`);

        break;
    }
  };

  return (
    <div>
      <FormControl component="fieldset">
        <RadioGroup
          aria-label="Database type"
          value={props.databaseType}
          onChange={(_, value) => props.onChangeDatabaseType(value)}
        >
          {props.variant !== "emu" && (
            <FormControlLabel value="default" control={<Radio className={classes.radio} />} label={t("Default")} />
          )}
          <FormControlLabel value="custom" control={<Radio className={classes.radio} />} label={t("Custom")} />
          <FormControlLabel value="external" control={<Radio className={classes.radio} />} label={t("External")} />
        </RadioGroup>
      </FormControl>

      <div className={classes.dataTypeSpecificComponents}>{getDatabaseTypeSpecificComponents()}</div>
    </div>
  );
};

StartRunDatabasePicker.propTypes = {
  variant: PropTypes.oneOf(["default", "emu"]),
  databaseType: databaseTypePropType,
  defaultDatabases: PropTypes.arrayOf(databasePropType),
  customDatabases: PropTypes.arrayOf(databasePropType).isRequired,
  selectedDatabaseId: PropTypes.string,
  uploadedDatabaseName: PropTypes.string,
  uploadingDatabase: PropTypes.bool,
  databaseUploadProgress: PropTypes.number,
  onChangeDatabaseType: PropTypes.func.isRequired,
  onSelectDatabase: PropTypes.func.isRequired,
  onSelectDatabaseFile: PropTypes.func.isRequired,
  onUploadDatabase: PropTypes.func.isRequired,
  onCancelDatabaseUpload: PropTypes.func.isRequired,
};

StartRunDatabasePicker.defaultProps = {
  variant: "default",
  databaseType: "default",
  defaultDatabases: [],
  uploadingDatabase: false,
};

export default withTranslation()(StartRunDatabasePicker);
