import React from "react";
import * as PropTypes from "prop-types";
import IconButton from "@mui/material/IconButton";
import Snackbar from "@mui/material/Snackbar";
import SnackbarContent from "@mui/material/SnackbarContent";
import CheckCircleIcon from "@mui/icons-material/CheckCircle";
import CloseIcon from "@mui/icons-material/Close";
import ErrorIcon from "@mui/icons-material/Error";
import InfoIcon from "@mui/icons-material/Info";
import WarningIcon from "@mui/icons-material/Warning";
import amber from "@mui/material/colors/amber";
import green from "@mui/material/colors/green";

const variantIcon = {
  success: CheckCircleIcon,
  warning: WarningIcon,
  error: ErrorIcon,
  information: InfoIcon,
};

const styles2 = {
  icon: {
    fontSize: 20,
  },
  iconVariant: (theme) => ({
    opacity: 0.9,
    marginRight: theme.spacing(1),
  }),
  margin: (theme) => ({
    margin: theme.spacing(1),
  }),
  success: {
    backgroundColor: green[600],
  },
  warning: {
    backgroundColor: amber[700],
  },
  error: (theme) => ({
    backgroundColor: theme.palette.error.dark,
  }),
  information: (theme) => ({
    backgroundColor: theme.palette.primary.dark,
  }),
  message: {
    display: "flex",
    alignItems: "center",
  },
};

const Notification = (props) => {
  const Icon = variantIcon[props.variant];

  return (
    <Snackbar
      anchorOrigin={{
        vertical: "bottom",
        horizontal: "left",
      }}
      open={props.open}
      autoHideDuration={props.autoHideDurationMilliseconds}
      //   className={props.classes.container}
      onClose={props.onClose}
    >
      <SnackbarContent
        message={
          <span id="client-snackbar" style={styles2.message}>
            <Icon sx={[styles2.icon, styles2.iconVariant]} />
            {props.message}
          </span>
        }
        action={[
          <IconButton key="close" color="inherit" aria-label="Close" onClick={props.onClose} size="large">
            <CloseIcon />
          </IconButton>,
        ]}
        sx={[styles2.margin, styles2[props.variant]]}
        aria-describedby="client-snackbar"
      />
    </Snackbar>
  );
};

Notification.propTypes = {
  variant: PropTypes.oneOf(["success", "information", "warning", "error"]),
  open: PropTypes.bool,
  message: PropTypes.string,
  autoHideDurationMilliseconds: PropTypes.number,
  onClose: PropTypes.func.isRequired,
};

Notification.defaultProps = {
  variant: "information",
  open: false,
  message: "",
  autoHideDurationMilliseconds: 6000,
};

export default Notification;
