import React from "react";
import ErrorIcon from "@mui/icons-material/Error";
import { withTranslation } from "react-i18next";

const styles = {
  message: {
    display: "flex",
    alignItems: "center",
  },
  icon: {
    width: "1.5em",
    height: "1.5em",
    color: "error.dark",
  },
  text: {
    marginLeft: 10,
  },
};

const Oops = ({ t }) => (
  <div>
    <h1>{t("Oops!")}</h1>

    <div style={styles.message}>
      <ErrorIcon sx={styles.icon} />

      <div style={styles.text}>
        <div
          dangerouslySetInnerHTML={{
            __html: t("We're sorry&mdash;something's gone wrong."),
          }}
        />
        {/* <div>{t("Our team has been notified.")}</div> */}
      </div>
    </div>
  </div>
);

export default withTranslation()(Oops);
