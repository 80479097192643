import React from "react";
import * as PropTypes from "prop-types";
import { indicatorResultPropType, measurePropType } from "../../propTypes";
import { formatLogicalCell, formatNumericCell, formatPercentageCell } from "../../utilities";
import classNames from "classnames";
import { AgGridReact } from "ag-grid-react";
import "ag-grid-enterprise";
import "ag-grid-community/styles/ag-grid.css";
import "ag-grid-community/styles/ag-theme-material.css";
import { withTranslation } from "react-i18next";
import { translateGridText } from "../../i18n";

const getFormatter = (measure) => {
  return measure === "percentage"
    ? (value) => formatPercentageCell(value, 1)
    : (value) => formatNumericCell(value / 1000, 0);
};

const getColumnDefinitionsForSingleRun = (estimateFormatter) => [
  {
    headerName: "In union?",
    field: "isInUnion",
    valueFormatter: (params) => formatLogicalCell(params.value),
  },
  {
    headerName: "Year",
    field: "year",
    type: "numericColumn",
    filter: "agNumberColumnFilter",
  },
  {
    headerName: "2.5%",
    field: "percentile2pt5",
    type: "numericColumn",
    filter: "agNumberColumnFilter",
    valueFormatter: (params) => estimateFormatter(params.value),
  },
  {
    headerName: "10%",
    field: "percentile10",
    type: "numericColumn",
    filter: "agNumberColumnFilter",
    valueFormatter: (params) => estimateFormatter(params.value),
  },
  {
    headerName: "Median",
    field: "median",
    type: "numericColumn",
    filter: "agNumberColumnFilter",
    valueFormatter: (params) => estimateFormatter(params.value),
  },
  {
    headerName: "90%",
    field: "percentile90",
    type: "numericColumn",
    filter: "agNumberColumnFilter",
    valueFormatter: (params) => estimateFormatter(params.value),
  },
  {
    headerName: "97.5%",
    field: "percentile97pt5",
    type: "numericColumn",
    filter: "agNumberColumnFilter",
    valueFormatter: (params) => estimateFormatter(params.value),
  },
];

const getColumnDefinitionsRunComparison = (estimateFormatter) => [
  {
    headerName: "In union?",
    field: "isInUnion",
    valueFormatter: (params) => formatLogicalCell(params.value),
  },
  {
    headerName: "Year",
    field: "year",
    type: "numericColumn",
    filter: "agNumberColumnFilter",
  },
  {
    headerName: "Mean #1",
    field: "mean",
    type: "numericColumn",
    filter: "agNumberColumnFilter",
    valueFormatter: (params) => estimateFormatter(params.value),
  },
  {
    headerName: "Mean #2",
    field: "comparisonMean",
    type: "numericColumn",
    filter: "agNumberColumnFilter",
    valueFormatter: (params) => estimateFormatter(params.value),
  },
  {
    headerName: "Median #1",
    field: "median",
    type: "numericColumn",
    filter: "agNumberColumnFilter",
    valueFormatter: (params) => estimateFormatter(params.value),
  },
  {
    headerName: "Median #2",
    field: "comparisonMedian",
    type: "numericColumn",
    filter: "agNumberColumnFilter",
    valueFormatter: (params) => estimateFormatter(params.value),
  },
];

const ResultsDataTable = ({ t, ...rest }) => {
  const colDefChoice = rest.comparison
    ? getColumnDefinitionsRunComparison(getFormatter(rest.measure))
    : getColumnDefinitionsForSingleRun(getFormatter(rest.measure));

  const colDefs = colDefChoice.map((datum) => ({
    ...datum,
    headerName: t(datum.headerName),
  }));

  const rowData = rest.results.map((datum) => ({
    ...datum,
    year: datum.year + 0.5,
  }));

  return (
    <div key={rest.measure} className={classNames("ag-theme-material", rest.className)}>
      <AgGridReact
        domLayout="autoHeight"
        defaultColDef={{ sortable: true, filter: true }}
        pagination={true}
        paginationPageSize={10}
        suppressColumnVirtualisation={true}
        suppressCellFocus={true}
        overlayNoRowsTemplate="<span />"
        getLocaleText={translateGridText}
        rowData={rowData}
        columnDefs={colDefs}
        onGridReady={(params) => {
          params.columnApi.autoSizeAllColumns();
        }}
      />
    </div>
  );
};

ResultsDataTable.propTypes = {
  measure: measurePropType.isRequired,
  results: PropTypes.arrayOf(indicatorResultPropType),
  comparison: PropTypes.bool,
  className: PropTypes.string,
};

ResultsDataTable.defaultProps = {
  results: [],
  comparison: false,
};

export default withTranslation()(ResultsDataTable);
