import React from "react";
import * as PropTypes from "prop-types";
import { css } from "@emotion/css";
import { managedLabeledValuePropType } from "../propTypes";
import { Route, Switch, Redirect } from "react-router-dom";
import { withTranslation } from "react-i18next";
import Databases from "../components/ManageData/ManageDataDatabases";
import Loading from "../components/Loading";
import NavigationTab from "../components/NavigationTab";
import Runs from "../components/ManageData/ManageDataRuns";

const classes = {
  mainContent: css`
    height: 100%;
    padding: 20px;
    overflow: auto;
  `,
  tabPage: css`
    margin: 10px;
  `,
};

const styles = {
  note: {
    marginTop: 0,
  },
};

const ManageData = ({ match, t, ...rest }) => (
  <div className={classes.mainContent}>
    <Loading loading={rest.loadingData} message={t("Loading databases and runs...")}>
      <p style={styles.note}>{t("Cleaning up data you no longer need allows you to focus on new analyses.")}</p>

      <NavigationTab to="/managedata/databases">{t("Databases")}</NavigationTab>
      <NavigationTab to="/managedata/runs">{t("Runs")}</NavigationTab>

      <div className={classes.tabPage}>
        <Switch>
          <Route exact path={match.path} render={() => <Redirect replace to={`${match.path}/databases`} />} />
          <Route
            path={`${match.path}/databases`}
            render={() => (
              <Databases
                databases={rest.databases}
                selectedDatabaseIds={rest.selectedDatabaseIds}
                onToggleDatabase={rest.onToggleDatabase}
                onDownloadDatabases={rest.onDownloadDatabases}
                onDeleteDatabases={rest.onDeleteDatabases}
              />
            )}
          />
          <Route
            path={`${match.path}/runs`}
            render={() => (
              <Runs
                runs={rest.runs}
                selectedRunIds={rest.selectedRunIds}
                onToggleRun={rest.onToggleRun}
                onDeleteRuns={rest.onDeleteRuns}
              />
            )}
          />
        </Switch>
      </div>
    </Loading>
  </div>
);

ManageData.propTypes = {
  loadingData: PropTypes.bool,
  databases: PropTypes.arrayOf(managedLabeledValuePropType),
  runs: PropTypes.arrayOf(managedLabeledValuePropType),
  selectedDatabaseIds: PropTypes.arrayOf(PropTypes.string).isRequired,
  selectedRunIds: PropTypes.arrayOf(PropTypes.string).isRequired,
  onToggleDatabase: PropTypes.func.isRequired,
  onDeleteDatabases: PropTypes.func.isRequired,
  onDownloadDatabases: PropTypes.func.isRequired,
  onToggleRun: PropTypes.func.isRequired,
  onDeleteRuns: PropTypes.func.isRequired,
};

ManageData.defaultProps = {
  loadingData: false,
  databases: [],
  runs: [],
};

export default withTranslation()(ManageData);
