import React from "react";
import * as PropTypes from "prop-types";
import { Route, Switch, withRouter } from "react-router-dom";
import AppBarCustom from "./AppBarCustom";
import About from "../pages/About";
import CompareRunsContainer from "../containers/CompareRunsContainer";
import ManageDataContainer from "../containers/ManageDataContainer";
import PrepareDataContainer from "../containers/PrepareDataContainer";
import RunHistoryContainer from "../containers/RunHistoryContainer";
import StartRunContainer from "../containers/StartRunContainer";
import ViewRunContainer from "../containers/ViewRunContainer";

const styles = {
  content: {
    // margin: 20,
    height: "calc(100% - 48px)",
  },
};

const App = (props) => {
  const { onChangeLanguage, onLogout } = props;

  return (
    <>
      <AppBarCustom onChangeLanguage={onChangeLanguage} onLogout={onLogout} />

      <div style={styles.content}>
        <Switch>
          <Route exact path="/" component={About} />
          <Route path="/startrun" component={StartRunContainer} />
          <Route path="/viewrun" component={ViewRunContainer} />
          <Route path="/compareruns" component={CompareRunsContainer} />
          <Route path="/history" component={RunHistoryContainer} />
          <Route path="/preparedata" component={PrepareDataContainer} />
          <Route path="/managedata" component={ManageDataContainer} />
        </Switch>
      </div>
    </>
  );
};

App.propTypes = {
  onChangeLanguage: PropTypes.func.isRequired,
  onLogout: PropTypes.func.isRequired,
};

export default withRouter(App);
