import React from "react";
import { Route, HashRouter as Router, Switch } from "react-router-dom";
import CssBaseline from "@mui/material/CssBaseline";
import { withErrorBoundary } from "./errors";
import { withTranslation } from "react-i18next";
import AppContainer from "./containers/AppContainer";
import LoginContainer from "./containers/LoginContainer";
import PrivateRoute from "./components/PrivateRoute";

const Root = (props) => {
  React.useEffect(() => {
    document.title = props.t("APP_TITLE");
  }, []);

  return (
    <Router>
      <CssBaseline />
      <Switch>
        <Route path="/login" component={LoginContainer} />
        <PrivateRoute path="/" component={AppContainer} />
      </Switch>
    </Router>
  );
};

export default withErrorBoundary(withTranslation()(Root));
