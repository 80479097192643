import React from "react";
import * as PropTypes from "prop-types";
import { css } from "@emotion/css";
import { labeledValuePropType, maritalStatusPropType, measurePropType } from "../../propTypes";
import Button from "@mui/material/Button";
import Typography from "@mui/material/Typography";
import { withTranslation } from "react-i18next";
import ChartSetSelector from "./ChartSetSelector";
import MaritalStatusSelector from "./ChartsMaritalStatusSelector";
import MeasureSelector from "../MeasureSelector";

const classes = {
  button: css`
    margin-right: 20px;
  `,
  buttonGroup: css`
    margin-top: 20px;
    padding-bottom: 20px;
  `,
  leftSelector: css`
    margin-right: 30px;
  `,
  selectors: css`
    display: flex;
  `,
  title: css`
    margin-bottom: 10px;
  `,
};

const ChartsExporter = ({ t, ...rest }) => (
  <div>
    <Typography variant="h5" className={classes.title}>
      {t("Export chart sets")}
    </Typography>

    <div className={classes.selectors}>
      <ChartSetSelector
        chartSets={rest.chartSets}
        value={rest.selectedChartSet}
        className={classes.leftSelector}
        onChange={rest.onChangeChartSet}
      />
      <MeasureSelector value={rest.selectedMeasure} className={classes.leftSelector} onChange={rest.onChangeMeasure} />
      <MaritalStatusSelector value={rest.selectedMaritalStatus} onChange={rest.onChangeMaritalStatus} />
    </div>

    <div className={classes.buttonGroup}>
      <Button
        variant="contained"
        color="primary"
        disabled={rest.disableChartSetDownload}
        className={classes.button}
        onClick={rest.onDownloadChartSet}
      >
        {t("Download chart set")}
      </Button>
      <Button
        variant="contained"
        color="primary"
        disabled={rest.disableSelectedChartsDownload}
        className={classes.button}
        onClick={rest.onDownloadSelectedCharts}
      >
        {t("Download displayed charts")}
      </Button>
    </div>
  </div>
);

ChartsExporter.propTypes = {
  chartSets: PropTypes.arrayOf(labeledValuePropType),
  selectedChartSet: PropTypes.string.isRequired,
  selectedMeasure: measurePropType.isRequired,
  selectedMaritalStatus: maritalStatusPropType,
  disableChartSetDownload: PropTypes.bool.isRequired,
  disableSelectedChartsDownload: PropTypes.bool.isRequired,
  onChangeChartSet: PropTypes.func.isRequired,
  onChangeMeasure: PropTypes.func.isRequired,
  onChangeMaritalStatus: PropTypes.func.isRequired,
  onDownloadChartSet: PropTypes.func.isRequired,
  onDownloadSelectedCharts: PropTypes.func.isRequired,
};

export default withTranslation()(ChartsExporter);
