import React from "react";
import { NavTab } from "react-router-tabs";
import "react-router-tabs/styles/react-router-tabs.css";
import classNames from "classnames";
import { css } from "@emotion/css";

const classes = {
  active: css`
    font-weight: 600;
  `,

  container: css`
    color: #555;
  `,
};

const NavigationTab = (props) => (
  <NavTab
    {...props}
    className={classNames("nav-tab", classes.container)}
    activeClassName={classNames("active", classes.active)}
  />
);

export default NavigationTab;
