import React from "react";
import * as PropTypes from "prop-types";
import { contraceptiveMethods, indicators } from "../constants";
import { trimYearToRange } from "../utilities";
import * as api from "../api/api";
import Progress from "../components/Progress/Progress";

const createInitialState = () => {
  return {
    indicatorProgressParameters: {
      indicator: indicators[0].value,
      maritalStatus: "married",
    },
    contraceptiveMethodProgressParameters: {
      maritalStatus: "married",
      contraceptiveMethod: contraceptiveMethods[0].value,
    },
  };
};

const validatePeriod = (period) => period && period.firstYear && period.lastYear && period.firstYear <= period.lastYear;

const createPayload = (state) => {
  const payload = {};

  if (validatePeriod(state.indicatorProgressParameters.period)) {
    payload.indicatorProgressParameters = state.indicatorProgressParameters;
  }

  if (validatePeriod(state.contraceptiveMethodProgressParameters.period)) {
    payload.contraceptiveMethodProgressParameters = state.contraceptiveMethodProgressParameters;
  }

  return payload;
};

const ProgressContainer = (props) => {
  const [state, setState] = React.useState(createInitialState());

  const signal = api.getSignal();

  const setStateSafely = (newState) => {
    setState((prevState) => ({
      ...prevState,
      ...newState,
    }));
  };

  React.useEffect(() => {
    return () => {
      signal.cancel();
    };
  }, []);

  const onSelectIndicatorProgressIndicator = (indicator) => {
    setStateSafely({
      indicatorProgressParameters: {
        ...state.indicatorProgressParameters,
        indicator,
      },
    });
  };

  const onSelectIndicatorProgressMaritalStatus = (maritalStatus) => {
    setStateSafely({
      indicatorProgressParameters: {
        ...state.indicatorProgressParameters,
        maritalStatus,
      },
    });
  };

  const onChangeIndicatorProgressFirstYear = (firstYear, enforceRange) => {
    firstYear = trimYearToRange(firstYear, enforceRange);

    setStateSafely({
      indicatorProgressParameters: {
        ...state.indicatorProgressParameters,
        period: {
          ...state.indicatorProgressParameters.period,
          firstYear,
        },
      },
    });
  };

  const onChangeIndicatorProgressLastYear = (lastYear, enforceRange) => {
    lastYear = trimYearToRange(lastYear, enforceRange);

    setStateSafely({
      indicatorProgressParameters: {
        ...state.indicatorProgressParameters,
        period: {
          ...state.indicatorProgressParameters.period,
          lastYear,
        },
      },
    });
  };

  const onSelectContraceptiveMethodProgressMaritalStatus = (maritalStatus) => {
    setStateSafely({
      contraceptiveMethodProgressParameters: {
        ...state.contraceptiveMethodProgressParameters,
        maritalStatus,
      },
    });
  };

  const onSelectContraceptiveMethodProgressContraceptiveMethod = (contraceptiveMethod) => {
    setStateSafely({
      contraceptiveMethodProgressParameters: {
        ...state.contraceptiveMethodProgressParameters,
        contraceptiveMethod,
      },
    });
  };

  const onChangeContraceptiveMethodProgressFirstYear = (firstYear, enforceRange) => {
    firstYear = trimYearToRange(firstYear, enforceRange);

    setStateSafely({
      contraceptiveMethodProgressParameters: {
        ...state.contraceptiveMethodProgressParameters,
        period: {
          ...state.contraceptiveMethodProgressParameters.period,
          firstYear,
        },
      },
    });
  };

  const onChangeContraceptiveMethodProgressLastYear = (lastYear, enforceRange) => {
    lastYear = trimYearToRange(lastYear, enforceRange);

    setStateSafely({
      contraceptiveMethodProgressParameters: {
        ...state.contraceptiveMethodProgressParameters,
        period: {
          ...state.contraceptiveMethodProgressParameters.period,
          lastYear,
        },
      },
    });
  };

  const onCalculate = () => {
    setStateSafely({
      calculating: true,
    });

    const countryNumericCodeOrRegion = props.countryNumericCode || props.region;

    api
      .calculateProgress(props.runId, countryNumericCodeOrRegion, createPayload(state), signal.token)
      .then((response) => {
        if (!response) {
          return;
        }
        setStateSafely({
          ...response.data,
        });
      })
      .finally(() => {
        setStateSafely({
          calculating: false,
        });
      });
  };

  return (
    <Progress
      indicators={indicators}
      contraceptiveMethods={contraceptiveMethods}
      indicatorProgressParameters={state.indicatorProgressParameters}
      indicatorProgress={state.indicatorProgress}
      contraceptiveMethodProgressParameters={state.contraceptiveMethodProgressParameters}
      contraceptiveMethodProgress={state.contraceptiveMethodProgress}
      valid={(!!props.countryNumericCode || !!props.region) && Object.keys(createPayload(state)).length > 0}
      calculating={state.calculating}
      onSelectIndicatorProgressIndicator={onSelectIndicatorProgressIndicator}
      onSelectIndicatorProgressMaritalStatus={onSelectIndicatorProgressMaritalStatus}
      onChangeIndicatorProgressFirstYear={onChangeIndicatorProgressFirstYear}
      onChangeIndicatorProgressLastYear={onChangeIndicatorProgressLastYear}
      onSelectContraceptiveMethodProgressMaritalStatus={onSelectContraceptiveMethodProgressMaritalStatus}
      onSelectContraceptiveMethodProgressContraceptiveMethod={onSelectContraceptiveMethodProgressContraceptiveMethod}
      onChangeContraceptiveMethodProgressFirstYear={onChangeContraceptiveMethodProgressFirstYear}
      onChangeContraceptiveMethodProgressLastYear={onChangeContraceptiveMethodProgressLastYear}
      onCalculate={onCalculate}
    />
  );
};

ProgressContainer.propTypes = {
  runId: PropTypes.string,
  countryNumericCode: PropTypes.number,
  region: PropTypes.string,
};

export default ProgressContainer;
