import React from "react";
import * as PropTypes from "prop-types";
import { css } from "@emotion/css";
import { formatDateTime } from "../utilities";
import IconButton from "@mui/material/IconButton";
import Table from "@mui/material/Table";
import TableBody from "@mui/material/TableBody";
import TableCell from "@mui/material/TableCell";
import TableHead from "@mui/material/TableHead";
import TableRow from "@mui/material/TableRow";
import Tooltip from "@mui/material/Tooltip";
import HourglassFullIcon from "@mui/icons-material/HourglassFull";
import HourglassEmptyIcon from "@mui/icons-material/HourglassEmpty";
import InfoIcon from "@mui/icons-material/Info";
import { withTranslation } from "react-i18next";
import Loading from "../components/Loading";
import Notification from "../components/Notification";
import { Link } from "@mui/material";
// NOTE: Do not use import { Link } from "react-router-dom";

const classes = {
  mainContent: css`
    height: 100%;
    padding: 20px;
    overflow: auto;
  `,
};

const styles = {
  button: {
    marginTop: "20px",
  },
  failed: {
    color: "red",
    marginTop: "6px",
  },
  note: {
    marginTop: 0,
    marginBottom: 0,
  },
  succeeded: {
    color: "green",
    marginTop: "6px",
  },
  table: {
    width: "initial",
    marginTop: "16px",
    "& .MuiTableCell-root": {
      padding: "0 16px",
    },
  },
};

const RunHistory = ({ t, ...rest }) => (
  <div className={classes.mainContent}>
    <Loading loading={rest.loadingData} message={t("Loading runs...")}>
      <p style={styles.note}>{t("The run history will refresh automatically.")}</p>

      <Table sx={styles.table}>
        <TableHead>
          <TableRow>
            <TableCell>{t("Run name")}</TableCell>
            <TableCell>{t("Started at")}</TableCell>
            <TableCell />
            <TableCell />
          </TableRow>
        </TableHead>

        <TableBody>
          {rest.runs
            .sort((a, b) => b.startedAt - a.startedAt)
            .map((run) => (
              <TableRow key={run.name}>
                <TableCell>
                  {run.completed && !run.failed ? (
                    <Tooltip title={t("Load run")}>
                      <Link href={`./#/viewrun/survey?run=${run.id}`}>{run.name}</Link>
                    </Tooltip>
                  ) : (
                    <span>{run.name}</span>
                  )}
                </TableCell>
                <TableCell>{formatDateTime(run.startedAt)}</TableCell>
                <TableCell>
                  <Tooltip
                    title={
                      run.completed ? (!run.failed ? t("Run has completed") : t("Run failed")) : t("Run is in progress")
                    }
                  >
                    {run.completed ? (
                      <HourglassFullIcon sx={!run.failed ? styles.succeeded : styles.failed} />
                    ) : (
                      <HourglassEmptyIcon />
                    )}
                  </Tooltip>
                </TableCell>
                <TableCell>
                  <IconButton
                    onClick={() => {
                      rest.onDownloadRunLog(run.id);
                    }}
                    size="small"
                  >
                    <Tooltip title={t("Log")}>
                      <InfoIcon />
                    </Tooltip>
                  </IconButton>
                </TableCell>
              </TableRow>
            ))}
        </TableBody>
      </Table>

      <Notification
        variant={rest.notificationVariant}
        open={rest.notificationOpen}
        message={rest.notificationMessage}
        onClose={rest.onCloseNotification}
      />
    </Loading>
  </div>
);

RunHistory.propTypes = {
  loadingData: PropTypes.bool,
  runs: PropTypes.arrayOf(
    PropTypes.shape({
      id: PropTypes.string.isRequired,
      name: PropTypes.string.isRequired,
      startedAt: PropTypes.instanceOf(Date).isRequired,
      completed: PropTypes.bool.isRequired,
    })
  ),
  notificationVariant: PropTypes.string,
  notificationOpen: PropTypes.bool,
  notificationMessage: PropTypes.string,
  onDownloadRunLog: PropTypes.func.isRequired,
  onCloseNotification: PropTypes.func.isRequired,
};

RunHistory.defaultProps = {
  loadingData: false,
  runs: [],
  notificationOpen: false,
};

export default withTranslation()(RunHistory);
