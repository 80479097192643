import React from "react";
import { toggleSetExistence } from "../utilities";
import { normalizeDatabases, normalizeRuns } from "../data";
import * as api from "../api/api";
import fileDownload from "js-file-download";
import { withErrorBoundary } from "../errors";
import ManageData from "../pages/ManageData";

const createInitialState = () => ({
  selectedDatabaseIds: new Set(),
  selectedRunIds: new Set(),
});

const ManageDataContainer = (props) => {
  const [state, setState] = React.useState(createInitialState());

  const signal = api.getSignal();

  const setStateSafely = (newState) => {
    setState((prevState) => ({
      ...prevState,
      ...newState,
    }));
  };

  React.useEffect(() => {
    setStateSafely({
      loadingData: true,
    });

    // noinspection JSCheckFunctionSignatures
    Promise.all([api.fetchDatabases(signal.token), api.fetchRuns(true, true, signal.token)])
      .then((response) => {
        if (!response || response.some((item) => !item)) {
          return;
        }

        const [databases, runs] = response;

        setStateSafely({
          databases: normalizeDatabasesLocal(databases.data),
          runs: Object.entries(normalizeRuns(runs.data))
            .filter((run) => !run.isGlobal)
            .map(([id, run]) => ({
              value: id,
              label: run.name,
              isGlobal: run.isGlobal,
              createdAt: run.startedAt,
              completed: run.completed,
              failed: run.failed,
            })),
        });
      })
      .finally(() => {
        setStateSafely({
          loadingData: false,
        });
      });

    return () => {
      signal.cancel();
    };
  }, []);

  const normalizeDatabasesLocal = (databases) =>
    Object.entries(normalizeDatabases(databases))
      .filter((database) => !database.isGlobal)
      .map(([id, database]) => ({
        value: id,
        label: `${database.name} (${database.type})`,
        isGlobal: database.isGlobal,
        isInUse: database.isInUse,
      }));

  const onToggleDatabase = (databaseId) => {
    // noinspection JSCheckFunctionSignatures
    const selectedDatabaseIds = new Set(state.selectedDatabaseIds);

    toggleSetExistence(selectedDatabaseIds, databaseId);

    setStateSafely({
      selectedDatabaseIds,
    });
  };

  const onDownloadDatabases = () => {
    const selectedDatabaseIds = Array.from(state.selectedDatabaseIds);

    api.downloadDatabases(selectedDatabaseIds, signal.token).then((response) => {
      if (!response) {
        return;
      }

      fileDownload(response.data, "databases.zip");
    });
  };

  const onDeleteDatabases = () => {
    api.deleteDatabases(Array.from(state.selectedDatabaseIds), signal.token).then(() => {
      const databases = state.databases.filter((database) => !state.selectedDatabaseIds.has(database.value));

      setStateSafely({
        selectedDatabaseIds: new Set(),
        databases,
      });
    });
  };

  const onToggleRun = (runId) => {
    // noinspection JSCheckFunctionSignatures
    const selectedRunIds = new Set(state.selectedRunIds);

    toggleSetExistence(selectedRunIds, runId);

    setStateSafely({
      selectedRunIds,
    });
  };

  const onDeleteRuns = () => {
    api.deleteRuns(Array.from(state.selectedRunIds), signal.token).then(() => {
      const runs = state.runs.filter((run) => !state.selectedRunIds.has(run.value));

      setStateSafely({
        selectedRunIds: new Set(),
        runs,
      });

      api.fetchDatabases(signal.token).then((response) => {
        setStateSafely({
          databases: normalizeDatabasesLocal(response.data),
        });
      });
    });
  };

  return (
    <ManageData
      loadingData={state.loadingData}
      match={props.match}
      databases={state.databases}
      selectedDatabaseIds={Array.from(state.selectedDatabaseIds)}
      selectedRunIds={Array.from(state.selectedRunIds)}
      runs={state.runs}
      onToggleDatabase={onToggleDatabase}
      onDeleteDatabases={onDeleteDatabases}
      onDownloadDatabases={onDownloadDatabases}
      onToggleRun={onToggleRun}
      onDeleteRuns={onDeleteRuns}
    />
  );
};

export default withErrorBoundary(ManageDataContainer);
