import React from "react";
import * as PropTypes from "prop-types";
import { css } from "@emotion/css";
import { managedLabeledValuePropType } from "../../propTypes";
import Button from "@mui/material/Button";
import { withTranslation } from "react-i18next";
import ManageDataItems from "./ManageDataItems";

const classes = {
  button: css`
    margin-right: 20px;
  `,
  buttonGroup: css`
    margin-top: 20px;
  `,
  footnotes: css`
    margin: 1em 0;
  `,
};

const ManageDataDatabases = ({ t, ...rest }) => (
  <div>
    <ManageDataItems
      items={rest.databases}
      selectedItemValues={rest.selectedDatabaseIds}
      onToggleItem={rest.onToggleDatabase}
    />

    <div className={classes.buttonGroup}>
      <Button
        variant="contained"
        color="primary"
        disabled={!rest.selectedDatabaseIds.length}
        className={classes.button}
        onClick={rest.onDownloadDatabases}
      >
        {t("Download selected databases")}
      </Button>

      <Button
        variant="contained"
        color="secondary"
        disabled={
          !rest.selectedDatabaseIds.length ||
          rest.databases.filter(
            (database) => rest.selectedDatabaseIds.includes(database.value) && (database.isInUse || database.isGlobal)
          ).length > 0
        }
        className={classes.button}
        onClick={rest.onDeleteDatabases}
      >
        {t("Delete selected databases")}
      </Button>
    </div>

    <div className={classes.footnotes}>
      <div>
        <sup>*</sup>
        {t("Global databases cannot be deleted.")}
      </div>
      <div>
        <sup>&dagger;</sup>
        {t("Databases referenced by active runs cannot be deleted.")}
      </div>
    </div>
  </div>
);

ManageDataDatabases.propTypes = {
  databases: PropTypes.arrayOf(managedLabeledValuePropType).isRequired,
  selectedDatabaseIds: PropTypes.arrayOf(PropTypes.string).isRequired,
  onToggleDatabase: PropTypes.func.isRequired,
  onDownloadDatabases: PropTypes.func.isRequired,
  onDeleteDatabases: PropTypes.func.isRequired,
};

ManageDataDatabases.defaultProps = {
  databases: [],
};

export default withTranslation()(ManageDataDatabases);
