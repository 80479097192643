import React from "react";
import ReactDOMServer from "react-dom/server";
import * as PropTypes from "prop-types";
import { measurePropType, surveyDatumPropType } from "../../propTypes";
import { indicators } from "../../constants";
import { getScaledResults } from "../../data";
import Tooltip from "@mui/material/Tooltip";
import { withTranslation } from "react-i18next";
import ChartsSurveyMarker from "./ChartsSurveyMarker";

import Highcharts from "highcharts";
import HighchartsMore from "highcharts/highcharts-more";
import HighchartsReact from "highcharts-react-official";

window.Highcharts = Highcharts;
require("highcharts/modules/exporting")(Highcharts);
require("highcharts/modules/no-data-to-display")(Highcharts);
HighchartsMore(Highcharts); // init module

Highcharts.setOptions({
  lang: {
    numericSymbols: null,
    thousandsSep: ",",
  },
});

const styles = {
  title: {
    marginBottom: 20,
    textAlign: "center",
    fontSize: "smaller",
  },
};

const ChartsConfidenceIntervalChart = (props) => {
  const getErrorBounds = () => {
    return props.surveys.reduce((result, survey) => {
      let error;

      if (props.indicator === "contraceptiveUseAny" && survey.estimatedContraceptiveUseAny) {
        error = {
          lower: survey.lowerContraceptiveUseAny,
          estimate: survey.estimatedContraceptiveUseAny,
          upper: survey.upperContraceptiveUseAny,
        };
      } else if (props.indicator === "contraceptiveUseModern" && survey.estimatedContraceptiveUseModern) {
        error = {
          lower: survey.lowerContraceptiveUseModern,
          estimate: survey.estimatedContraceptiveUseModern,
          upper: survey.upperContraceptiveUseModern,
        };
      } else if (props.indicator === "contraceptiveUseTraditional" && survey.estimatedContraceptiveUseTraditional) {
        error = {
          lower: survey.lowerContraceptiveUseTraditional,
          estimate: survey.estimatedContraceptiveUseTraditional,
          upper: survey.upperContraceptiveUseTraditional,
        };
      } else if (props.indicator === "unmetNeedAny" && survey.estimatedUnmetNeedAny) {
        error = {
          lower: survey.lowerUnmetNeedAny,
          estimate: survey.estimatedUnmetNeedAny,
          upper: survey.upperUnmetNeedAny,
        };
      }

      if (error) {
        result.push({
          startDate: survey.startDate,
          endDate: survey.endDate,
          isInUnion: survey.isInUnion,
          ...error,
        });
      }

      return result;
    }, []);
  };

  const formatCrosshairValue = (value) => {
    return props.measure === "percentage" ? `${(value * 100).toFixed(1)}%` : Math.floor(value / 1000).toFixed(0);
  };

  const getCrosshairValues = (index) => {
    const datum = props.data[index];

    return [
      {
        x: datum.year,
        y: datum.percentile2pt5,
      },
      {
        x: datum.year,
        y: datum.percentile10,
      },
      {
        x: datum.year,
        y: datum.median,
      },
      {
        x: datum.year,
        y: datum.percentile90,
      },
      {
        x: datum.year,
        y: datum.percentile97pt5,
      },
    ];
  };

  const CustomHighChart = ({ width, height, series }) => {
    const demandLabel = indicators.find((indicator) => indicator.value === "demand").label;
    const title = props.t(props.indicator === "demandModern" ? demandLabel : props.title);

    const options = {
      chart: {
        backgroundColor: null,
        width: width,
        height: height,
      },
      title: {
        text: "", // title
        // style: {
        //   fontSize: "12px",
        // },
      },
      //   subtitle: {
      //     text: props.description,
      //     style: {
      //       fontSize: "10px",
      //     },
      //   },
      xAxis: {
        categories: [],
        tickInterval: 10,
        labels: {
          style: {
            fontFamily: "Roboto,Segoe UI,Oxygen,Ubuntu,Cantarell,Fira Sans,Droid Sans,Helvetica Neue,sans-serif",
            fontSize: "10px",
            color: "#6b6b76",
          },
        },
      },
      yAxis: {
        title: {
          text: "",
        },
        labels: {
          tickInterval: 10,
          formatter: function() {
            return props.measure === "percentage" ? `${(this.value * 100).toFixed(0)}%` : this.value;
          },
          style: {
            fontFamily: "Roboto,Segoe UI,Oxygen,Ubuntu,Cantarell,Fira Sans,Droid Sans,Helvetica Neue,sans-serif",
            fontSize: "10px",
            color: "#6b6b76",
          },
        },
        endOnTick: false,
        startOnTick: false,
      },
      legend: {
        enabled: false,
      },
      credits: {
        enabled: false,
        // text: props.description,
        // href: "",
        // style: {
        //   cursor: "arrow",
        // },
      },
      exporting: {
        enabled: false,
      },
      tooltip: {
        crosshairs: true,
        shared: true,
        formatter: function() {
          if (this.points) {
            const crosshairValues = getCrosshairValues(this.points[0].point.index);

            return (
              `<b>${props.t("Year")}</b> ${this.points[0].point.category}<br/>` +
              `<hr />` +
              `<b>2.5%  </b> ${formatCrosshairValue(crosshairValues[0].y)}<br/>` +
              `<b>10.0% </b> ${formatCrosshairValue(crosshairValues[1].y)}<br/>` +
              `<b>50.0% </b> ${formatCrosshairValue(crosshairValues[2].y)}<br/>` +
              `<b>90.0% </b> ${formatCrosshairValue(crosshairValues[3].y)}<br/>` +
              `<b>97.5% </b> ${formatCrosshairValue(crosshairValues[4].y)}`
            );
          }
        },
      },
      series: series,
    };

    return (
      <div>
        <Tooltip title={props.description}>
          <div style={styles.title}>{title}</div>
        </Tooltip>
        <HighchartsReact highcharts={Highcharts} options={options} />
      </div>
    );
  };

  const GetSeries = (data, comparison) => {
    const series1 = {
      type: "arearange",
      name: "",
      data: data.map((datum) => [datum.year, datum.percentile2pt5, datum.percentile97pt5]),
      color: "lavender",
      opacity: !comparison ? 1.0 : 0.5,
      marker: {
        enabled: false,
      },
    };

    const series2 = comparison && {
      type: "arearange",
      name: "",
      data: data.map((datum) => [datum.year, datum.comparisonPercentile2pt5, datum.comparisonPercentile97pt5]),
      color: "plum",
      opacity: 0.5,
      marker: {
        enabled: false,
      },
    };

    const series3 = !comparison && {
      type: "arearange",
      name: "",
      data: data.map((datum) => [datum.year, datum.percentile10, datum.percentile90]),
      color: "plum",
      marker: {
        enabled: false,
      },
    };

    //***********************************************************************

    // Whats the reason for this???
    const series4 = comparison && {
      type: "line",
      name: "",
      data: data.map((datum) => [datum.year, datum.percentile10]),
      color: "black",
      opacity: 1,
      marker: {
        enabled: false,
      },
      // style={{
      //     fill: "none",
      // }}
    };

    // Whats the reason for this???
    const series5 = comparison && {
      type: "line",
      name: "",
      data: data.map((datum) => [datum.year, datum.comparisonPercentile10]),
      color: "black",
      dashStyle: "Dash",
      opacity: 1,
      marker: {
        enabled: false,
      },
      // style={{
      //     fill: "none",
      // }}
    };

    //***********************************************************************

    // Whats the reason for this???
    const series6 = comparison && {
      type: "line",
      name: "",
      data: data.map((datum) => [datum.year, datum.percentile90]),
      color: "black",
      opacity: 1,
      marker: {
        enabled: false,
      },
      // style={{
      //     fill: "none",
      // }}
    };

    // Whats the reason for this???
    const series7 = comparison && {
      type: "line",
      name: "",
      data: data.map((datum) => [datum.year, datum.comparisonPercentile90]),
      color: "black",
      dashStyle: "Dash",
      opacity: 1,
      marker: {
        enabled: false,
      },
      // style={{
      //     fill: "none",
      // }}
    };

    //***********************************************************************

    // NOTE: Always show this
    const series8 = {
      type: "line",
      name: "",
      data: data.map((datum) => [datum.year, datum.median]),
      color: "black",
      opacity: 1,
      marker: {
        enabled: false,
      },
    };

    // Whats the reason for this???
    const series9 = comparison && {
      type: "line",
      name: "",
      data: data.map((datum) => [datum.year, datum.comparisonMedian]),
      color: "black",
      dashStyle: "Dash",
      opacity: 1,
      marker: {
        enabled: false,
      },
      // style={{
      //     fill: "none",
      // }}
      // onNearestX={onNearestX}
    };

    //***********************************************************************

    // CustomSVGSeries
    const series10 = props.measure === "percentage" &&
      ![
        // "unmetNeedModern",
        // "demandModern",
        // "demandSatisfiedModern"
      ].includes(props.indicator) && {
        type: "scatter",
        data: props.surveys.map((survey) => {
          function encodeSvg(reactElement) {
            return "data:image/svg+xml," + escape(ReactDOMServer.renderToStaticMarkup(reactElement));
          }

          const customMarker = (
            <ChartsSurveyMarker
              dataSeriesType={survey.dataSeriesType}
              groupTypeRelativeToBaseline={survey.groupTypeRelativeToBaseline}
              ageGroupBias={survey.ageGroupBias}
              hasTraditionalMethodBias={survey.hasTraditionalMethodBias}
              modernMethodBias={survey.modernMethodBias}
            />
          );

          const base64Url = encodeSvg(customMarker);

          return {
            x: (survey.startDate + survey.endDate) / 2,
            y: survey[props.y] || 0,
            marker: {
              width: 14,
              height: 14,
              symbol: `url(${base64Url})`,
            },
          };
        }),
        enableMouseTracking: false,
      };

    // MarkSeries
    const series11 = props.measure === "percentage" && {
      type: "scatter",
      name: "",
      data: props.emuData.map((datum) => ({
        x: datum.startYear + 0.5,
        y: datum.emu,
        marker: {
          symbol: "circle",
          lineColor: "black",
          fillColor: "black",
          lineWidth: 2,
          radius: 3,
        },
      })),
      enableMouseTracking: false,
    };

    // WhiskerSeries
    const indicatorErrors = getErrorBounds();
    const series12 = props.measure === "percentage" && {
      type: "errorbar",
      name: "",
      data: indicatorErrors.map((error) => ({
        x: (error.startDate + error.endDate) / 2,
        low: error.lower,
        high: error.upper,
      })),
      color: "black",
      whiskerLength: 6,
      enableMouseTracking: false,
    };

    //***********************************************************************

    const series = [
      series1,
      series2,
      series3,
      series4,
      series5,
      series6,
      series7,
      series8,
      series9,
      series10,
      series11,
      series12,
    ];

    return series;
  };

  const data = getScaledResults(props.measure, props.data);

  const series = GetSeries(data, props.comparison);

  return (
    <>
      {/* <CustomHighChart width={props.width} height={props.height} series={series1} /> */}
      {CustomHighChart({ width: props.width, height: props.height, series: series })}
    </>
  );
};

ChartsConfidenceIntervalChart.propTypes = {
  comparison: PropTypes.bool,
  measure: measurePropType.isRequired,
  indicator: PropTypes.string.isRequired,
  width: PropTypes.number,
  height: PropTypes.number,
  title: PropTypes.string.isRequired,
  description: PropTypes.string,
  xDomain: PropTypes.arrayOf(PropTypes.number),
  data: PropTypes.arrayOf(
    PropTypes.shape({
      year: PropTypes.number.isRequired,
      percentile2pt5: PropTypes.number.isRequired,
      percentile10: PropTypes.number.isRequired,
      median: PropTypes.number.isRequired,
      percentile90: PropTypes.number.isRequired,
      percentile97pt5: PropTypes.number.isRequired,
      comparisonPercentile2pt5: PropTypes.number,
      comparisonPercentile10: PropTypes.number,
      comparisonMedian: PropTypes.number,
      comparisonPercentile90: PropTypes.number,
      comparisonPercentile97pt5: PropTypes.number,
    })
  ),
  surveys: PropTypes.arrayOf(surveyDatumPropType),
  y: PropTypes.string.isRequired,
  tickValues: PropTypes.arrayOf(PropTypes.number),
  shadeInnerBand: PropTypes.bool,
};

ChartsConfidenceIntervalChart.defaultProps = {
  description: "",
  comparison: false,
  width: 300,
  height: 300,
  data: [],
  surveys: [],
  emuData: [],
};

export default withTranslation()(ChartsConfidenceIntervalChart);
