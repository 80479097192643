import React from "react";
import * as PropTypes from "prop-types";
import { maritalStatusPropType } from "../propTypes";
import NativeSelect from "@mui/material/NativeSelect";

const SentenceMaritalStatusSelector = (props) => (
  <NativeSelect
    value={props.value}
    className={props.className}
    onChange={(e) => {
      props.onChange(e.target.value);
    }}
  >
    <option value="married">married</option>
    <option value="unmarried">unmarried</option>
    <option value="all_women">all</option>
  </NativeSelect>
);

SentenceMaritalStatusSelector.propTypes = {
  value: maritalStatusPropType,
  className: PropTypes.string,
  onChange: PropTypes.func.isRequired,
};

export default SentenceMaritalStatusSelector;
