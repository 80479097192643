import React from "react";
import * as PropTypes from "prop-types";
import { css } from "@emotion/css";
import { labeledValuePropType, surveyDatumPropType } from "../../propTypes";
import { createDefaultSurveyDatum } from "../../data";
import Button from "@mui/material/Button";
import "ag-grid-enterprise";
import "ag-grid-community/styles/ag-grid.css";
import "ag-grid-community/styles/ag-theme-material.css";
import { withTranslation } from "react-i18next";
import CountrySelector from "./PrepareDataCountrySelector";
import Saver from "./PrepareDataSaver";
import PrepareDataSurveyDataTable from "../AGGrid/PrepareDataSurveyDataTable";

const classes = {
  button: css`
    margin-right: 20px;
  `,
  buttonGroup: css`
    margin-top: 20px;
    margin-bottom: 20px;
  `,
  regionEditor: css`
    margin: 20px 0 20px;
  `,
  table: css`
    margin: 20px 0;
    height: 400px;
  `,
};

const PrepareDataSurveyData = (props) => {
  const [state, setState] = React.useState({
    rowSelected: undefined,
    gridApi: undefined,
  });

  const setStateSafely = (newState) => {
    setState((prevState) => ({
      ...prevState,
      ...newState,
    }));
  };

  const onGridReady = (params) => {
    setStateSafely({
      gridApi: params.api,
    });
  };

  const onSelectionChanged = () => {
    setStateSafely({
      rowSelected: state.gridApi.getSelectedRows().length > 0,
    });
  };

  const onAddNewItem = () => {
    const item = createDefaultSurveyDatum();

    state.gridApi.applyTransaction({ add: [item] });
    state.gridApi.ensureIndexVisible(state.gridApi.getDisplayedRowCount() - 1, "bottom");

    props.onAddNewItem(item);
  };

  const onDeleteItem = () => {
    const selectedItemIds = state.gridApi.getSelectedRows().map((item) => item.id);

    if (!selectedItemIds.length) {
      return;
    }

    props.onDeleteItem(selectedItemIds[0]);

    setStateSafely({
      rowSelected: false,
    });
  };

  return (
    <div>
      <CountrySelector
        databases={props.databases}
        countries={props.countries}
        selectedDatabaseId={props.selectedDatabaseId}
        selectedCountryNumericCode={props.selectedCountryNumericCode}
        onSelectDatabase={props.onSelectDatabase}
        onSelectCountry={props.onSelectCountry}
        onLoadData={props.onLoadCountryData}
      />

      <PrepareDataSurveyDataTable
        data={props.data}
        onAddItem={props.onAddItem}
        onDeleteItem={props.onDeleteItem}
        onGridReady={onGridReady}
        onSelectedChanged={onSelectionChanged}
        onEditItem={props.onEditItem}
      />

      <div className={classes.buttonGroup}>
        <Button variant="contained" color="primary" className={classes.button} onClick={onAddNewItem}>
          {props.t("Add new record")}
        </Button>

        <Button
          variant="contained"
          color="secondary"
          disabled={!state.rowSelected}
          className={classes.button}
          onClick={onDeleteItem}
        >
          {props.t("Delete selected row")}
        </Button>

        <Button variant="contained" color="secondary" className={classes.button} onClick={props.onClearData}>
          {props.t("Clear data")}
        </Button>
      </div>

      <Saver
        name={props.newDatabaseName}
        saveDisabled={
          (!props.regionData && !props.selectedCountryNumericCode) ||
          (props.regionData && (!props.regionNumericCode || !props.regionAlphaCode || !props.regionName)) ||
          !props.newDatabaseName
        }
        onChangeName={props.onChangeDatabaseName}
        onSave={props.onSave}
      />
    </div>
  );
};

PrepareDataSurveyData.propTypes = {
  databases: PropTypes.arrayOf(labeledValuePropType),
  countries: PropTypes.arrayOf(labeledValuePropType),
  selectedDatabaseId: PropTypes.string,
  selectedCountryNumericCode: PropTypes.number,
  data: PropTypes.arrayOf(surveyDatumPropType),
  newDatabaseName: PropTypes.string,
  onSelectDatabase: PropTypes.func.isRequired,
  onSelectCountry: PropTypes.func.isRequired,
  onLoadCountryData: PropTypes.func.isRequired,
  onAddNewItem: PropTypes.func.isRequired,
  onEditItem: PropTypes.func.isRequired,
  onDeleteItem: PropTypes.func.isRequired,
  onClearData: PropTypes.func.isRequired,
  onChangeDatabaseName: PropTypes.func.isRequired,
  onSave: PropTypes.func.isRequired,
};

PrepareDataSurveyData.defaultProps = {
  databases: [],
  countries: [],
  data: [],
};

export default withTranslation()(PrepareDataSurveyData);
