import React from "react";
import * as PropTypes from "prop-types";
import { managedLabeledValuePropType } from "../../propTypes";
import Button from "@mui/material/Button";
import { withTranslation } from "react-i18next";
import ManageDataItems from "./ManageDataItems";

const styles = {
  buttonGroup: {
    marginTop: 20,
  },
};

const ManageDataRuns = ({ t, ...rest }) => (
  <div>
    <ManageDataItems items={rest.runs} selectedItemValues={rest.selectedRunIds} onToggleItem={rest.onToggleRun} />

    <div style={styles.buttonGroup}>
      <Button
        variant="contained"
        color="secondary"
        disabled={
          !rest.selectedRunIds.length ||
          rest.runs.filter((run) => rest.selectedRunIds.includes(run.value) && run.isGlobal).length
        }
        onClick={rest.onDeleteRuns}
      >
        {t("Delete selected runs")}
      </Button>
    </div>

    <p>
      <sup>*</sup>
      {t("Global runs cannot be deleted.")}
    </p>
  </div>
);

ManageDataRuns.propTypes = {
  runs: PropTypes.arrayOf(managedLabeledValuePropType),
  selectedRunIds: PropTypes.arrayOf(PropTypes.string).isRequired,
  onToggleRun: PropTypes.func.isRequired,
  onDeleteRuns: PropTypes.func.isRequired,
};

ManageDataRuns.defaultProps = {
  runs: [],
};

export default withTranslation()(ManageDataRuns);
