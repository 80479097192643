import React from "react";
import * as PropTypes from "prop-types";
import { formatNumericCell, parseNullableAlphaCell, parseNullableNumericCell, parseNumericCell } from "../../utilities";
import { AgGridReact } from "ag-grid-react";
import "ag-grid-enterprise";
import "ag-grid-community/styles/ag-grid.css";
import "ag-grid-community/styles/ag-theme-material.css";
import { withTranslation } from "react-i18next";
import { translateGridText } from "../../i18n";
import BooleanCellRenderer from "../BooleanCellRenderer";
import { maximumYear, minimumYear } from "../../constants";

const PrepareDataSurveyDataTable = ({ t, ...rest }) => {
  const onGridReady = (params) => {
    rest.onGridReady(params);
    params.columnApi.autoSizeAllColumns();
  };

  const onSelectionChanged = () => {
    rest.onSelectedChanged();
  };

  const refreshCells = (params) => {
    params.gridApi.refreshCells();
  };

  const components = React.useMemo(
    () => ({
      booleanCellRenderer: BooleanCellRenderer,
    }),
    []
  );

  const colDefs = [
    {
      field: "include",
      headerName: t("Include?"),
      cellRenderer: "booleanCellRenderer",
      cellEditor: "agRichSelectCellEditor",
      cellEditorParams: {
        cellRenderer: "booleanCellRenderer",
        values: [true, false],
      },
      cellEditorPopup: true,
    },
    {
      field: "possible_outlier",
      headerName: t("possible_outlier"),
      cellEditor: "agRichSelectCellEditor",
      cellEditorParams: {
        values: ["0", "1", null],
      },
      cellEditorPopup: true,
    },
    {
      field: "possible_outlier_userinput",
      headerName: t("possible_outlier_userinput"),
      cellEditor: "agRichSelectCellEditor",
      cellEditorParams: {
        values: ["0", "1", null],
      },
      cellEditorPopup: true,
    },
    {
      field: "regionCode",
      headerName: t("Region code"),
      valueParser: (params) => parseNullableAlphaCell(params),
    },
    {
      field: "startDate",
      headerName: t("Start year"),
      type: "numericColumn",
      filter: "agNumberColumnFilter",
      valueFormatter: (params) => formatNumericCell(params.value, 2),
      valueParser: (params) => parseNumericCell(params, minimumYear + 0.5, params.node.data.endDate),
    },
    {
      field: "endDate",
      headerName: t("End year"),
      type: "numericColumn",
      filter: "agNumberColumnFilter",
      valueFormatter: (params) => formatNumericCell(params.value, 2),
      valueParser: (params) => parseNumericCell(params, params.node.data.startDate, maximumYear + 0.5),
    },
    {
      field: "isInUnion",
      headerName: t("In union?"),
      cellRenderer: "booleanCellRenderer",
      cellEditor: "agRichSelectCellEditor",
      cellEditorParams: {
        cellRenderer: "booleanCellRenderer",
        values: [true, false],
      },
      cellEditorPopup: true,
    },
    {
      field: "ageRange",
      headerName: t("Age"),
      cellEditor: "agRichSelectCellEditor",
      cellEditorParams: {
        values: ["10-14", "15-19", "20-24", "25-29", "30-34", "35-39", "40-44", "45-49", "15-49"],
      },
      cellEditorPopup: true,
    },
    {
      field: "dataSeriesType",
      headerName: t("Data series/type"),
      cellEditor: "agRichSelectCellEditor",
      cellEditorParams: {
        values: ["DHS", "MICS", "National survey", "PMA", "Other"],
      },
      cellEditorPopup: true,
    },
    {
      field: "groupTypeRelativeToBaseline",
      headerName: t("Population"),
      headerTooltip: t("Group type relative to baseline"),
      cellEditor: "agRichSelectCellEditor",
      cellEditorParams: {
        values: ["AL", "BS", "EM", "FM", "HW", "MW", "PW", "SA", "UW"],
      },
      cellEditorPopup: true,
    },
    {
      field: "contraceptiveUseModern",
      headerName: t("Modern contraceptive use"),
      type: "numericColumn",
      filter: "agNumberColumnFilter",
      valueFormatter: (params) => formatNumericCell(params.value, 3),
      valueParser: (params) => parseNumericCell(params, 0, 1),
      headerTooltip: t("Contraceptive use (modern)"),
    },
    {
      field: "contraceptiveUseTraditional",
      headerName: t("Traditional contraceptive use"),
      type: "numericColumn",
      filter: "agNumberColumnFilter",
      valueFormatter: (params) => formatNumericCell(params.value, 3),
      valueParser: (params) => parseNumericCell(params, 0, 1),
      headerTooltip: t("Contraceptive use (traditional)"),
    },
    {
      field: "contraceptiveUseAny",
      headerName: t("All contraceptive use"),
      type: "numericColumn",
      filter: "agNumberColumnFilter",
      valueFormatter: (params) => formatNumericCell(params.value, 3),
      valueParser: (params) => parseNumericCell(params, 0, 1),
      headerTooltip: t("Contraceptive use (all)"),
    },
    {
      field: "unmetNeedModern",
      headerName: t("Modern methods unmet need"),
      type: "numericColumn",
      filter: "agNumberColumnFilter",
      valueFormatter: (params) => formatNumericCell(params.value, 3),
      valueParser: (params) => parseNullableNumericCell(params, 0, 1),
      headerTooltip: t("Unmet need for modern methods"),
    },
    {
      field: "unmetNeedAny",
      headerName: t("Any method unmet need"),
      type: "numericColumn",
      filter: "agNumberColumnFilter",
      valueFormatter: (params) => formatNumericCell(params.value, 3),
      valueParser: (params) => parseNullableNumericCell(params, 0, 1),
      headerTooltip: t("Unmet need for any method"),
    },
    {
      field: "pertainingToMethodsUsedSinceLastPregnancyReason",
      headerName: t("Pertaining to methods used since last pregnancy"),
    },

    {
      field: "geographicalRegionBiasReason",
      headerName: t("Geographical bias"),
    },
    {
      headerName: t("Non-pregnant and other positive biases"),
      field: "nonPregnantAndOtherPositiveBiasesReason",
    },
    {
      field: "ageGroupBias",
      headerName: t("Age bias"),
      cellEditor: "agRichSelectCellEditor",
      cellEditorParams: {
        values: ["+", "-", "?", "None"],
      },
      cellEditorPopup: true,
    },
    {
      field: "modernMethodBias",
      headerName: t("Modern method bias"),
      cellEditor: "agRichSelectCellEditor",
      cellEditorParams: {
        values: ["+", "-", "None"],
      },
      cellEditorPopup: true,
    },
    {
      field: "traditionalMethodBiasReason",
      headerName: t("Traditional method bias"),
    },
    {
      field: "hasAbsenceOfProbingQuestionsBias",
      headerName: t("Absence of probing question bias?"),
      cellRenderer: "booleanCellRenderer",
      cellEditor: "agRichSelectCellEditor",
      cellEditorParams: {
        cellRenderer: "booleanCellRenderer",
        values: [true, false],
      },
      cellEditorPopup: true,
    },
    {
      field: "seLogRModernNoUse",
      headerName: t("SE log-ratio modern/no use"),
      type: "numericColumn",
      filter: "agNumberColumnFilter",
      valueFormatter: (params) => formatNumericCell(params.value, 2),
      valueParser: (params) => parseNullableNumericCell(params, 0, Number.MAX_VALUE),
      headerTooltip: t("Sampling error for the log-ratio of modern contraceptive method use to no use"),
    },
    {
      field: "seLogRTraditionalNoUse",
      headerName: t("SE log-ratio traditional/no use"),
      type: "numericColumn",
      filter: "agNumberColumnFilter",
      valueFormatter: (params) => formatNumericCell(params.value, 2),
      valueParser: (params) => parseNullableNumericCell(params, 0, Number.MAX_VALUE),
      headerTooltip: t("Sampling error for the log-ratio of traditional contraceptive method use to no use"),
    },
    {
      field: "seLogRUnmetNoNeed",
      headerName: t("SE log-ratio unmet/no need"),
      type: "numericColumn",
      filter: "agNumberColumnFilter",
      valueFormatter: (params) => formatNumericCell(params.value, 2),
      valueParser: (params) => parseNullableNumericCell(params, 0, Number.MAX_VALUE),
      headerTooltip: t("Sampling error for the log-ratio of unmet need (any method) to no need"),
    },
    {
      field: "sourceId",
      headerName: t("Source ID"),
      type: "numericColumn",
    },
  ];

  const rowData = rest.data;

  return (
    <div className="ag-theme-material">
      <AgGridReact
        domLayout="autoHeight"
        defaultColDef={{ sortable: true, editable: true }}
        pagination={true}
        paginationPageSize={10}
        suppressColumnVirtualisation={true}
        suppressCellFocus={true}
        components={components}
        overlayNoRowsTemplate="<span />"
        getLocaleText={translateGridText}
        rowData={rowData}
        columnDefs={colDefs}
        onGridReady={onGridReady}
        rowSelection="single"
        onSelectionChanged={onSelectionChanged}
        stopEditingWhenCellsLoseFocus={true}
        onCellValueChanged={(params) => {
          rest.onEditItem(params.data, params.colDef.field, refreshCells);
        }}
      />
    </div>
  );
};

PrepareDataSurveyDataTable.propTypes = {
  data: PropTypes.array,
};

PrepareDataSurveyDataTable.defaultProps = {
  data: [],
};

export default withTranslation()(PrepareDataSurveyDataTable);

// <div className={classNames("ag-theme-material", classes.table)}>
//     <AgGridReact
//       defaultColDef={{
//         sortable: true,
//         editable: true,
//       }}
//       rowSelection="single"
//       suppressColumnVirtualisation={true}
//       suppressScrollOnNewData={true}
//       frameworkComponents={{ booleanCellRenderer: BooleanCellRenderer }}
//       overlayNoRowsTemplate="<span />"
//       getLocaleText={translateGridText}
//       rowData={props.data}
//       onGridReady={onGridReady}
//       onSelectionChanged={onSelectionChanged}
//       onCellValueChanged={(params) => {
//         props.onEditItem(params.data, params.colDef.field, refreshCells);
//       }}
//     >
//       <AgGridColumn
//         headerName={props.t("Include?")}
//         field="include"
//         cellRenderer="booleanCellRenderer"
//         cellEditor="agRichSelectCellEditor"
//         cellEditorParams={{
//           cellRenderer: "booleanCellRenderer",
//           values: [true, false],
//         }}
//       />
//       <AgGridColumn
//         headerName={props.t("possible_outlier")}
//         field="possible_outlier"
//         cellEditor="agRichSelectCellEditor"
//         cellEditorParams={{
//           values: ["0", "1", null],
//         }}
//       />
//       <AgGridColumn
//         headerName={props.t("possible_outlier_userinput")}
//         field="possible_outlier_userinput"
//         cellEditor="agRichSelectCellEditor"
//         cellEditorParams={{
//           values: ["0", "1", null],
//         }}
//       />
//       <AgGridColumn
//         headerName={props.t("Region code")}
//         field="regionCode"
//         valueParser={(params) => parseNullableAlphaCell(params)}
//       />
//       <AgGridColumn
//         headerName={props.t("Start year")}
//         field="startDate"
//         type="numericColumn"
//         filter="agNumberColumnFilter"
//         valueFormatter={(params) => formatNumericCell(params.value, 2)}
//         valueParser={(params) => parseNumericCell(params, minimumYear + 0.5, params.node.data.endDate)}
//       />
//       <AgGridColumn
//         headerName={props.t("End year")}
//         field="endDate"
//         type="numericColumn"
//         filter="agNumberColumnFilter"
//         valueFormatter={(params) => formatNumericCell(params.value, 2)}
//         valueParser={(params) => parseNumericCell(params, params.node.data.startDate, maximumYear + 0.5)}
//       />
//       <AgGridColumn
//         headerName={props.t("In union?")}
//         field="isInUnion"
//         cellRenderer="booleanCellRenderer"
//         cellEditor="agRichSelectCellEditor"
//         cellEditorParams={{
//           cellRenderer: "booleanCellRenderer",
//           values: [true, false],
//         }}
//       />
//       <AgGridColumn
//         headerName={props.t("Age")}
//         field="ageRange"
//         cellEditor="agRichSelectCellEditor"
//         cellEditorParams={{
//           values: ["10-14", "15-19", "20-24", "25-29", "30-34", "35-39", "40-44", "45-49", "15-49"],
//         }}
//       />
//       <AgGridColumn
//         headerName={props.t("Data series/type")}
//         field="dataSeriesType"
//         cellEditor="agRichSelectCellEditor"
//         cellEditorParams={{
//           values: ["DHS", "MICS", "National survey", "PMA", "Other"],
//         }}
//       />
//       <AgGridColumn
//         headerName={props.t("Population")}
//         field="groupTypeRelativeToBaseline"
//         headerTooltip={props.t("Group type relative to baseline")}
//         cellEditor="agRichSelectCellEditor"
//         cellEditorParams={{
//           values: ["AL", "BS", "EM", "FM", "HW", "MW", "PW", "SA", "UW"],
//         }}
//       />
//       <AgGridColumn
//         headerName={props.t("Modern contraceptive use")}
//         field="contraceptiveUseModern"
//         type="numericColumn"
//         filter="agNumberColumnFilter"
//         valueFormatter={(params) => formatNumericCell(params.value, 3)}
//         valueParser={(params) => parseNumericCell(params, 0, 1)}
//         headerTooltip={props.t("Contraceptive use (modern)")}
//       />
//       <AgGridColumn
//         headerName={props.t("Traditional contraceptive use")}
//         field="contraceptiveUseTraditional"
//         type="numericColumn"
//         filter="agNumberColumnFilter"
//         valueFormatter={(params) => formatNumericCell(params.value, 3)}
//         valueParser={(params) => parseNumericCell(params, 0, 1)}
//         headerTooltip={props.t("Contraceptive use (traditional)")}
//       />
//       <AgGridColumn
//         headerName={props.t("All contraceptive use")}
//         field="contraceptiveUseAny"
//         type="numericColumn"
//         filter="agNumberColumnFilter"
//         valueFormatter={(params) => formatNumericCell(params.value, 3)}
//         valueParser={(params) => parseNumericCell(params, 0, 1)}
//         headerTooltip={props.t("Contraceptive use (all)")}
//       />
//       <AgGridColumn
//         headerName={props.t("Modern methods unmet need")}
//         field="unmetNeedModern"
//         type="numericColumn"
//         filter="agNumberColumnFilter"
//         valueFormatter={(params) => formatNumericCell(params.value, 3)}
//         valueParser={(params) => parseNullableNumericCell(params, 0, 1)}
//         headerTooltip={props.t("Unmet need for modern methods")}
//       />
//       <AgGridColumn
//         headerName={props.t("Any method unmet need")}
//         field="unmetNeedAny"
//         type="numericColumn"
//         filter="agNumberColumnFilter"
//         valueFormatter={(params) => formatNumericCell(params.value, 3)}
//         valueParser={(params) => parseNullableNumericCell(params, 0, 1)}
//         headerTooltip={props.t("Unmet need for any method")}
//       />
//       <AgGridColumn
//         headerName={props.t("Pertaining to methods used since last pregnancy")}
//         field="pertainingToMethodsUsedSinceLastPregnancyReason"
//       />
//       <AgGridColumn headerName={props.t("Geographical bias")} field="geographicalRegionBiasReason" />
//       <AgGridColumn
//         headerName={props.t("Non-pregnant and other positive biases")}
//         field="nonPregnantAndOtherPositiveBiasesReason"
//       />
//       <AgGridColumn
//         headerName={props.t("Age bias")}
//         field="ageGroupBias"
//         cellEditor="agRichSelectCellEditor"
//         cellEditorParams={{
//           values: ["+", "-", "?", "None"],
//         }}
//       />
//       <AgGridColumn
//         headerName={props.t("Modern method bias")}
//         field="modernMethodBias"
//         cellEditor="agRichSelectCellEditor"
//         cellEditorParams={{
//           values: ["+", "-", "None"],
//         }}
//       />
//       <AgGridColumn headerName={props.t("Traditional method bias")} field="traditionalMethodBiasReason" />
//       <AgGridColumn
//         headerName={props.t("Absence of probing question bias?")}
//         field="hasAbsenceOfProbingQuestionsBias"
//         cellRenderer="booleanCellRenderer"
//         cellEditor="agRichSelectCellEditor"
//         cellEditorParams={{
//           cellRenderer: "booleanCellRenderer",
//           values: [true, false],
//         }}
//       />
//       <AgGridColumn
//         headerName={props.t("SE log-ratio modern/no use")}
//         field="seLogRModernNoUse"
//         type="numericColumn"
//         filter="agNumberColumnFilter"
//         valueFormatter={(params) => formatNumericCell(params.value, 2)}
//         valueParser={(params) => parseNullableNumericCell(params, 0, Number.MAX_VALUE)}
//         headerTooltip={props.t("Sampling error for the log-ratio of modern contraceptive method use to no use")}
//       />
//       <AgGridColumn
//         headerName={props.t("SE log-ratio traditional/no use")}
//         field="seLogRTraditionalNoUse"
//         type="numericColumn"
//         filter="agNumberColumnFilter"
//         valueFormatter={(params) => formatNumericCell(params.value, 2)}
//         valueParser={(params) => parseNullableNumericCell(params, 0, Number.MAX_VALUE)}
//         headerTooltip={props.t(
//           "Sampling error for the log-ratio of traditional contraceptive method use to no use"
//         )}
//       />
//       <AgGridColumn
//         headerName={props.t("SE log-ratio unmet/no need")}
//         field="seLogRUnmetNoNeed"
//         type="numericColumn"
//         filter="agNumberColumnFilter"
//         valueFormatter={(params) => formatNumericCell(params.value, 2)}
//         valueParser={(params) => parseNullableNumericCell(params, 0, Number.MAX_VALUE)}
//         headerTooltip={props.t("Sampling error for the log-ratio of unmet need (any method) to no need")}
//       />
//       <AgGridColumn headerName={props.t("Source ID")} field="sourceId" type="numericColumn" />
//     </AgGridReact>
//   </div>
