import React from "react";
import * as PropTypes from "prop-types";
import { css } from "@emotion/css";
import { deltaPropType, directionPropType, maritalStatusPropType } from "../../propTypes";
import Divider from "@mui/material/Divider";
import Typography from "@mui/material/Typography";
import { withTranslation } from "react-i18next";
import ProgressButton from "../ProgressButton";
import TargetsIndicatorLevelSpecification from "./TargetsIndicatorLevelSpecification";
import TargetsIndicatorProbabilitySpecification from "./TargetsIndicatorProbabilitySpecification";
import TargetsPopulationLevelSpecification from "./TargetsPopulationLevelSpecification";
import TargetsPopulationProbabilitySpecification from "./TargetsPopulationProbabilitySpecification";

const classes = {
  answer: css`
    font-weight: bold;
  `,
  button: css`
    display: table;
    margin-top: 20px;
  `,
  panel: css`
    margin: 20px 0 33px;
  `,
};

const Targets = ({ t, ...rest }) => (
  <div>
    <Typography variant="h5">{t("Information for target setting")}</Typography>

    <p>
      {t(
        "Select entries below to find a probability associated with a given level of an indicator of interest and vice versa."
      )}
    </p>

    <Divider />

    <p>
      {t(
        "What is this target? If you have a prevalence goal, you can use this option to find out the probability of meeting that goal. For example, you may want to know the probability that mCPR is above 32% in 2030, and the answer is .4, this means there is a 40% change the mCPR will be above 32% and a 60% chance the mCPR will be below 32% in 2030."
      )}
    </p>

    <div className={classes.panel}>
      <TargetsIndicatorProbabilitySpecification
        indicators={rest.indicators}
        indicator={rest.indicatorProbabilityParameters.indicator}
        maritalStatus={rest.indicatorProbabilityParameters.maritalStatus}
        year={rest.indicatorProbabilityParameters.year}
        direction={rest.indicatorProbabilityParameters.direction}
        level={rest.indicatorProbabilityParameters.level}
        probability={rest.indicatorProbabilityParameters.probability}
        onSelectIndicator={rest.onSelectIndicatorProbabilityIndicator}
        onSelectMaritalStatus={rest.onSelectIndicatorProbabilityMaritalStatus}
        onChangeYear={rest.onChangeIndicatorProbabilityYear}
        onSelectDirection={rest.onSelectIndicatorProbabilityDirection}
        onChangeLevel={rest.onChangeIndicatorProbabilityLevel}
      />
      <p className={classes.answer}>
        The probability is{" "}
        {rest.indicatorProbability != null ? `${(rest.indicatorProbability * 100).toFixed(0)}%` : "???"}
      </p>
    </div>

    <Divider />

    <p>
      {t(
        'What is this target? If you want to set a target/objective for your country that is ambitious, yet still obtainable, you would use this option. For example, if you wanted to have a target that you only had a 25% chance of achieving (a 75% chance of not achieving), you would enter as your attainment probability ".25" and choose an outcome "greater" than the target value. This would tell you what prevalence you have a 25% chance of reaching.'
      )}
    </p>

    <div className={classes.panel}>
      <TargetsIndicatorLevelSpecification
        indicators={rest.indicators}
        indicator={rest.indicatorLevelParameters.indicator}
        maritalStatus={rest.indicatorLevelParameters.maritalStatus}
        year={rest.indicatorLevelParameters.year}
        probability={rest.indicatorLevelParameters.probability}
        direction={rest.indicatorLevelParameters.direction}
        level={rest.indicatorLevelParameters.level}
        onSelectIndicator={rest.onSelectIndicatorLevelIndicator}
        onSelectMaritalStatus={rest.onSelectIndicatorLevelMaritalStatus}
        onChangeYear={rest.onChangeIndicatorLevelYear}
        onChangeProbability={rest.onChangeIndicatorLevelProbability}
        onSelectDirection={rest.onSelectIndicatorLevelDirection}
      />
      <p className={classes.answer}>
        {rest.indicatorLevel != null ? `${(rest.indicatorLevel * 100).toFixed(2)}%` : "???"}
      </p>
    </div>

    <Divider />

    <p>
      {t(
        "What is this target? This option is similar to the first option, but instead of a prevalence, it is for number of women. If you want to know the probability of having more than 1 million women using contraception, you would use this option, and it would give you a percent chance of having 1 million or more women using contraception."
      )}
    </p>

    <div className={classes.panel}>
      <TargetsPopulationProbabilitySpecification
        contraceptiveMethods={rest.contraceptiveMethods}
        delta={rest.populationProbabilityParameters.delta}
        maritalStatus={rest.populationProbabilityParameters.maritalStatus}
        relativeToYear={rest.populationProbabilityParameters.relativeToYear}
        contraceptiveMethod={rest.populationProbabilityParameters.contraceptiveMethod}
        year={rest.populationProbabilityParameters.year}
        direction={rest.populationProbabilityParameters.direction}
        level={rest.populationProbabilityParameters.level}
        probability={rest.populationProbabilityParameters.probability}
        onSelectDelta={rest.onSelectPopulationProbabilityDelta}
        onSelectMaritalStatus={rest.onSelectPopulationProbabilityMaritalStatus}
        onChangeRelativeToYear={rest.onChangePopulationProbabilityRelativeToYear}
        onSelectContraceptiveMethod={rest.onSelectPopulationProbabilityContraceptiveMethod}
        onChangeYear={rest.onChangePopulationProbabilityYear}
        onSelectDirection={rest.onSelectPopulationProbabilityDirection}
        onChangeLevel={rest.onChangePopulationProbabilityLevel}
      />
      <p className={classes.answer}>
        The probability is{" "}
        {rest.populationProbability != null ? `${(rest.populationProbability * 100).toFixed(0)}%` : "???"}
      </p>
    </div>

    <Divider />

    <p>
      {t(
        "What is this target? This option is similar to the second option, but instead of a prevalence, it is for number of women. If you want to set a goal of the number of women using family planning, and you want it to be ambitious yet attainable, you could enter in an attainment probably of .25 (you only have a 25% probability of meeting this number), and FPET will tell you how many women you would reach."
      )}
    </p>

    <div className={classes.panel}>
      <TargetsPopulationLevelSpecification
        contraceptiveMethods={rest.contraceptiveMethods}
        delta={rest.populationLevelParameters.delta}
        maritalStatus={rest.populationLevelParameters.maritalStatus}
        relativeToYear={rest.populationLevelParameters.relativeToYear}
        contraceptiveMethod={rest.populationLevelParameters.contraceptiveMethod}
        year={rest.populationLevelParameters.year}
        direction={rest.populationLevelParameters.direction}
        level={rest.populationLevelParameters.level}
        probability={rest.populationLevelParameters.probability}
        onSelectDelta={rest.onSelectPopulationLevelDelta}
        onSelectMaritalStatus={rest.onSelectPopulationLevelMaritalStatus}
        onChangeRelativeToYear={rest.onChangePopulationLevelRelativeToYear}
        onSelectContraceptiveMethod={rest.onSelectPopulationLevelContraceptiveMethod}
        onChangeYear={rest.onChangePopulationLevelYear}
        onSelectDirection={rest.onSelectPopulationLevelDirection}
        onChangeProbability={rest.onChangePopulationLevelProbability}
      />
      <p className={classes.answer}>{rest.populationLevel != null ? rest.populationLevel.toFixed(0) : "???"}</p>
    </div>

    <Divider />

    <ProgressButton
      variant="contained"
      color="primary"
      disabled={!rest.valid}
      loading={rest.calculating}
      className={classes.button}
      onClick={rest.onCalculate}
    >
      {t("Calculate")}
    </ProgressButton>
  </div>
);

Targets.propTypes = {
  indicators: PropTypes.array.isRequired,
  indicatorProbabilityParameters: PropTypes.shape({
    indicator: PropTypes.string.isRequired,
    maritalStatus: maritalStatusPropType,
    year: PropTypes.number,
    direction: directionPropType,
    level: PropTypes.number,
  }).isRequired,
  indicatorProbability: PropTypes.number,
  indicatorLevelParameters: PropTypes.shape({
    indicator: PropTypes.string.isRequired,
    maritalStatus: maritalStatusPropType,
    year: PropTypes.number,
    probability: PropTypes.string,
    direction: directionPropType,
  }).isRequired,
  indicatorLevel: PropTypes.number,
  populationProbabilityParameters: PropTypes.shape({
    delta: deltaPropType,
    maritalStatus: maritalStatusPropType,
    contraceptiveMethod: PropTypes.string.isRequired,
    year: PropTypes.number,
    direction: directionPropType,
    level: PropTypes.number,
  }).isRequired,
  populationProbability: PropTypes.number,
  populationLevelParameters: PropTypes.shape({
    delta: deltaPropType,
    maritalStatus: maritalStatusPropType,
    contraceptiveMethod: PropTypes.string.isRequired,
    year: PropTypes.number,
    probability: PropTypes.string,
    direction: directionPropType,
  }).isRequired,
  populationLevel: PropTypes.number,
  valid: PropTypes.bool.isRequired,
  calculating: PropTypes.bool,
  onSelectIndicatorProbabilityIndicator: PropTypes.func.isRequired,
  onSelectIndicatorProbabilityMaritalStatus: PropTypes.func.isRequired,
  onChangeIndicatorProbabilityYear: PropTypes.func.isRequired,
  onSelectIndicatorProbabilityDirection: PropTypes.func.isRequired,
  onChangeIndicatorProbabilityLevel: PropTypes.func.isRequired,
  onSelectIndicatorLevelIndicator: PropTypes.func.isRequired,
  onSelectIndicatorLevelMaritalStatus: PropTypes.func.isRequired,
  onChangeIndicatorLevelYear: PropTypes.func.isRequired,
  onChangeIndicatorLevelProbability: PropTypes.func.isRequired,
  onSelectIndicatorLevelDirection: PropTypes.func.isRequired,
  onSelectPopulationProbabilityDelta: PropTypes.func.isRequired,
  onSelectPopulationProbabilityMaritalStatus: PropTypes.func.isRequired,
  onChangePopulationProbabilityRelativeToYear: PropTypes.func.isRequired,
  onSelectPopulationProbabilityContraceptiveMethod: PropTypes.func.isRequired,
  onChangePopulationProbabilityYear: PropTypes.func.isRequired,
  onSelectPopulationProbabilityDirection: PropTypes.func.isRequired,
  onChangePopulationProbabilityLevel: PropTypes.func.isRequired,
  onSelectPopulationLevelDelta: PropTypes.func.isRequired,
  onSelectPopulationLevelMaritalStatus: PropTypes.func.isRequired,
  onChangePopulationLevelRelativeToYear: PropTypes.func.isRequired,
  onSelectPopulationLevelContraceptiveMethod: PropTypes.func.isRequired,
  onChangePopulationLevelYear: PropTypes.func.isRequired,
  onChangePopulationLevelProbability: PropTypes.func.isRequired,
  onSelectPopulationLevelDirection: PropTypes.func.isRequired,
  onCalculate: PropTypes.func.isRequired,
};

Targets.defaultProps = {
  calculating: false,
};

export default withTranslation()(Targets);
