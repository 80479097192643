import React from "react";
import * as PropTypes from "prop-types";
import { css } from "@emotion/css";
import { maritalStatusPropType } from "../../propTypes";
import { maximumYear, minimumYear } from "../../constants";
import classNames from "classnames";
import NativeSelect from "@mui/material/NativeSelect";
import TextField from "@mui/material/TextField";
import MaritalStatusSelector from "../SentenceMaritalStatusSelector";

const classes = {
  container: css`
    display: flex;
    align-items: baseline;
    flex-wrap: wrap;
  `,
  input: css`
    margin: 0 0.5em;
  `,
  year: css`
    width: 85px;
  `,
};

const ProgressIndicatorSpecification = (props) => (
  <div className={classes.container}>
    <span>The change in</span>

    <NativeSelect
      value={props.indicator}
      className={classes.input}
      onChange={(e) => {
        props.onSelectIndicator(e.target.value);
      }}
    >
      {props.indicators.map((indicator) => (
        <option key={indicator.value} value={indicator.value}>
          {indicator.label}
        </option>
      ))}
    </NativeSelect>

    <span>among</span>

    <MaritalStatusSelector
      value={props.maritalStatus}
      className={classes.input}
      onChange={props.onSelectMaritalStatus}
    />

    <span>women from the year</span>

    <TextField
      type="number"
      size="small"
      margin="dense"
      inputProps={{
        min: minimumYear,
        max: maximumYear,
      }}
      value={(props.period && props.period.firstYear) || ""}
      className={classNames(classes.input, classes.year)}
      onChange={(e) => {
        props.onChangeFirstYear(parseInt(e.target.value, 10) || undefined);
      }}
      onBlur={(e) => {
        props.onChangeFirstYear(parseInt(e.target.value, 10) || undefined, true);
      }}
    />

    <span>to the year</span>

    <TextField
      type="number"
      size="small"
      margin="dense"
      inputProps={{
        min: minimumYear,
        max: maximumYear,
      }}
      value={(props.period && props.period.lastYear) || ""}
      className={classNames(classes.input, classes.year)}
      onChange={(e) => {
        props.onChangeLastYear(parseInt(e.target.value, 10) || undefined);
      }}
      onBlur={(e) => {
        props.onChangeLastYear(parseInt(e.target.value, 10) || undefined, true);
      }}
    />

    <span>is...</span>
  </div>
);

ProgressIndicatorSpecification.propTypes = {
  indicators: PropTypes.arrayOf(
    PropTypes.shape({
      value: PropTypes.string.isRequired,
      label: PropTypes.string.isRequired,
    })
  ).isRequired,
  indicator: PropTypes.string.isRequired,
  maritalStatus: maritalStatusPropType,
  period: PropTypes.shape({
    firstYear: PropTypes.number,
    lastYear: PropTypes.number,
  }),
  onSelectIndicator: PropTypes.func.isRequired,
  onSelectMaritalStatus: PropTypes.func.isRequired,
  onChangeFirstYear: PropTypes.func.isRequired,
  onChangeLastYear: PropTypes.func.isRequired,
};

export default ProgressIndicatorSpecification;
