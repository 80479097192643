import React from "react";
import { createSlug } from "../utilities";
import { normalizeRuns } from "../data";
import * as api from "../api/api";
import fileDownload from "js-file-download";
import { withErrorBoundary } from "../errors";
import { withTranslation } from "react-i18next";
import RunHistory from "../pages/RunHistory";

const createInitialState = () => ({});

const getNewlyCompletedRunIds = (previousRuns, currentRuns) => {
  if (!previousRuns) {
    return [];
  }

  const previouslyCompletedRunIds = new Set((previousRuns || []).filter((run) => run.completed).map((run) => run.id));

  const currentlyCompletedRunIds = (currentRuns || []).filter((run) => run.completed).map((run) => run.id);

  return currentlyCompletedRunIds.filter((runId) => !previouslyCompletedRunIds.has(runId));
};

const RunHistoryContainer = (props) => {
  const [state, setState] = React.useState(createInitialState());
  const [refreshTimer, setRefreshTimer] = React.useState(undefined);

  const signal = api.getSignal();

  const setStateSafely = (newState) => {
    setState((prevState) => ({
      ...prevState,
      ...newState,
    }));
  };

  React.useEffect(() => {
    fetchRuns(false);

    return () => {
      if (refreshTimer) {
        window.clearTimeout(refreshTimer);
      }

      signal.cancel();
    };
  }, []);

  const fetchRuns = (refreshing) => {
    setStateSafely({
      loadingData: !refreshing,
    });

    api
      .fetchRuns(true, true, signal.token)
      .then((response) => {
        if (!response) {
          return;
        }

        const runs = Object.entries(normalizeRuns(response.data)).map(([id, run]) => ({
          id: id,
          name: run.name,
          startedAt: run.startedAt,
          completed: run.completed,
          failed: run.failed,
        }));

        const newlyCompletedRunIds = getNewlyCompletedRunIds(state.runs, runs);

        const notification = newlyCompletedRunIds.length && {
          notificationVariant: "success",
          notificationOpen: true,
          notificationMessage: props.t("{{runCount}} run(s) recently completed", {
            runCount: newlyCompletedRunIds.length,
          }),
        };

        setStateSafely({
          runs: Object.entries(normalizeRuns(response.data)).map(([id, run]) => ({
            id: id,
            name: run.name,
            startedAt: run.startedAt,
            completed: run.completed,
            failed: run.failed,
          })),
          ...(notification || {}),
        });
      })
      .finally(() => {
        setStateSafely({
          loadingData: false,
        });

        setRefreshTimer(window.setTimeout(() => fetchRuns(true), 60000));
      });
  };

  const onDownloadRunLog = (runId) => {
    api.fetchRunLog(runId, signal.token).then((response) => {
      if (!response) {
        return;
      }

      const run = state.runs.find((run) => run.id === runId);

      if (run) {
        fileDownload(response.data, createSlug(run.name, "run.log"));
      }
    });
  };

  const onCloseNotification = () => {
    setStateSafely({
      notificationOpen: false,
    });
  };

  return (
    <RunHistory
      loadingData={state.loadingData}
      runs={state.runs}
      notificationVariant={state.notificationVariant}
      notificationOpen={state.notificationOpen}
      notificationMessage={state.notificationMessage}
      onDownloadRunLog={onDownloadRunLog}
      onCloseNotification={onCloseNotification}
    />
  );
};

export default withTranslation()(withErrorBoundary(RunHistoryContainer));
