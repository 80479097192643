import React from "react";
import * as PropTypes from "prop-types";
import { css } from "@emotion/css";
import contentWithDrawer from "../components/contentWithDrawer";
import DrawerContent from "../components/StartRun/StartRunDrawerContent";
import MainContent from "../components/StartRun/StartRunMainContent";
import Notification from "../components/Notification";

const classes = {
  pageContainer: css`
    height: 100%;
  `,
};

const Component = contentWithDrawer(MainContent, DrawerContent);

const StartRun = ({ notificationVariant, notificationOpen, notificationMessage, onCloseNotification, ...rest }) => (
  <div className={classes.pageContainer}>
    <Component {...rest} />

    <Notification
      variant={notificationVariant}
      open={notificationOpen}
      message={notificationMessage}
      onClose={onCloseNotification}
    />
  </div>
);

StartRun.propTypes = {
  notificationVariant: PropTypes.string,
  notificationOpen: PropTypes.bool,
  notificationMessage: PropTypes.string,
  onCloseNotification: PropTypes.func.isRequired,
};

StartRun.defaultProps = {
  notificationOpen: false,
};

export default StartRun;
