import React from "react";
import { createGuid } from "../utilities";
import {
  to01Null,
  normalizeDatabases,
  normalizePopulationData,
  normalizeSurveyData,
  normalizeEmuData,
  synchonizeContraceptivePrevalence,
} from "../data";
import * as api from "../api/api";
import { withErrorBoundary } from "../errors";
import { withTranslation } from "react-i18next";
import PrepareData from "../pages/PrepareData";

const createInitialState = () => ({
  selectedEmuDatabaseId: "",
});

const findDefaultDatabaseId = (databases) => {
  if (!databases) {
    return;
  }

  for (let databaseId of Object.keys(databases)) {
    const { type, isDefault } = databases[databaseId];

    if (type === "survey" && isDefault) {
      return databaseId;
    }
  }
};

const getCountries = (databases, databaseId) => {
  if (!databases || !databaseId) {
    return;
  }

  const database = databases[databaseId];

  return Object.keys(database.divisions).reduce((result, divisionKey) => {
    const division = database.divisions[divisionKey];

    if (division.isCountry) {
      result[divisionKey] = division;
    }

    return result;
  }, {});
};

const findIndex = (data, key, value) => data.map((datum) => datum[key]).indexOf(value);

const PrepareDataContainer = (props) => {
  const [state, setState] = React.useState(createInitialState());

  const signal = api.getSignal();

  const setStateSafely = (newState) => {
    setState((prevState) => ({
      ...prevState,
      ...newState,
    }));
  };

  React.useEffect(() => {
    api.fetchDatabases(signal.token).then((response) => {
      if (!response) {
        return;
      }

      const databases = normalizeDatabases(response.data);

      setStateSafely({
        databases: databases,
        defaultCountries: getCountries(databases, findDefaultDatabaseId(databases)),
      });
    });

    return () => {
      signal.cancel();
    };
  }, []);

  const _findDefaultDatabaseId = () => findDefaultDatabaseId(state.databases);

  const _getCountries = (databaseId) => getCountries(state.databases, databaseId);

  const createDatabasePayload = (type, name, databaseId, divisionNumericCode, data) => {
    const payload = {
      id: createGuid(),
      type,
      databaseId: databaseId || _findDefaultDatabaseId(),
      divisionNumericCode,
      name,
      data: data.map((datum) => ({
        ...datum,
        divisionNumericCode,
      })),
    };

    return payload;
  };

  const createDatabase = (databasePayload) => ({
    type: databasePayload.type,
    name: databasePayload.name,
    divisions: {
      [databasePayload.divisionNumericCode]:
        state.databases[databasePayload.databaseId].divisions[databasePayload.divisionNumericCode],
    },
  });

  const onSelectSurveyDatabase = (databaseId) => {
    const countries = _getCountries(databaseId);

    setStateSafely({
      selectedSurveyDatabaseId: databaseId,
      selectedSurveyDataCountryNumericCode:
        Object.keys(countries).length !== 1 ? undefined : parseInt(Object.keys(countries)[0], 10),
    });
  };

  const onSelectSurveyDataCountry = (countryNumericCode) => {
    setStateSafely({
      selectedSurveyDataCountryNumericCode: countryNumericCode,
    });
  };

  const onLoadCountrySurveyData = () => {
    api
      .fetchSurveyDatabase(
        state.selectedSurveyDatabaseId,
        state.selectedSurveyDataCountryNumericCode,
        undefined,
        signal.token
      )
      .then((response) => {
        if (!response) {
          return;
        }

        setStateSafely({
          surveyData: normalizeSurveyData(response.data).map((datum) => ({
            ...datum,
            id: createGuid(),
            include: !datum.exclude,
          })),
        });
      });
  };

  const onAddNewSurveyItem = (item) => {
    setStateSafely({
      surveyData: [...(state.surveyData || []), item],
    });
  };

  const onEditSurveyItem = (item, updatedField, callback) => {
    const surveyData = [...state.surveyData];

    const itemIndex = findIndex(surveyData, "id", item.id);

    // noinspection JSValidateTypes
    surveyData[itemIndex] = synchonizeContraceptivePrevalence(item, updatedField);

    setStateSafely(
      {
        surveyData,
      },
      callback
    );
  };

  const onDeleteSurveyItem = (itemId) => {
    setStateSafely({
      surveyData: state.surveyData.filter((datum) => datum.id !== itemId),
    });
  };

  const onClearSurveyData = () => {
    setStateSafely({
      surveyData: undefined,
    });
  };

  const onChangeSurveyDatabaseName = (databaseName) => {
    setStateSafely({
      newSurveyDatabaseName: databaseName,
    });
  };

  const onSaveSurveyDatabase = () => {
    const databasePayload = createDatabasePayload(
      "survey",
      state.newSurveyDatabaseName,
      state.selectedSurveyDatabaseId,
      state.selectedSurveyDataCountryNumericCode,
      state.surveyData.map(({ include, ...rest }) => ({
        ...rest,
        possible_outlier: to01Null(rest.possible_outlier),
        possible_outlier_userinput: to01Null(rest.possible_outlier_userinput),
        hasGeographicalRegionBias: !!rest.geographicalRegionBiasReason,
        exclude: !include,
      }))
    );

    api.saveDatabase(databasePayload, signal.token).then((response) => {
      const { id } = response.data;

      const databases = {
        ...state.databases,
        [id]: createDatabase(databasePayload),
      };

      setStateSafely({
        databases,
        notificationVariant: "success",
        notificationOpen: true,
        notificationMessage: props.t('Database "{{databaseName}}" was saved', {
          databaseName: databasePayload.name,
        }),
      });
    });
  };

  const onSelectPopulationDatabase = (databaseId) => {
    const countries = _getCountries(databaseId);

    setStateSafely({
      selectedPopulationDatabaseId: databaseId,
      selectedPopulationDataCountryNumericCode:
        Object.keys(countries).length !== 1 ? undefined : parseInt(Object.keys(countries)[0], 10),
    });
  };

  const onSelectPopulationDataCountry = (countryNumericCode) => {
    setStateSafely({
      selectedPopulationDataCountryNumericCode: countryNumericCode,
    });
  };

  const onLoadCountryPopulationData = () => {
    api
      .fetchPopulationDatabase(
        state.selectedPopulationDatabaseId,
        state.selectedPopulationDataCountryNumericCode,
        signal.token
      )
      .then((response) => {
        if (!response) {
          return;
        }

        setStateSafely({
          populationData: normalizePopulationData(
            response.data.filter(
              (datum) =>
                datum.divisionNumericCode === state.selectedPopulationDataCountryNumericCode &&
                datum.ageRange === "15-49"
            )
          ).map((datum) => ({
            ...datum,
            id: createGuid(),
          })),
        });
      });
  };

  const onAddNewPopulationItem = (item) => {
    setStateSafely({
      populationData: [...(state.populationData || []), item],
    });
  };

  const onEditPopulationItem = (item) => {
    const populationData = [...state.populationData];

    const itemIndex = findIndex(populationData, "id", item.id);
    populationData[itemIndex] = item;

    setStateSafely({
      populationData,
    });
  };

  const onDeletePopulationItem = (itemId) => {
    setStateSafely({
      populationData: state.populationData.filter((datum) => datum.id !== itemId),
    });
  };

  const onClearPopulationData = () => {
    setStateSafely({
      populationData: undefined,
    });
  };

  const onChangePopulationDatabaseName = (databaseName) => {
    setStateSafely({
      newPopulationDatabaseName: databaseName,
    });
  };

  const onSavePopulationDatabase = () => {
    const databasePayload = createDatabasePayload(
      "population",
      state.newPopulationDatabaseName,
      state.selectedPopulationDatabaseId,
      state.selectedPopulationDataCountryNumericCode,
      state.populationData
    );

    api.saveDatabase(databasePayload, signal.token).then((response) => {
      const { id } = response.data;

      const databases = {
        ...state.databases,
        [id]: createDatabase(databasePayload),
      };

      setStateSafely({
        databases,
        notificationVariant: "success",
        notificationOpen: true,
        notificationMessage: props.t('Database "{{databaseName}}" was saved', {
          databaseName: databasePayload.name,
        }),
      });
    });
  };

  const onSelectEmuDatabase = (databaseId) => {
    const countries = _getCountries(databaseId || _findDefaultDatabaseId());

    setStateSafely({
      selectedEmuDatabaseId: databaseId,
      selectedEmuDataCountryNumericCode:
        Object.keys(countries).length !== 1 ? undefined : parseInt(Object.keys(countries)[0], 10),
    });
  };

  const onSelectEmuDataCountry = (countryNumericCode) => {
    setStateSafely({
      selectedEmuDataCountryNumericCode: countryNumericCode,
    });
  };

  const onLoadCountryEmuData = () => {
    api
      .fetchEmuDatabase(state.selectedEmuDatabaseId, state.selectedEmuDataCountryNumericCode, signal.token)
      .then((response) => {
        if (!response) {
          return;
        }

        setStateSafely({
          emuData: normalizeEmuData(
            response.data.filter((datum) => datum.divisionNumericCode === state.selectedEmuDataCountryNumericCode)
          ).map((datum) => ({
            ...datum,
            id: createGuid(),
          })),
        });
      });
  };

  const onAddNewEmuItem = (item) => {
    setStateSafely({
      emuData: [...(state.emuData || []), item],
    });
  };

  const onEditEmuItem = (item) => {
    const emuData = [...state.emuData];

    const itemIndex = findIndex(emuData, "id", item.id);
    emuData[itemIndex] = item;

    setStateSafely({
      emuData,
    });
  };

  const onDeleteEmuItem = (itemId) => {
    setStateSafely({
      emuData: state.emuData.filter((datum) => datum.id !== itemId),
    });
  };

  const onClearEmuData = () => {
    setStateSafely({
      emuData: undefined,
    });
  };

  const onChangeEmuDatabaseName = (databaseName) => {
    setStateSafely({
      newEmuDatabaseName: databaseName,
    });
  };

  const onSaveEmuDatabase = () => {
    const databasePayload = createDatabasePayload(
      "emu",
      state.newEmuDatabaseName,
      state.selectedEmuDatabaseId || _findDefaultDatabaseId(),
      state.selectedEmuDataCountryNumericCode,
      state.emuData.map(({ startYear, endYear, ...rest }) => ({
        ...rest,
        year: startYear,
      }))
    );

    api.saveDatabase(databasePayload, signal.token).then((response) => {
      const { id } = response.data;

      const databases = {
        ...state.databases,
        [id]: createDatabase(databasePayload),
      };

      setStateSafely({
        databases,
        notificationVariant: "success",
        notificationOpen: true,
        notificationMessage: props.t('Database "{{databaseName}}" was saved', {
          databaseName: databasePayload.name,
        }),
      });
    });
  };

  const onCloseNotification = () => {
    setStateSafely({
      notificationVariant: undefined,
      notificationOpen: false,
      notificationMessage: undefined,
    });
  };

  return (
    <PrepareData
      match={props.match}
      databases={state.databases}
      surveyDatabaseCountries={_getCountries(state.selectedSurveyDatabaseId)}
      selectedSurveyDatabaseId={state.selectedSurveyDatabaseId}
      selectedSurveyDataCountryNumericCode={state.selectedSurveyDataCountryNumericCode}
      surveyData={state.surveyData}
      newSurveyDatabaseName={state.newSurveyDatabaseName}
      populationDatabaseCountries={_getCountries(state.selectedPopulationDatabaseId)}
      selectedPopulationDatabaseId={state.selectedPopulationDatabaseId}
      selectedPopulationDataCountryNumericCode={state.selectedPopulationDataCountryNumericCode}
      populationData={state.populationData}
      newPopulationDatabaseName={state.newPopulationDatabaseName}
      emuDatabaseCountries={_getCountries(state.selectedEmuDatabaseId || _findDefaultDatabaseId())}
      selectedEmuDatabaseId={state.selectedEmuDatabaseId}
      selectedEmuDataCountryNumericCode={state.selectedEmuDataCountryNumericCode}
      emuData={state.emuData}
      newEmuDatabaseName={state.newEmuDatabaseName}
      notificationVariant={state.notificationVariant}
      notificationOpen={state.notificationOpen}
      notificationMessage={state.notificationMessage}
      onSelectSurveyDatabase={onSelectSurveyDatabase}
      onSelectSurveyDataCountry={onSelectSurveyDataCountry}
      onLoadCountrySurveyData={onLoadCountrySurveyData}
      onAddNewSurveyItem={onAddNewSurveyItem}
      onEditSurveyItem={onEditSurveyItem}
      onDeleteSurveyItem={onDeleteSurveyItem}
      onClearSurveyData={onClearSurveyData}
      onChangeSurveyDatabaseName={onChangeSurveyDatabaseName}
      onSaveSurveyDatabase={onSaveSurveyDatabase}
      onSelectPopulationDatabase={onSelectPopulationDatabase}
      onSelectPopulationDataCountry={onSelectPopulationDataCountry}
      onLoadCountryPopulationData={onLoadCountryPopulationData}
      onAddNewPopulationItem={onAddNewPopulationItem}
      onEditPopulationItem={onEditPopulationItem}
      onDeletePopulationItem={onDeletePopulationItem}
      onClearPopulationData={onClearPopulationData}
      onChangePopulationDatabaseName={onChangePopulationDatabaseName}
      onSavePopulationDatabase={onSavePopulationDatabase}
      onSelectEmuDatabase={onSelectEmuDatabase}
      onSelectEmuDataCountry={onSelectEmuDataCountry}
      onLoadCountryEmuData={onLoadCountryEmuData}
      onAddNewEmuItem={onAddNewEmuItem}
      onEditEmuItem={onEditEmuItem}
      onDeleteEmuItem={onDeleteEmuItem}
      onClearEmuData={onClearEmuData}
      onChangeEmuDatabaseName={onChangeEmuDatabaseName}
      onSaveEmuDatabase={onSaveEmuDatabase}
      onCloseNotification={onCloseNotification}
    />
  );
};

export default withTranslation()(withErrorBoundary(PrepareDataContainer));
