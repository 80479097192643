import React from "react";
import * as PropTypes from "prop-types";
import { contraceptiveMethods, indicators } from "../constants";
import { trimToRange, trimYearToRange } from "../utilities";
import * as api from "../api/api";
import Targets from "../components/Targets/Targets";

const createInitialState = () => ({
  indicatorProbabilityParameters: {
    indicator: indicators[0].value,
    maritalStatus: "married",
    direction: "greaterThan",
  },
  indicatorLevelParameters: {
    indicator: indicators[0].value,
    maritalStatus: "married",
    direction: "greaterThan",
  },
  populationProbabilityParameters: {
    delta: "total",
    maritalStatus: "married",
    contraceptiveMethod: contraceptiveMethods[0].value,
    direction: "greaterThan",
  },
  populationLevelParameters: {
    delta: "total",
    maritalStatus: "married",
    contraceptiveMethod: contraceptiveMethods[0].value,
    direction: "greaterThan",
  },
});

const createPayload = (state) => {
  const payload = {};

  if (state.indicatorProbabilityParameters.year && !isNaN(state.indicatorProbabilityParameters.level)) {
    payload.indicatorProbabilityParameters = {
      ...state.indicatorProbabilityParameters,
      level: state.indicatorProbabilityParameters.level / 100,
    };
  }

  if (state.indicatorLevelParameters.year && !isNaN(state.indicatorLevelParameters.probability)) {
    payload.indicatorLevelParameters = state.indicatorLevelParameters;
  }

  if (state.populationProbabilityParameters.year && !isNaN(state.populationProbabilityParameters.level)) {
    payload.populationProbabilityParameters = state.populationProbabilityParameters;
  }

  if (state.populationLevelParameters.year && !isNaN(state.populationLevelParameters.probability)) {
    payload.populationLevelParameters = state.populationLevelParameters;
  }

  return payload;
};

const TargetsContainer = (props) => {
  const [state, setState] = React.useState(createInitialState());

  const signal = api.getSignal();

  const setStateSafely = (newState) => {
    setState((prevState) => ({
      ...prevState,
      ...newState,
    }));
  };

  React.useEffect(() => {
    return () => {
      signal.cancel();
    };
  }, []);

  const onSelectIndicatorProbabilityIndicator = (indicator) => {
    setStateSafely({
      indicatorProbabilityParameters: {
        ...state.indicatorProbabilityParameters,
        indicator,
      },
    });
  };

  const onSelectIndicatorProbabilityMaritalStatus = (maritalStatus) => {
    setStateSafely({
      indicatorProbabilityParameters: {
        ...state.indicatorProbabilityParameters,
        maritalStatus,
      },
    });
  };

  const onChangeIndicatorProbabilityYear = (year, enforceRange) => {
    year = trimYearToRange(year, enforceRange);

    setStateSafely({
      indicatorProbabilityParameters: {
        ...state.indicatorProbabilityParameters,
        year,
      },
    });
  };

  const onSelectIndicatorProbabilityDirection = (direction) => {
    setStateSafely({
      indicatorProbabilityParameters: {
        ...state.indicatorProbabilityParameters,
        direction,
      },
    });
  };

  const onChangeIndicatorProbabilityLevel = (level) => {
    level = trimToRange(level, 0, 100);

    setStateSafely({
      indicatorProbabilityParameters: {
        ...state.indicatorProbabilityParameters,
        level,
      },
    });
  };

  const onSelectIndicatorLevelIndicator = (indicator) => {
    setStateSafely({
      indicatorLevelParameters: {
        ...state.indicatorLevelParameters,
        indicator,
      },
    });
  };

  const onSelectIndicatorLevelMaritalStatus = (maritalStatus) => {
    setStateSafely({
      indicatorLevelParameters: {
        ...state.indicatorLevelParameters,
        maritalStatus,
      },
    });
  };

  const onChangeIndicatorLevelYear = (year, enforceRange) => {
    year = trimYearToRange(year, enforceRange);

    setStateSafely({
      indicatorLevelParameters: {
        ...state.indicatorLevelParameters,
        year,
      },
    });
  };

  const onChangeIndicatorLevelProbability = (probability) => {
    if (!probability) {
      probability = "";
    } else if (!probability.match(/^(0?(\.\d*)?|1)$/)) {
      probability = state.indicatorLevelParameters.probability;
    }

    setStateSafely({
      indicatorLevelParameters: {
        ...state.indicatorLevelParameters,
        probability,
      },
    });
  };

  const onSelectIndicatorLevelDirection = (direction) => {
    setStateSafely({
      indicatorLevelParameters: {
        ...state.indicatorLevelParameters,
        direction,
      },
    });
  };

  const onSelectPopulationProbabilityDelta = (delta) => {
    setStateSafely({
      populationProbabilityParameters: {
        ...state.populationProbabilityParameters,
        delta,
      },
    });
  };

  const onSelectPopulationProbabilityMaritalStatus = (maritalStatus) => {
    setStateSafely({
      populationProbabilityParameters: {
        ...state.populationProbabilityParameters,
        maritalStatus,
      },
    });
  };

  const onChangePopulationProbabilityRelativeToYear = (year, enforceRange) => {
    year = trimYearToRange(year, enforceRange);

    setStateSafely({
      populationProbabilityParameters: {
        ...state.populationProbabilityParameters,
        relativeToYear: year,
      },
    });
  };

  const onSelectPopulationProbabilityContraceptiveMethod = (contraceptiveMethod) => {
    setStateSafely({
      populationProbabilityParameters: {
        ...state.populationProbabilityParameters,
        contraceptiveMethod,
      },
    });
  };

  const onChangePopulationProbabilityYear = (year, enforceRange) => {
    year = trimYearToRange(year, enforceRange);

    setStateSafely({
      populationProbabilityParameters: {
        ...state.populationProbabilityParameters,
        year,
      },
    });
  };

  const onSelectPopulationProbabilityDirection = (direction) => {
    setStateSafely({
      populationProbabilityParameters: {
        ...state.populationProbabilityParameters,
        direction,
      },
    });
  };

  const onChangePopulationProbabilityLevel = (level) => {
    level = trimToRange(level, 0, Number.MAX_VALUE);

    setStateSafely({
      populationProbabilityParameters: {
        ...state.populationProbabilityParameters,
        level,
      },
    });
  };

  const onSelectPopulationLevelDelta = (delta) => {
    setStateSafely({
      populationLevelParameters: {
        ...state.populationLevelParameters,
        delta,
      },
    });
  };

  const onSelectPopulationLevelMaritalStatus = (maritalStatus) => {
    setStateSafely({
      populationLevelParameters: {
        ...state.populationLevelParameters,
        maritalStatus,
      },
    });
  };

  const onChangePopulationLevelRelativeToYear = (year, enforceRange) => {
    year = trimYearToRange(year, enforceRange);

    setStateSafely({
      populationLevelParameters: {
        ...state.populationLevelParameters,
        relativeToYear: year,
      },
    });
  };

  const onSelectPopulationLevelContraceptiveMethod = (contraceptiveMethod) => {
    setStateSafely({
      populationLevelParameters: {
        ...state.populationLevelParameters,
        contraceptiveMethod,
      },
    });
  };

  const onChangePopulationLevelYear = (year, enforceRange) => {
    year = trimYearToRange(year, enforceRange);

    setStateSafely({
      populationLevelParameters: {
        ...state.populationLevelParameters,
        year,
      },
    });
  };

  const onChangePopulationLevelProbability = (probability) => {
    if (!probability) {
      probability = "";
    } else if (!probability.match(/^(0?(\.\d*)?|1)$/)) {
      probability = state.populationLevelParameters.probability;
    }

    setStateSafely({
      populationLevelParameters: {
        ...state.populationLevelParameters,
        probability,
      },
    });
  };

  const onSelectPopulationLevelDirection = (direction) => {
    setStateSafely({
      populationLevelParameters: {
        ...state.populationLevelParameters,
        direction,
      },
    });
  };

  const onCalculate = () => {
    setStateSafely({
      calculating: true,
    });

    const countryNumericCodeOrRegion = props.countryNumericCode || props.region;

    api
      .calculateTargets(props.runId, countryNumericCodeOrRegion, createPayload(state), signal.token)
      .then((response) => {
        if (!response) {
          return;
        }

        setStateSafely({
          ...response.data,
        });
      })
      .finally(() => {
        setStateSafely({
          calculating: false,
        });
      });
  };

  return (
    <Targets
      indicators={indicators}
      contraceptiveMethods={contraceptiveMethods}
      indicatorProbabilityParameters={state.indicatorProbabilityParameters}
      indicatorProbability={state.indicatorProbability}
      indicatorLevelParameters={state.indicatorLevelParameters}
      indicatorLevel={state.indicatorLevel}
      populationProbabilityParameters={state.populationProbabilityParameters}
      populationProbability={state.populationProbability}
      populationLevelParameters={state.populationLevelParameters}
      populationLevel={state.populationLevel}
      valid={(!!props.countryNumericCode || !!props.region) && Object.keys(createPayload(state)).length > 0}
      calculating={state.calculating}
      onSelectIndicatorProbabilityIndicator={onSelectIndicatorProbabilityIndicator}
      onSelectIndicatorProbabilityMaritalStatus={onSelectIndicatorProbabilityMaritalStatus}
      onChangeIndicatorProbabilityYear={onChangeIndicatorProbabilityYear}
      onSelectIndicatorProbabilityDirection={onSelectIndicatorProbabilityDirection}
      onChangeIndicatorProbabilityLevel={onChangeIndicatorProbabilityLevel}
      onSelectIndicatorLevelIndicator={onSelectIndicatorLevelIndicator}
      onSelectIndicatorLevelMaritalStatus={onSelectIndicatorLevelMaritalStatus}
      onChangeIndicatorLevelYear={onChangeIndicatorLevelYear}
      onChangeIndicatorLevelProbability={onChangeIndicatorLevelProbability}
      onSelectIndicatorLevelDirection={onSelectIndicatorLevelDirection}
      onSelectPopulationProbabilityDelta={onSelectPopulationProbabilityDelta}
      onSelectPopulationProbabilityMaritalStatus={onSelectPopulationProbabilityMaritalStatus}
      onChangePopulationProbabilityRelativeToYear={onChangePopulationProbabilityRelativeToYear}
      onSelectPopulationProbabilityContraceptiveMethod={onSelectPopulationProbabilityContraceptiveMethod}
      onChangePopulationProbabilityYear={onChangePopulationProbabilityYear}
      onSelectPopulationProbabilityDirection={onSelectPopulationProbabilityDirection}
      onChangePopulationProbabilityLevel={onChangePopulationProbabilityLevel}
      onSelectPopulationLevelDelta={onSelectPopulationLevelDelta}
      onSelectPopulationLevelMaritalStatus={onSelectPopulationLevelMaritalStatus}
      onChangePopulationLevelRelativeToYear={onChangePopulationLevelRelativeToYear}
      onSelectPopulationLevelContraceptiveMethod={onSelectPopulationLevelContraceptiveMethod}
      onChangePopulationLevelYear={onChangePopulationLevelYear}
      onChangePopulationLevelProbability={onChangePopulationLevelProbability}
      onSelectPopulationLevelDirection={onSelectPopulationLevelDirection}
      onCalculate={onCalculate}
    />
  );
};

TargetsContainer.propTypes = {
  runId: PropTypes.string,
  countryNumericCode: PropTypes.number,
  region: PropTypes.string,
};

export default TargetsContainer;
