import React from "react";
import * as PropTypes from "prop-types";
import Button from "@mui/material/Button";
import CircularProgress from "@mui/material/CircularProgress";

const styles = {
  button: {
    width: "100%",
  },
  container: {
    position: "relative",
  },
  progress: {
    position: "absolute",
    top: "50%",
    left: "50%",
    marginTop: "-12px",
    marginLeft: "-12px",
  },
};

const ProgressButton = (props) => (
  <div style={styles.container} className={props.className}>
    <Button
      variant={props.variant}
      color={props.color}
      disabled={props.disabled || props.loading}
      className={props.buttonClassName}
      sx={styles.button}
      onClick={props.onClick}
    >
      {props.children}
    </Button>

    {props.loading && <CircularProgress size={24} sx={styles.progress} />}
  </div>
);

ProgressButton.propTypes = {
  variant: PropTypes.string,
  color: PropTypes.string,
  disabled: PropTypes.bool,
  className: PropTypes.string,
  buttonClassName: PropTypes.string,
  loading: PropTypes.bool,
  onClick: PropTypes.func.isRequired,
};

ProgressButton.defaultProps = {
  variant: "contained",
  color: "primary",
  disabled: false,
  loading: false,
};

export default ProgressButton;
