import React from "react";
import { defaultFirstYear, minimumYear, maximumYear } from "../constants";
import { getDefaultLastYear } from "../utilities";
import * as api from "../api/api";
import {
  filterPopulationData,
  filterSurveyData,
  normalizeDatabases,
  normalizeEmuData,
  normalizePopulationData,
  normalizeSurveyData,
} from "../data";
import { withErrorBoundary } from "../errors";
import { withTranslation } from "react-i18next";
import StartRun from "../pages/StartRun";

const createInitialState = () => {
  return {
    emuDatabaseType: "custom",
    period: {
      firstYear: defaultFirstYear,
      lastYear: getDefaultLastYear(),
    },
  };
};

const removeFileExtension = (filename) => filename.replace(/\.[^/.]+$/, "");

const getDatabaseName = (file) => file && removeFileExtension(file.name);

const StartRunContainer = (props) => {
  const [state, setState] = React.useState(createInitialState());

  const signal = api.getSignal();

  const setStateSafely = (newState) => {
    setState((prevState) => ({
      ...prevState,
      ...newState,
    }));
  };

  const uploadDatabase = (file, databaseType, onProgress) => {
    const data = new FormData();

    data.append("file", file, file.name);

    return api.uploadDatabase(data, databaseType, onProgress, signal.token);
  };

  const getCountryIntersection = (surveyDatabaseId, populationDatabaseId, emuDatabaseId) => {
    if (!surveyDatabaseId || !populationDatabaseId) {
      return {};
    }

    const surveyDatabase = state.databases[surveyDatabaseId];
    const populationDatabase = state.databases[populationDatabaseId];
    const emuDatabase = emuDatabaseId && state.databases[emuDatabaseId];

    return Object.keys(surveyDatabase.divisions).reduce((result, divisionNumericCode) => {
      const division = surveyDatabase.divisions[divisionNumericCode];

      if (
        division.isCountry &&
        populationDatabase.divisions[divisionNumericCode] &&
        (!emuDatabase || emuDatabase.divisions[divisionNumericCode])
      ) {
        result[divisionNumericCode] = division;
      }

      return result;
    }, {});
  };

  const getRegionIntersection = (surveyDatabaseId, populationDatabaseId, emuDatabaseId) => {
    if (!surveyDatabaseId || !populationDatabaseId) {
      return [];
    }

    const surveyRegions = state.databases[surveyDatabaseId].regionCodes || [];

    const populationRegionSet = new Set(state.databases[populationDatabaseId].regionCodes || []);

    const emuRegionSet = emuDatabaseId && new Set(state.databases[emuDatabaseId].regionCodes || []);

    return surveyRegions.reduce((result, region) => {
      if (populationRegionSet.has(region) && (!emuRegionSet || emuRegionSet.has(region))) {
        result.push(region);
      }

      return result;
    }, []);
  };

  const selectDefaultCountryOrRegion = (surveyDatabaseId, populationDatabaseId, emuDatabaseId) => {
    const countries = getCountryIntersection(surveyDatabaseId, populationDatabaseId, emuDatabaseId);

    const countryNumericCodes = Object.keys(countries);

    const regions = getRegionIntersection(surveyDatabaseId, populationDatabaseId, emuDatabaseId);

    if (countryNumericCodes.length + regions.length === 1) {
      if (countryNumericCodes.length) {
        setStateSafely({
          selectedCountryNumericCode: parseInt(countryNumericCodes[0], 10),
        });
      } else {
        setStateSafely({
          selectedRegion: regions[0],
        });
      }
    }
  };

  const createRunTitle = () => {
    let countryAlphaCodeOrRegion;

    if (state.selectedRegion) {
      countryAlphaCodeOrRegion = state.selectedRegion;
    } else if (state.selectedCountryNumericCode) {
      countryAlphaCodeOrRegion =
        state.databases[state.selectedSurveyDatabaseId].divisions[state.selectedCountryNumericCode].alphaCode;
    }

    if (countryAlphaCodeOrRegion) {
      const { firstYear, lastYear } = state.period;

      return props.t("Defining run for {{countryAlphaCodeOrRegion}} during {{firstYear}}-{{lastYear}}", {
        countryAlphaCodeOrRegion,
        firstYear,
        lastYear,
      });
    }
  };

  const showNotification = (variant, message) => {
    setStateSafely({
      notificationVariant: variant,
      notificationOpen: true,
      notificationMessage: message,
    });
  };

  const onChangeSurveyDatabaseType = (type) => {
    setStateSafely({
      surveyDatabaseType: type,
      selectedSurveyDatabaseId: undefined,
      selectedCountryNumericCode: undefined,
      selectedRegion: undefined,
    });
  };

  const onSelectSurveyDatabase = (databaseId) => {
    setStateSafely({
      selectedSurveyDatabaseId: databaseId,
      selectedCountryNumericCode: undefined,
      selectedRegion: undefined,
    });

    selectDefaultCountryOrRegion(databaseId, state.selectedPopulationDatabaseId, state.selectedEmuDatabaseId);
  };

  const onSelectSurveyDatabaseFile = (file) => {
    setStateSafely({
      uploadedSurveyDatabaseFile: file,
    });
  };

  const onUploadSurveyDatabase = () => {
    const file = state.uploadedSurveyDatabaseFile;

    setStateSafely({
      uploadingSurveyDatabase: true,
    });

    uploadDatabase(file, "survey", onProgressSurveyDatabaseUpload)
      .then((uploadDatabaseResponse) => {
        return api.fetchDatabases(signal.token).then((fetchDatabasesResponse) => {
          const database = uploadDatabaseResponse.data;

          setStateSafely({
            databases: normalizeDatabases(fetchDatabasesResponse.data),
            selectedSurveyDatabaseId: database.id,
            surveyDatabaseType: "custom",
          });
        });
      })
      .catch((error) => {
        const filename = state.uploadedSurveyDatabaseFile.name;

        showNotification("error", `Failed to upload "${filename}"`);

        throw error;
      })
      .finally(() => {
        setStateSafely({
          uploadedSurveyDatabaseFile: undefined,
          uploadingSurveyDatabase: false,
        });
      });
  };

  const onProgressSurveyDatabaseUpload = (progressEvent) => {
    setStateSafely({
      surveyDatabaseUploadProgress: (progressEvent.loaded / progressEvent.total) * 100,
    });
  };

  const onCancelSurveyDatabaseUpload = () => {
    setStateSafely({
      uploadedSurveyDatabaseFile: undefined,
    });
  };

  const onChangePopulationDatabaseType = (type) => {
    setStateSafely({
      populationDatabaseType: type,
      selectedPopulationDatabaseId: undefined,
      selectedCountryNumericCode: undefined,
      selectedRegion: undefined,
    });
  };

  const onSelectPopulationDatabase = (databaseId) => {
    setStateSafely({
      selectedPopulationDatabaseId: databaseId,
      selectedCountryNumericCode: undefined,
      selectedRegion: undefined,
    });

    selectDefaultCountryOrRegion(state.selectedSurveyDatabaseId, databaseId, state.selectedEmuDatabaseId);
  };

  const onSelectPopulationDatabaseFile = (file) => {
    setStateSafely({
      uploadedPopulationDatabaseFile: file,
    });
  };

  const onUploadPopulationDatabase = () => {
    const file = state.uploadedPopulationDatabaseFile;

    setStateSafely({
      uploadingPopulationDatabase: true,
    });

    uploadDatabase(file, "population", onProgressPopulationDatabaseUpload)
      .then((uploadDatabaseResponse) => {
        return api.fetchDatabases(signal.token).then((fetchDatabasesResponse) => {
          const database = uploadDatabaseResponse.data;

          setStateSafely({
            databases: normalizeDatabases(fetchDatabasesResponse.data),
            selectedPopulationDatabaseId: database.id,
            populationDatabaseType: "custom",
          });
        });
      })
      .catch((error) => {
        const filename = state.uploadedPopulationDatabaseFile.name;

        showNotification("error", `Failed to upload "${filename}"`);

        throw error;
      })
      .finally(() => {
        setStateSafely({
          uploadedPopulationDatabaseFile: undefined,
          uploadingPopulationDatabase: false,
        });
      });
  };

  const onProgressPopulationDatabaseUpload = (progressEvent) => {
    setStateSafely({
      populationDatabaseUploadProgress: (progressEvent.loaded / progressEvent.total) * 100,
    });
  };

  const onCancelPopulationDatabaseUpload = () => {
    setStateSafely({
      uploadedPopulationDatabaseFile: undefined,
    });
  };

  const onChangeEmuDatabaseType = (type) => {
    setStateSafely({
      emuDatabaseType: type,
      selectedEmuDatabaseId: undefined,
      selectedCountryNumericCode: undefined,
      selectedRegion: undefined,
    });
  };

  const onSelectEmuDatabase = (databaseId) => {
    setStateSafely({
      selectedEmuDatabaseId: databaseId,
      selectedCountryNumericCode: undefined,
      selectedRegion: undefined,
    });

    selectDefaultCountryOrRegion(state.selectedSurveyDatabaseId, state.selectedPopulationDatabaseId, databaseId);
  };

  const onSelectEmuDatabaseFile = (file) => {
    setStateSafely({
      uploadedEmuDatabaseFile: file,
    });
  };

  const onUploadEmuDatabase = () => {
    const file = state.uploadedEmuDatabaseFile;

    setStateSafely({
      uploadingEmuDatabase: true,
    });

    uploadDatabase(file, "emu", onProgressEmuDatabaseUpload)
      .then((uploadDatabaseResponse) => {
        return api.fetchDatabases(signal.token).then((fetchDatabasesResponse) => {
          const database = uploadDatabaseResponse.data;

          setStateSafely({
            databases: normalizeDatabases(fetchDatabasesResponse.data),
            selectedEmuDatabaseId: database.id,
            emuDatabaseType: "custom",
          });
        });
      })
      .catch((error) => {
        const filename = state.uploadedEmuDatabaseFile.name;

        showNotification("error", `Failed to upload "${filename}"`);

        throw error;
      })
      .finally(() => {
        setStateSafely({
          uploadedEmuDatabaseFile: undefined,
          uploadingEmuDatabase: false,
        });
      });
  };

  const onProgressEmuDatabaseUpload = (progressEvent) => {
    setStateSafely({
      emuDatabaseUploadProgress: (progressEvent.loaded / progressEvent.total) * 100,
    });
  };

  const onCancelEmuDatabaseUpload = () => {
    setStateSafely({
      uploadedEmuDatabaseFile: undefined,
    });
  };

  const onSelectCountry = (countryNumericCode) => {
    setStateSafely({
      selectedCountryNumericCode: countryNumericCode,
      selectedRegion: undefined,
    });
  };

  const onSelectRegion = (region) => {
    setStateSafely({
      selectedRegion: region,
    });
  };

  const onSelectNation = (nation) => {
    setStateSafely({
      selectedNation: nation,
    });
  };

  const onRunNameChanged = (runName) => {
    setStateSafely({
      runName,
    });
  };

  const onChangePeriod = (period) => {
    setStateSafely({
      period,
    });
  };

  const onReviewData = () => {
    const countryNumericCodeOrRegion = state.selectedRegion || state.selectedCountryNumericCode.toString();

    setStateSafely({
      loadingTables: true,
    });

    // noinspection JSCheckFunctionSignatures
    Promise.all([
      api.fetchSurveyDatabase(state.selectedSurveyDatabaseId, countryNumericCodeOrRegion, undefined, signal.token),
      api.fetchPopulationDatabase(state.selectedPopulationDatabaseId, countryNumericCodeOrRegion, signal.token),
      state.selectedEmuDatabaseId
        ? api.fetchEmuDatabase(state.selectedEmuDatabaseId, countryNumericCodeOrRegion, signal.token)
        : new Promise((resolve) =>
            resolve({
              data: [],
            })
          ),
    ])
      .then((response) => {
        if (!response || response.some((item) => !item)) {
          return;
        }

        const [surveyData, populationData, emuData] = response;

        setStateSafely({
          surveyData: normalizeSurveyData(surveyData.data),
          populationData: normalizePopulationData(populationData.data),
          emuData: normalizeEmuData(emuData.data),
        });
      })
      .finally(() => {
        setStateSafely({
          loadingTables: false,
        });
      });
  };

  const onStartRun = () => {
    api
      .startRun(
        state.runName,
        state.selectedSurveyDatabaseId,
        state.selectedPopulationDatabaseId,
        state.selectedEmuDatabaseId || undefined,
        state.selectedCountryNumericCode,
        state.selectedRegion,
        state.period
      )
      .then(() => {
        props.history.push("/history");
      });
  };

  const onCloseNotification = () => {
    setStateSafely({
      notificationOpen: false,
    });
  };

  React.useEffect(() => {
    setStateSafely({
      loadingData: true,
    });

    api
      .fetchDatabases(signal.token)
      .then((response) => {
        if (!response) {
          return;
        }

        setStateSafely({
          databases: normalizeDatabases(response.data),
        });
      })
      .finally(() => {
        setStateSafely({
          loadingData: false,
        });
      });

    return () => {
      signal.cancel();
    };
  }, []);

  const countries = getCountryIntersection(
    state.selectedSurveyDatabaseId,
    state.selectedPopulationDatabaseId,
    state.selectedEmuDatabaseId
  );

  const regions = getRegionIntersection(
    state.selectedSurveyDatabaseId,
    state.selectedPopulationDatabaseId,
    state.selectedEmuDatabaseId
  );

  const { firstYear, lastYear } = {
    firstYear: minimumYear,
    lastYear: maximumYear,
  };

  const surveyData = filterSurveyData(state.surveyData, firstYear, lastYear);

  const populationData = filterPopulationData(state.populationData, firstYear, lastYear);

  return (
    <StartRun
      match={props.match}
      loadingData={state.loadingData}
      databases={state.databases}
      surveyDatabaseType={state.surveyDatabaseType}
      selectedSurveyDatabaseId={state.selectedSurveyDatabaseId}
      uploadedSurveyDatabaseName={getDatabaseName(state.uploadedSurveyDatabaseFile)}
      uploadingSurveyDatabase={state.uploadingSurveyDatabase}
      surveyDatabaseUploadProgress={state.surveyDatabaseUploadProgress}
      populationDatabaseType={state.populationDatabaseType}
      selectedPopulationDatabaseId={state.selectedPopulationDatabaseId}
      uploadedPopulationDatabaseName={getDatabaseName(state.uploadedPopulationDatabaseFile)}
      uploadingPopulationDatabase={state.uploadingPopulationDatabase}
      populationDatabaseUploadProgress={state.populationDatabaseUploadProgress}
      emuDatabaseType={state.emuDatabaseType}
      selectedEmuDatabaseId={state.selectedEmuDatabaseId}
      uploadedEmuDatabaseName={getDatabaseName(state.uploadedEmuDatabaseFile)}
      uploadingEmuDatabase={state.uploadingEmuDatabase}
      emuDatabaseUploadProgress={state.emuDatabaseUploadProgress}
      countries={countries}
      selectedCountryNumericCode={state.selectedCountryNumericCode}
      regions={regions}
      selectedRegion={state.selectedRegion}
      selectedNation={state.selectedNation}
      runName={state.runName}
      period={state.period}
      runTitle={createRunTitle()}
      loadingTables={state.loadingTables}
      surveyData={surveyData}
      populationData={populationData}
      emuData={state.emuData}
      notificationVariant={state.notificationVariant}
      notificationOpen={state.notificationOpen}
      notificationMessage={state.notificationMessage}
      onChangeSurveyDatabaseType={onChangeSurveyDatabaseType}
      onSelectSurveyDatabase={onSelectSurveyDatabase}
      onSelectSurveyDatabaseFile={onSelectSurveyDatabaseFile}
      onUploadSurveyDatabase={onUploadSurveyDatabase}
      onCancelSurveyDatabaseUpload={onCancelSurveyDatabaseUpload}
      onChangePopulationDatabaseType={onChangePopulationDatabaseType}
      onSelectPopulationDatabase={onSelectPopulationDatabase}
      onSelectPopulationDatabaseFile={onSelectPopulationDatabaseFile}
      onUploadPopulationDatabase={onUploadPopulationDatabase}
      onCancelPopulationDatabaseUpload={onCancelPopulationDatabaseUpload}
      onChangeEmuDatabaseType={onChangeEmuDatabaseType}
      onSelectEmuDatabase={onSelectEmuDatabase}
      onSelectEmuDatabaseFile={onSelectEmuDatabaseFile}
      onUploadEmuDatabase={onUploadEmuDatabase}
      onCancelEmuDatabaseUpload={onCancelEmuDatabaseUpload}
      onSelectCountry={onSelectCountry}
      onSelectRegion={onSelectRegion}
      onSelectNation={onSelectNation}
      onRunNameChanged={onRunNameChanged}
      onChangePeriod={onChangePeriod}
      onReviewData={onReviewData}
      onStartRun={onStartRun}
      onCloseNotification={onCloseNotification}
    />
  );
};

export default withTranslation()(withErrorBoundary(StartRunContainer));
