import React from "react";
import * as queryString from "query-string";
import { defaultFirstYear, indicators, maximumYear, minimumYear } from "../constants";
import { createSlug, getDefaultLastYear, toggleSetExistence } from "../utilities";
import {
  convertMaritalStatusToIsInUnion,
  filterPopulationData,
  filterResults,
  filterSurveyData,
  getPeriod,
  normalizeChartSets,
  normalizeDatabases,
  normalizeEmuData,
  normalizePopulationData,
  normalizeResults,
  normalizeRuns,
  normalizeSurveyData,
} from "../data";
import * as api from "../api/api";
import fileDownload from "js-file-download";
import { withErrorBoundary } from "../errors";
import { withTranslation } from "react-i18next";
import ViewRun from "../pages/ViewRun";

const createInitialState = () => ({
  period: {
    firstYear: defaultFirstYear,
    lastYear: getDefaultLastYear(),
  },
  selectedResultsMeasure: "percentage",
  selectedResultsIndicator: indicators[0].value,
  selectedResultsMaritalStatus: "married",
  selectedExportedResultsMeasures: new Set(),
  selectedExportedResultsIndicators: new Set(),
  selectedChartsMeasure: "percentage",
  selectedChartsMaritalStatus: "married",
  selectedChartsIndicators: new Set(
    indicators.filter((indicator) => indicator.chart).map((indicator) => indicator.value)
  ),
  showSurveyLegend: true,
  chartSets: [
    {
      value: "complete",
      label: "Complete",
    },
  ],
  selectedExportedChartSet: "complete",
  selectedExportedChartsMeasure: "percentage",
  selectedExportedChartsMaritalStatus: "married",
});

const ViewRunContainer = (props) => {
  const [state, setState] = React.useState(createInitialState());

  const signal = api.getSignal();

  const setStateSafely = (newState) => {
    setState((prevState) => ({
      ...prevState,
      ...newState,
    }));
  };

  React.useEffect(() => {
    setStateSafely({
      loadingData: true,
    });

    // noinspection JSCheckFunctionSignatures
    Promise.all([
      api.fetchDatabases(signal.token),
      api.fetchRuns(false, false, signal.token),
      api.fetchChartSets(signal.token),
    ])
      .then((response) => {
        if (!response || response.some((item) => !item)) {
          return;
        }

        const databases = normalizeDatabases(response[0].data);
        const runs = normalizeRuns(response[1].data);
        const chartSets = normalizeChartSets(response[2].data);

        let { run: selectedRunId } = queryString.parse(props.location.search);

        if (selectedRunId && !runs[selectedRunId]) {
          console.log(`Run ${selectedRunId} does not exist`);

          selectedRunId = undefined;
        }

        const selectedRegion =
          (selectedRunId &&
            runs[selectedRunId] &&
            runs[selectedRunId].regionCodes.length &&
            runs[selectedRunId].regionCodes[0]) ||
          undefined;

        const selectedCountryNumericCode =
          (!selectedRegion &&
            selectedRunId &&
            runs[selectedRunId] &&
            runs[selectedRunId].countryNumericCodes.length &&
            runs[selectedRunId].countryNumericCodes[0]) ||
          undefined;

        setStateSafely(
          {
            databases: databases,
            runs: runs,
            selectedRunId,
            selectedCountryNumericCode,
            selectedRegion,
            chartSets,
          },
          selectedRunId
            ? () => {
                viewRun();
              }
            : undefined
        );
      })
      .finally(() => {
        setStateSafely({
          loadingData: false,
        });
      });

    return () => {
      signal.cancel();
    };
  }, []);

  const getCountries = (runId) => {
    if (!runId) {
      return {};
    }

    const run = state.runs[runId];

    const divisions = state.databases[run.surveyDatabaseId].divisions;

    return run.countryNumericCodes.reduce((result, countryNumericCode) => {
      if (divisions[countryNumericCode] && divisions[countryNumericCode].isCountry) {
        result[countryNumericCode] = {
          numericCode: countryNumericCode,
          ...divisions[countryNumericCode],
        };
      }

      return result;
    }, {});
  };

  const getRegions = (runId) => {
    if (!runId) {
      return [];
    }

    const run = state.runs[runId];

    return run.regionCodes || [];
  };

  const selectDefaultCountryOrRegion = (runId) => {
    const countryNumericCodes = Object.keys(getCountries(runId));
    const regions = getRegions(runId);

    if (countryNumericCodes.length + regions.length === 1) {
      if (countryNumericCodes.length) {
        setStateSafely({
          selectedCountryNumericCode: parseInt(countryNumericCodes[0], 10),
        });
      } else {
        setStateSafely({
          selectedRegion: regions[0],
        });
      }
    }
  };

  const getSelectedCountryNumericCodeOrRegion = () => state.selectedRegion || state.selectedCountryNumericCode;

  const createRunTitle = () => {
    let countryAlphaCodeOrRegion;

    if (state.selectedRegion) {
      countryAlphaCodeOrRegion = state.selectedRegion;
    } else if (state.selectedCountryNumericCode) {
      const selectedRun = state.runs[state.selectedRunId];

      countryAlphaCodeOrRegion =
        state.databases[selectedRun.surveyDatabaseId].divisions[state.selectedCountryNumericCode].alphaCode;
    }

    if (countryAlphaCodeOrRegion) {
      const selectedRun = state.runs[state.selectedRunId];

      const { firstYear, lastYear } = state.period;

      return props.t("Run {{name}} for {{countryAlphaCodeOrRegion}} during {{firstYear}}-{{lastYear}}", {
        name: selectedRun.name,
        countryAlphaCodeOrRegion,
        firstYear,
        lastYear,
      });
    }
  };

  const viewRun = () => {
    setStateSafely({
      loadingRun: true,
    });

    const run = state.runs[state.selectedRunId];

    const countryNumericCodeOrRegion = state.selectedRegion || state.selectedCountryNumericCode;

    // noinspection JSCheckFunctionSignatures
    Promise.all([
      api.fetchSurveyDatabase(run.surveyDatabaseId, countryNumericCodeOrRegion, state.selectedRunId, signal.token),
      api.fetchPopulationDatabase(run.populationDatabaseId, countryNumericCodeOrRegion, signal.token),
      api.fetchResults(state.selectedRunId, undefined, countryNumericCodeOrRegion, signal.token),
      run.emuDatabaseId
        ? api.fetchEmuDatabase(run.emuDatabaseId, countryNumericCodeOrRegion, signal.token)
        : new Promise((resolve) =>
            resolve({
              data: [],
            })
          ),
    ])
      .then((response) => {
        console.log("Fetched survey");

        if (!response || response.some((item) => !item)) {
          return;
        }

        const [surveyData, populationData, results, emuData] = response;

        const period = getPeriod(results.data.proportions);

        setStateSafely({
          period,
          surveyData: normalizeSurveyData(surveyData.data),
          populationData: normalizePopulationData(populationData.data),
          emuData: normalizeEmuData(emuData.data),
          results: {
            proportions: normalizeResults(results.data.proportions),
            populations: normalizeResults(results.data.populations),
          },
        });
      })
      .finally(() => {
        setStateSafely({
          loadingRun: false,
        });
      });
  };

  const getMeasureResultsForPeriod = (measure) => {
    if (!state.results) {
      return;
    }

    const measureKey = measure === "percentage" ? "proportions" : "populations";

    return filterResults(state.results[measureKey], state.period.firstYear, state.period.lastYear);
  };

  const getTableResults = () => {
    const results = getMeasureResultsForPeriod(state.selectedResultsMeasure);

    if (!results) {
      return results;
    }

    const isInUnion = convertMaritalStatusToIsInUnion(state.selectedResultsMaritalStatus);

    return results[state.selectedResultsIndicator].filter((datum) => datum.isInUnion === isInUnion);
  };

  const getChartSurveyData = (surveyData) => {
    if (!surveyData) {
      return;
    }

    const isInUnion = convertMaritalStatusToIsInUnion(state.selectedChartsMaritalStatus);

    return surveyData.filter((datum) => datum.isInUnion === isInUnion);
  };

  const getChartResults = () => {
    const measureResultsForPeriod = getMeasureResultsForPeriod(state.selectedChartsMeasure);

    if (!measureResultsForPeriod) {
      return;
    }

    const isInUnion = convertMaritalStatusToIsInUnion(state.selectedChartsMaritalStatus);

    const results = Object.keys(measureResultsForPeriod).reduce((result, indicator) => {
      if (state.selectedChartsIndicators.has(indicator)) {
        result[indicator] = measureResultsForPeriod[indicator].filter((datum) => datum.isInUnion === isInUnion);
      }

      return result;
    }, {});

    if (!Object.keys(results).length || !results[Object.keys(results)[0]].length) {
      return;
    }

    return results;
  };

  const onSelectRun = (runId) => {
    setStateSafely({
      selectedRunId: runId,
      selectedCountryNumericCode: undefined,
      selectedRegion: undefined,
    });

    selectDefaultCountryOrRegion(runId);
  };

  const onSelectPopulation = (population) => {
    setStateSafely({
      selectedPopulation: population,
    });
  };

  const onSelectCountry = (countryNumericCode) => {
    setStateSafely({
      selectedCountryNumericCode: countryNumericCode,
      selectedRegion: undefined,
    });
  };

  const onSelectRegion = (region) => {
    setStateSafely({
      selectedRegion: region,
    });
  };

  const onChangePeriod = (period) => {
    setStateSafely({
      period,
    });
  };

  const onViewRun = () => {
    viewRun();
  };

  const onChangeResultsMeasure = (measure) => {
    setStateSafely({
      selectedResultsMeasure: measure,
      selectedResultsIndicator:
        measure === "percentage" || state.selectedResultsIndicator !== "ratioModernAny"
          ? state.selectedResultsIndicator
          : indicators[0].value,
    });
  };

  const onChangeResultsIndicator = (indicator) => {
    setStateSafely({
      selectedResultsIndicator: indicator,
    });
  };

  const onChangeResultsMaritalStatus = (martialStatus) => {
    setStateSafely({
      selectedResultsMaritalStatus: martialStatus,
    });
  };

  const onToggleExportedResultsMeasure = (measure) => {
    const selectedExportedResultsMeasures = new Set(state.selectedExportedResultsMeasures);

    toggleSetExistence(selectedExportedResultsMeasures, measure);

    setStateSafely({
      selectedExportedResultsMeasures,
    });
  };

  const onToggleExportedResultsIndicator = (indicator) => {
    const selectedExportedResultsIndicators = new Set(state.selectedExportedResultsIndicators);

    toggleSetExistence(selectedExportedResultsIndicators, indicator);

    setStateSafely({
      selectedExportedResultsIndicators,
    });
  };

  const onDownloadSelectedResults = () => {
    api
      .downloadResults(
        state.selectedRunId,
        undefined,
        getSelectedCountryNumericCodeOrRegion(),
        Array.from(state.selectedExportedResultsMeasures),
        Array.from(state.selectedExportedResultsIndicators),
        signal.token
      )
      .then((response) => {
        if (!response) {
          return;
        }

        const run = state.runs[state.selectedRunId];

        if (run) {
          fileDownload(response.data, createSlug(run.name, "results.csv"));
        }
      });
  };

  const onDownloadAllResults = () => {
    api
      .downloadResults(
        state.selectedRunId,
        undefined,
        getSelectedCountryNumericCodeOrRegion(),
        ["percentage", "count"],
        indicators.map((indicator) => indicator.value),
        signal.token
      )
      .then((response) => {
        if (!response) {
          return;
        }

        const run = state.runs[state.selectedRunId];

        if (run) {
          fileDownload(response.data, createSlug(run.name, "all_results.csv"));
        }
      });
  };

  const onDownloadAllData = () => {
    api.downloadData(state.selectedRunId, getSelectedCountryNumericCodeOrRegion(), signal.token).then((response) => {
      if (!response) {
        return;
      }

      const run = state.runs[state.selectedRunId];

      if (run) {
        fileDownload(response.data, createSlug(run.name, "all_data.zip"));
      }
    });
  };

  const onChangeChartsMeasure = (measure) => {
    setStateSafely({
      selectedChartsMeasure: measure,
    });
  };

  const onChangeChartsMaritalStatus = (martialStatus) => {
    setStateSafely({
      selectedChartsMaritalStatus: martialStatus,
    });
  };

  const onToggleChart = (chart) => {
    const selectedChartsIndicators = new Set(state.selectedChartsIndicators);

    toggleSetExistence(selectedChartsIndicators, chart);

    setStateSafely({
      selectedChartsIndicators,
    });
  };

  const onToggleSurveyLegend = () => {
    setStateSafely({
      showSurveyLegend: !state.showSurveyLegend,
    });
  };

  const onChangeExportedChartSet = (chartSet) => {
    setStateSafely({
      selectedExportedChartSet: chartSet,
    });
  };

  const onChangeExportedChartsMeasure = (measure) => {
    setStateSafely({
      selectedExportedChartsMeasure: measure,
    });
  };

  const onChangeExportedChartsMaritalStatus = (maritalStatus) => {
    setStateSafely({
      selectedExportedChartsMaritalStatus: maritalStatus,
    });
  };

  const onDownloadChartSet = () => {
    api
      .downloadChartSet(
        state.selectedRunId,
        undefined,
        getSelectedCountryNumericCodeOrRegion(),
        state.selectedExportedChartSet,
        state.selectedExportedChartsMaritalStatus,
        state.selectedExportedChartsMeasure,
        signal.token
      )
      .then((response) => {
        if (!response) {
          return;
        }

        const run = state.runs[state.selectedRunId];

        if (run) {
          const prefix = `${run.name}_${state.selectedExportedChartSet}`;

          fileDownload(response.data, createSlug(prefix, "charts.pdf"));
        }
      });
  };

  const onDownloadSelectedCharts = () => {
    api
      .downloadCharts(
        state.selectedRunId,
        undefined,
        getSelectedCountryNumericCodeOrRegion(),
        state.selectedExportedChartsMaritalStatus,
        state.selectedExportedChartsMeasure,
        Array.from(state.selectedChartsIndicators),
        signal.token
      )
      .then((response) => {
        if (!response) {
          return;
        }

        const run = state.runs[state.selectedRunId];

        if (run) {
          fileDownload(response.data, createSlug(run.name, "charts.pdf"));
        }
      });
  };

  const { firstYear, lastYear } = {
    firstYear: minimumYear,
    lastYear: maximumYear,
  };

  const surveyData = filterSurveyData(state.surveyData, firstYear, lastYear);

  const populationData = filterPopulationData(state.populationData, firstYear, lastYear);

  return (
    <ViewRun
      match={props.match}
      loadingData={state.loadingData}
      runs={state.runs}
      selectedRunId={state.selectedRunId}
      countries={getCountries(state.selectedRunId)}
      selectedCountryNumericCode={state.selectedCountryNumericCode}
      selectedPopulation={state.selectedPopulation}
      regions={getRegions(state.selectedRunId)}
      selectedRegion={state.selectedRegion}
      period={state.period}
      loadingRun={state.loadingRun}
      runTitle={createRunTitle()}
      surveyData={surveyData}
      populationData={populationData}
      emuData={state.emuData}
      selectedResultsMeasure={state.selectedResultsMeasure}
      selectedResultsIndicator={state.selectedResultsIndicator}
      selectedResultsMaritalStatus={state.selectedResultsMaritalStatus}
      tableResults={getTableResults()}
      selectedExportedResultsMeasures={Array.from(state.selectedExportedResultsMeasures)}
      selectedExportedResultsIndicators={Array.from(state.selectedExportedResultsIndicators)}
      disableSelectedResultsDownload={
        !api.createResultsDownloadUrl(
          state.selectedRunId,
          undefined,
          getSelectedCountryNumericCodeOrRegion(),
          Array.from(state.selectedExportedResultsMeasures),
          Array.from(state.selectedExportedResultsIndicators)
        )
      }
      disableAllResultsDownload={!getSelectedCountryNumericCodeOrRegion()}
      disableAllDataDownload={!getSelectedCountryNumericCodeOrRegion()}
      selectedChartsMeasure={state.selectedChartsMeasure}
      selectedChartsMaritalStatus={state.selectedChartsMaritalStatus}
      selectedChartsIndicators={Array.from(state.selectedChartsIndicators)}
      showSurveyLegend={state.showSurveyLegend}
      chartResults={getChartResults()}
      chartSurveyData={getChartSurveyData(surveyData)}
      chartEmuData={state.selectedChartsMaritalStatus === "married" ? state.emuData : undefined}
      chartSets={state.chartSets}
      selectedExportedChartSet={state.selectedExportedChartSet}
      selectedExportedChartsMeasure={state.selectedExportedChartsMeasure}
      selectedExportedChartsMaritalStatus={state.selectedExportedChartsMaritalStatus}
      disableChartSetDownload={
        !api.createChartSetDownloadUrl(
          state.selectedRunId,
          undefined,
          getSelectedCountryNumericCodeOrRegion(),
          state.selectedExportedChartSet,
          state.selectedExportedChartsMaritalStatus,
          state.selectedExportedChartsMeasure
        )
      }
      disableSelectedChartsDownload={
        !api.createChartsDownloadUrl(
          state.selectedRunId,
          undefined,
          getSelectedCountryNumericCodeOrRegion(),
          state.selectedExportedChartsMaritalStatus,
          state.selectedExportedChartsMeasure,
          Array.from(state.selectedChartsIndicators)
        )
      }
      onSelectRun={onSelectRun}
      onSelectCountry={onSelectCountry}
      onSelectRegion={onSelectRegion}
      onSelectPopulation={onSelectPopulation}
      onChangePeriod={onChangePeriod}
      onViewRun={onViewRun}
      onChangeResultsMeasure={onChangeResultsMeasure}
      onChangeResultsIndicator={onChangeResultsIndicator}
      onChangeResultsMaritalStatus={onChangeResultsMaritalStatus}
      onToggleExportedResultsMeasure={onToggleExportedResultsMeasure}
      onToggleExportedResultsIndicator={onToggleExportedResultsIndicator}
      onDownloadSelectedResults={onDownloadSelectedResults}
      onDownloadAllResults={onDownloadAllResults}
      onDownloadAllData={onDownloadAllData}
      onChangeChartsMeasure={onChangeChartsMeasure}
      onChangeChartsMaritalStatus={onChangeChartsMaritalStatus}
      onToggleChart={onToggleChart}
      onToggleSurveyLegend={onToggleSurveyLegend}
      onChangeExportedChartSet={onChangeExportedChartSet}
      onChangeExportedChartsMeasure={onChangeExportedChartsMeasure}
      onChangeExportedChartsMaritalStatus={onChangeExportedChartsMaritalStatus}
      onDownloadChartSet={onDownloadChartSet}
      onDownloadSelectedCharts={onDownloadSelectedCharts}
    />
  );
};

export default withTranslation()(withErrorBoundary(ViewRunContainer));
