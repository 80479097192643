import React from "react";
import * as PropTypes from "prop-types";
import { css } from "@emotion/css";
import { managedLabeledValuePropType } from "../../propTypes";
import Checkbox from "@mui/material/Checkbox";
import List from "@mui/material/List";
import ListItem from "@mui/material/ListItem";
import ListItemText from "@mui/material/ListItemText";

const classes = {
  checkBox: css`
    padding: 0;
  `,
  item: css`
    padding: 4px 0;
  `,
};

const ManageDataItems = (props) => (
  <List>
    {props.items.map((item) => (
      <ListItem key={item.value} dense={true} className={classes.item}>
        <Checkbox
          checked={props.selectedItemValues.includes(item.value)}
          className={classes.checkBox}
          onChange={() => {
            props.onToggleItem(item.value);
          }}
        />
        <ListItemText
          primary={
            <>
              <span>{item.label}</span>

              {item.isGlobal && <sup>*</sup>}
              {item.isInUse && <sup>&dagger;</sup>}

              {item.createdAt && <span>{` (${item.createdAt.toLocaleDateString()})`}</span>}
            </>
          }
        />
      </ListItem>
    ))}
  </List>
);

ManageDataItems.propTypes = {
  items: PropTypes.arrayOf(managedLabeledValuePropType).isRequired,
  selectedItemValues: PropTypes.arrayOf(PropTypes.string).isRequired,
  onToggleItem: PropTypes.func.isRequired,
};

export default ManageDataItems;
