import React from "react";
import * as PropTypes from "prop-types";
import { css } from "@emotion/css";
import classNames from "classnames";
import Button from "@mui/material/Button";
import InputLabel from "@mui/material/InputLabel";
import LinearProgress from "@mui/material/LinearProgress";
import { withTranslation } from "react-i18next";

const classes = {
  additionalButton: css`
    margin-top: 10px;
  `,
  button: css`
    width: 100%;
  `,
  externalDatabasePath: css`
    margin-top: 10px;
  `,
  fileInput: css`
    display: none;
  `,
  inputLabel: css`
    fontsize: 75%;
  `,
};

const StartRunDatabaseUploader = ({ t, ...rest }) => (
  <div>
    {!rest.databaseName ? (
      <div>
        <input
          id="external-database-file"
          type="file"
          accept=".csv"
          className={classes.fileInput}
          onChange={(e) => rest.onSelectDatabase(e.target.files[0])}
        />
        <label htmlFor="external-database-file">
          <Button variant="contained" component="span" className={classes.button}>
            {t("Choose database")}
          </Button>
        </label>
      </div>
    ) : (
      undefined
    )}

    {rest.databaseName && !rest.uploadingDatabase ? (
      <div>
        <Button variant="contained" color="primary" className={classes.button} onClick={rest.onUpload}>
          {t("Upload database")}
        </Button>

        <Button
          variant="contained"
          color="secondary"
          className={classNames(classes.button, classes.additionalButton)}
          onClick={rest.onCancelUpload}
        >
          {t("Cancel")}
        </Button>
      </div>
    ) : (
      undefined
    )}

    {rest.uploadingDatabase ? (
      <div>
        <LinearProgress variant="determinate" value={rest.databaseUploadProgress} />
      </div>
    ) : (
      undefined
    )}

    <div className={classes.externalDatabasePath}>
      <InputLabel className={classes.inputLabel}>{t("Database to upload")}</InputLabel>

      <div>{rest.databaseName || t("No database chosen")}</div>
    </div>
  </div>
);

StartRunDatabaseUploader.propTypes = {
  databaseName: PropTypes.string,
  uploadingDatabase: PropTypes.bool,
  databaseUploadProgress: PropTypes.number,
  onSelectDatabase: PropTypes.func.isRequired,
  onUpload: PropTypes.func.isRequired,
  onCancelUpload: PropTypes.func.isRequired,
};

StartRunDatabaseUploader.defaultProps = {
  uploadingDatabase: false,
  databaseUploadProgress: 0,
};

export default withTranslation()(StartRunDatabaseUploader);
