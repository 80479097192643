import React from "react";
import * as PropTypes from "prop-types";
import { css } from "@emotion/css";
import { maximumYear, minimumYear } from "../../constants";
import { directionPropType, labeledValuePropType, maritalStatusPropType } from "../../propTypes";
import classNames from "classnames";
import NativeSelect from "@mui/material/NativeSelect";
import TextField from "@mui/material/TextField";
import MaritalStatusSelector from "../SentenceMaritalStatusSelector";

const classes = {
  container: css`
    display: inline-flex;
    flex-wrap: wrap;
    align-items: baseline;
  `,
  input: css`
    margin: 0 0.5em;
  `,
  probability: css`
    width: 5rem;
  `,
  year: css`
    width: 85px;
  `,
};

const TargetsIndicatorLevelSpecification = (props) => (
  <div className={classes.container}>
    <span>What target of</span>

    <NativeSelect
      value={props.indicator}
      className={classes.input}
      onChange={(e) => {
        props.onSelectIndicator(e.target.value);
      }}
    >
      {props.indicators.map((indicator) => (
        <option key={indicator.value} value={indicator.value}>
          {indicator.label}
        </option>
      ))}
    </NativeSelect>

    <span>among</span>

    <MaritalStatusSelector
      value={props.maritalStatus}
      className={classes.input}
      onChange={props.onSelectMaritalStatus}
    />

    <span>women in the year</span>

    <TextField
      type="number"
      size="small"
      margin="dense"
      inputProps={{
        min: minimumYear,
        max: maximumYear,
      }}
      value={props.year || ""}
      className={classNames(classes.input, classes.year)}
      onChange={(e) => {
        props.onChangeYear(parseInt(e.target.value, 10) || undefined);
      }}
      onBlur={(e) => {
        props.onChangeYear(parseInt(e.target.value, 10) || undefined, true);
      }}
    />

    <span>corresponds to an attainment probability of</span>

    <TextField
      value={props.probability || ""}
      size="small"
      margin="dense"
      className={classNames(classes.input, classes.probability)}
      onChange={(e) => {
        props.onChangeProbability(e.target.value || undefined);
      }}
    />

    <span>where attainment probability refers to the probability of an outcome</span>

    <NativeSelect
      value={props.direction}
      className={classes.input}
      onChange={(e) => {
        props.onSelectDirection(e.target.value);
      }}
    >
      <option value="greaterThan">greater</option>
      <option value="lessThan">smaller</option>
    </NativeSelect>

    <span>than the target value?</span>
  </div>
);

TargetsIndicatorLevelSpecification.propTypes = {
  indicators: PropTypes.arrayOf(labeledValuePropType).isRequired,
  indicator: PropTypes.string.isRequired,
  maritalStatus: maritalStatusPropType,
  year: PropTypes.number,
  probability: PropTypes.string,
  direction: directionPropType,
  level: PropTypes.number,
  onSelectIndicator: PropTypes.func.isRequired,
  onSelectMaritalStatus: PropTypes.func.isRequired,
  onChangeYear: PropTypes.func.isRequired,
  onChangeProbability: PropTypes.func.isRequired,
  onSelectDirection: PropTypes.func.isRequired,
};

export default TargetsIndicatorLevelSpecification;
