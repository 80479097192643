import React from "react";
import * as PropTypes from "prop-types";
import { css } from "@emotion/css";
import CircularProgress from "@mui/material/CircularProgress";
import Box from "@mui/material/Box";

const classes = {
  loading: css`
    display: flex;
    align-items: center;
  `,
};

const styles = {
  message: {
    marginLeft: 2,
  },
};

const Loading = (props) => (
  <div>
    {!props.loading ? (
      props.children
    ) : (
      <div className={classes.loading}>
        <CircularProgress size={20} />
        <Box sx={styles.message}>{props.message}</Box>
      </div>
    )}
  </div>
);

Loading.propTypes = {
  loading: PropTypes.bool,
  message: PropTypes.string,
};

Loading.defaultProps = {
  loading: false,
  message: "Loading data...",
};

export default Loading;
